import './index.scss';
import { getRarityFrame } from 'utils/nftHelper';
import mcrtLogo from 'assets/icon-mcrt.png';
import { BigNumber, ethers } from 'ethers';
import { useMCRT } from 'hooks/useContract';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import gemPack1Image from '../../assets/gem-pack1.png';
import gemPack2Image from '../../assets/gem-pack2.png';
import gemPack3Image from '../../assets/gem-pack3.png';
import gemPack4Image from '../../assets/gem-pack4.png';
import gemPack5Image from '../../assets/gem-pack5.png';
import gemPack6Image from '../../assets/gem-pack6.png';
import axios from 'axios';
import { useEffect, useState } from 'react';

const getGemsPackImage = (id) => {
  switch (id) {
    case 1:
      return gemPack1Image;
    case 2:
      return gemPack2Image;
    case 3:
      return gemPack3Image;
    case 4:
      return gemPack4Image;
    case 5:
      return gemPack5Image;
    case 6:
      return gemPack6Image;
    default:
      return gemPack1Image;
  }
};

function numberWithCommas(x) {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

const GemsPack = ({ packData, mcrtPrice }) => {
  const magicMCRT = useMCRT();
  const { account } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { pack_name, id, price_in_usd, gems } = packData;

  const packPriceInMCRT =
    !mcrtPrice || mcrtPrice === 0 ? 0 : price_in_usd / mcrtPrice;

  useEffect(() => {
    if (packPriceInMCRT === 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [packPriceInMCRT]);

  function convertToBigNumber(number) {
    try {
      const numberString = number.toString();

      const truncatedString = numberString.slice(
        0,
        numberString.indexOf('.') + 5,
      );

      const bigNumber = ethers.utils.parseUnits(truncatedString, 9);

      return bigNumber;
    } catch (error) {
      // console.error('Error converting number:', error);
      return null;
    }
  }

  const onBuyGemsPack = async (packPriceInMCRT) => {
    try {
      if (isError) {
        throw new Error('Error while processing transaction');
      }
      setIsLoading(true);
      const mcrtAmt = convertToBigNumber(packPriceInMCRT);

      const treasury_wallet_address =
        process.env.REACT_APP_TREASURY_WALLET_ADDRESS;

      const allowance = await magicMCRT.methods
        .allowance(account, treasury_wallet_address)
        .call();

      if (
        BigNumber.from(String(allowance)).lt(BigNumber.from(String(mcrtAmt)))
      ) {
        await magicMCRT.methods.approve(account, mcrtAmt).send({
          from: account,
          gasPrice: ethers.utils.parseUnits('10', 'gwei'),
          gas: 100000,
        });
      }

      const contract = await magicMCRT.methods
        .transferFrom(account, treasury_wallet_address, mcrtAmt)
        .send({
          from: account,
          gasPrice: ethers.utils.parseUnits('10', 'gwei'),
          gas: 100000,
        });

      if (!contract.transactionHash) {
        throw new Error('There was an error in completing the transaction.');
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_LOBBY_BACK_URL}/update-gems`,
        {
          tx_hash: contract.transactionHash,
        },
      );
      setIsLoading(false);

      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success(
          `Congratulations, you have successfully completed your purchase.`,
        );
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(`There was an error while processing your transaction.`);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          isLoading
            ? toast.error(`Your transaction is already in progress`)
            : onBuyGemsPack(packPriceInMCRT);
        }}
      >
        <div className="nft-inventory__item">
          {isLoading && (
            <div
              style={{ fontSize: 20 }}
              className="nft-inventory__item-loading"
            >
              In progress
            </div>
          )}
          <div className="nft-inventory__item-image">
            <img
              className="nft-inventory__item-image-frame"
              src={getRarityFrame('Legendary')}
              alt="frame"
            />
            <img
              className="nft-inventory__item-image-avatar"
              src={getGemsPackImage(id)}
              alt="Gem pack"
              loading="lazy"
            />
          </div>
          <div className="nft-inventory__item-content">
            <div
              style={{ marginBottom: '10px' }}
              className="d-flex align-items-center"
            >
              <h3 style={{ color: 'white', fontSize: 18 }}>{pack_name}</h3>
            </div>
            <div className="d-flex align-items-center">
              <div
                className="item-hero-knight"
                style={{ backgroundColor: '#D592FF' }}
              >
                Gems
              </div>
              <div
                className="item-hero-knight"
                style={{ backgroundColor: '#2579D18F' }}
              >
                All Games
              </div>
            </div>

            <div className="d-flex align-items-center" style={{ marginTop: 4 }}>
              {/* <img src={bnbLogo} width={16} height={16} alt="" /> */}
              <div className="item-price-gems">
                {numberWithCommas(gems)} Gems
              </div>
              {/* <span className="item-price-dollar">
                  ${numberWithCommas(nFormatter(priceDollar, 1))}
                </span> */}
            </div>

            <div className="d-flex align-items-center" style={{ marginTop: 4 }}>
              <img src={mcrtLogo} width={16} height={16} alt="MCRT" />
              <div className="item-price-token">
                {numberWithCommas(nFormatter(packPriceInMCRT, 2))}
              </div>
              <span className="item-price-dollar">${price_in_usd}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GemsPack;
