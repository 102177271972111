import React, { useState, useMemo } from 'react';
import Modal from 'react-modal';
import { BigNumber } from 'ethers';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import usePrice from 'hooks/usePrice';
import { useMcrtState } from 'state/hooks';
import { E9, E18, numberRegex } from 'config';
import swapIcon from 'assets/icon-swap.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    width: '100%',
    maxWidth: '378px',
    borderRadius: '32px',
    maxHeight: '100vh',
    zIndex: '100',
  },
};

const defaultInputValues1 = [
  {
    value: '10',
    label: '10%',
  },
  {
    value: '25',
    label: '25%',
  },
  {
    value: '50',
    label: '50%',
  },
  {
    value: 'MAX',
    label: 'MAX',
  },
];

const defaultInputValues2 = [
  {
    value: '1000',
    label: '$1000',
  },
  {
    value: '2500',
    label: '$2500',
  },
  {
    value: '5000',
    label: '$5000',
  },
  {
    value: '10000',
    label: '$10000',
  },
];

const stakeDurations = [
  {
    id: 0,
    value: 30 * 86400,
    label: '30D',
  },
  {
    id: 1,
    value: 90 * 86400,
    label: '90D',
  },
  {
    id: 2,
    value: 180 * 86400,
    label: '180D',
  },
  { id: 3, value: 365 * 86400, label: '1Y' },
  {
    id: 4,
    value: 3 * 365 * 86400,
    label: '3Y',
  },
  { id: 5, value: 5 * 365 * 86400, label: '5Y' },
];

interface RoiCalculatorModalProps {
  modalIsOpen: boolean;
  apy: number;
  bonusPercents: any[];
  closeModal: () => void;
}

const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;

const RoiCalculatorModal = (props: RoiCalculatorModalProps) => {
  const { modalIsOpen, apy, bonusPercents, closeModal } = props;
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('0.00');
  const [swapOption, setSwapOption] = useState('USD');
  const [stakeDuration, setStakeDuration] = useState(stakeDurations[0]);
  const { mcrtPrice } = usePrice();
  const { balance } = useMcrtState();
  const { account } = useWeb3React();

  const bonusPercent = bonusPercents[stakeDuration.id];
  const expectedRoi = useMemo(() => {
    if (Number(fromValue) === 0) return 0;
    const dailyApy = apy / 86400 / 365;
    return (dailyApy * stakeDuration.value * (100 + bonusPercent)) / 100;
  }, [apy, bonusPercent, stakeDuration, fromValue]);

  const expectedAmount =
    swapOption === 'USD'
      ? (Number(fromValue) * expectedRoi) / 100
      : (Number(fromValue) * mcrtPrice * expectedRoi) / 100;

  const mcrtBalance =
    BigNumber.from(balance?.toString())
      .mul(100)
      .div(chainId === 56 ? E9 : E18)
      .toNumber() / 100;

  const defaultInputValues =
    account && mcrtBalance > 0 ? defaultInputValues1 : defaultInputValues2;

  // change from balance
  const onChangeBalance = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    value = value.replace(/,/g, '.');

    if (value === '.' || value.split('.').length > 2) return;

    if (!value.match(numberRegex)) {
      return;
    }

    const toValue =
      swapOption === 'USD'
        ? Number(value) / Number(mcrtPrice)
        : Number(value) * Number(mcrtPrice);

    setFromValue(value);
    setToValue(toValue.toFixed(2));
  };

  // swap input balances
  const onSwapBalances = () => {
    if (swapOption === 'USD') {
      setSwapOption('MCRT');
    } else {
      setSwapOption('USD');
    }

    const _fromValue = fromValue;
    setFromValue(toValue);
    setToValue(_fromValue);
  };

  // default value click
  const onDefaultValueClick = (value: string) => {
    if (!account && value === 'MAX') return;

    if (swapOption === 'USD') {
      let _fromValue = '0';
      if (account && mcrtBalance > 0) {
        _fromValue =
          value === 'MAX'
            ? String(mcrtBalance * mcrtPrice)
            : String((mcrtBalance * mcrtPrice * Number(value)) / 100);
      } else {
        _fromValue = value === 'MAX' ? String(mcrtBalance * mcrtPrice) : value;
      }

      const _toValue = (Number(_fromValue) / Number(mcrtPrice)).toFixed(2);

      setFromValue(Number(_fromValue).toFixed(2));
      setToValue(Number(_toValue).toFixed(2));
    }

    if (swapOption === 'MCRT') {
      let _fromValue = 0;
      let _toValue = 0;
      if (account && mcrtBalance > 0) {
        _fromValue =
          value === 'MAX' ? mcrtBalance : (mcrtBalance * Number(value)) / 100;
        _toValue = Number(_fromValue) * Number(mcrtPrice);
      } else {
        _fromValue =
          value === 'MAX' ? mcrtBalance : Number(value) / Number(mcrtPrice);
        _toValue =
          value === 'MAX'
            ? Number(mcrtBalance) * Number(mcrtPrice)
            : Number(value);
      }

      setFromValue(Number(_fromValue).toFixed(2));
      setToValue(Number(_toValue).toFixed(2));
    }
  };

  // stake duration click
  const onSelectStakeDuration = (value: any) => {
    setStakeDuration(value);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <ModalContent>
        <ModalHeader>{`ROI Calculator`}</ModalHeader>
        <ModalBody>
          {/* stake input */}
          <StakeInputContainer>
            <StakeInputLabel>MCRT Pleged</StakeInputLabel>
            <StakeInputWrapper>
              <StakeInputMain>
                <StakeInputFrom>
                  <StakeInput
                    value={fromValue}
                    inputMode="decimal"
                    min="0"
                    placeholder="0.00"
                    pattern="^[0-9]*[.,]?[0-9]{0,18}$"
                    onChange={onChangeBalance}
                  />
                  <StakeInputTokenName>
                    {`${swapOption === 'USD' ? 'USD' : 'MCRT'}`}
                  </StakeInputTokenName>
                </StakeInputFrom>

                <StakeInputTo>
                  {`${toValue} ${swapOption === 'USD' ? 'MCRT' : 'USD'}`}
                </StakeInputTo>
              </StakeInputMain>
              <StakeInputIcon>
                <img src={swapIcon} alt="swap icon" onClick={onSwapBalances} />
              </StakeInputIcon>
            </StakeInputWrapper>
          </StakeInputContainer>
          <StakeInputDefaultValueContainer>
            {defaultInputValues.map((row, index) => {
              const isActive = Number(row.value) === Number(fromValue);

              return (
                <StakeInputDefaultValue
                  key={index}
                  isActive={isActive}
                  isDisabled={!account && row.value === 'MAX'}
                  onClick={() => onDefaultValueClick(row.value)}
                >
                  {row.label}
                </StakeInputDefaultValue>
              );
            })}
          </StakeInputDefaultValueContainer>

          {/* stake duration */}
          <StakeDurationContainer>
            <StakeDurationLabel>Pleged for</StakeDurationLabel>
            <StakeDurations>
              {stakeDurations.map((row, index) => {
                const isActive = row.value === stakeDuration.value;
                return (
                  <StakeDuration
                    key={index}
                    isActive={isActive}
                    onClick={() => onSelectStakeDuration(row)}
                  >
                    {row.label}
                  </StakeDuration>
                );
              })}
            </StakeDurations>
          </StakeDurationContainer>

          {/* roi at current rates input */}
          <RoiInputContainer>
            <RoiInputLabel>{`ROI at Current Rates`}</RoiInputLabel>
            <RoiInputWrapper>
              <RoiInputMain>
                <RoiInputFrom>
                  <RoiInputTokenName>{`$`}</RoiInputTokenName>
                  <RoiInput value={expectedAmount.toFixed(2)} />
                </RoiInputFrom>

                <RoiInputTo>{`${Number(expectedAmount / mcrtPrice).toFixed(
                  2,
                )} MCRT(${expectedRoi.toFixed(2)}%)`}</RoiInputTo>
              </RoiInputMain>
            </RoiInputWrapper>
          </RoiInputContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// content
const ModalContent = styled.div`
  padding: 0 36px;
  margin: 0 auto;
  background: #1f224a;
  padding: 24px 24px;
  width: 100%;
`;

// header
const ModalHeader = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
`;

//body
const ModalBody = styled.div`
  margin-top: 24px;
`;

// stake input container
const StakeInputContainer = styled.div``;

const StakeInputLabel = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 4px;
`;

const StakeInputWrapper = styled.div`
  display: flex;
  background: #131a40;
  border-radius: 16px;
  padding: 8px 16px;
`;

const StakeInputMain = styled.div`
  width: 100%;
`;

const StakeInputFrom = styled.div`
  display: flex;
`;

const StakeInput = styled.input`
  color: white;
  background: transparent;
  outline: none;
  width: 100%;
  box-shadow: none !important;
  border: none !important;
  *:focus {
    outline: none;
    box-shadow: none !important;
  }

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: #ffffff;
  opacity: 0.6;
`;

const StakeInputTokenName = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: right;
  color: #ffffff;
  opacity: 0.6;
  margin-left: 4px;
`;

const StakeInputTo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  color: #ffffff;
  opacity: 0.6;
  line-height: 26px;
`;

const StakeInputIcon = styled.div`
  align-items: center;
  display: flex;
  margin-left: 20px;
  img {
    width: 16px;
  }
`;

// stake input default value option container
const StakeInputDefaultValueContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

const StakeInputDefaultValue = styled.button<{
  isDisabled: boolean;
  isActive: boolean;
}>`
  width: 25%;
  height: 36px;
  background: #131a40;
  border-radius: 50px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #5e84ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:hover {
    opacity: ${({ isDisabled }) => (isDisabled ? '1' : '0.8')};
  }

  ${({ isActive }) => (isActive ? 'background: white' : '')};
  ${({ isDisabled }) => (isDisabled ? 'background: #333455' : '')};
  ${({ isDisabled }) => (isDisabled ? 'color: #212249' : '')};
  ${({ isDisabled }) => (isDisabled ? 'cursor: not-allowed !important' : '')};
`;

// stake duration container
const StakeDurationContainer = styled.div`
  margin-top: 36px;
`;

const StakeDurationLabel = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 4px;
`;

const StakeDurations = styled.div`
  display: flex;
  width: 100%;
  background: #131a40;
  border-radius: 60px;
  margin-top: 8px;
`;

const StakeDuration = styled.button<{ isActive: boolean }>`
  width: 16.66%;
  height: 36px;
  border-radius: 90px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActive }) => (isActive ? '#5e84ff' : 'transparent')};
  border: none;
`;

// roi at current rate input
const RoiInputContainer = styled.div`
  margin-top: 36px;
`;

const RoiInputLabel = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 4px;
`;

const RoiInputWrapper = styled.div`
  display: flex;
  background: #131a40;
  border-radius: 16px;
  padding: 16px;
`;

const RoiInputMain = styled.div`
  width: 100%;
`;

const RoiInputFrom = styled.div`
  display: flex;
`;

const RoiInput = styled.input`
  color: white;
  background: transparent;
  outline: none;
  width: 100%;
  box-shadow: none !important;
  border: none !important;
  *:focus {
    outline: none;
    box-shadow: none !important;
  }

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
`;

const RoiInputTokenName = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: 24px;
  text-align: right;
  color: #ffffff;
  margin-right: 4px;
`;

const RoiInputTo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.6;
`;

export { RoiCalculatorModal };
