import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import moment from 'moment';
import React, { useState } from 'react';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';

// styles
import './index.scss';

const nftRewards = [null, null, 'Item', 'Character', 'Land', 'Land x 2'];
const nftRewardsTexts = ['Item', 'Character', 'Land', 'Land x 2'];
const timelocks = [
  // 3600,
  30 * 86400,
  90 * 86400,
  180 * 86400,
  365 * 86400,
  365 * 3 * 86400,
  365 * 5 * 86400,
];
const dropdownList = [
  // { text: "Pledge (lock) for 1 hour", duration: 3600 * 1000, isForNft: true },
  {
    text: 'Pledge (lock) for 30 days',
    duration: 30 * 3600 * 24 * 1000,
    isForNft: false,
  },
  {
    text: 'Pledge (lock) for 90 days',
    duration: 90 * 3600 * 24 * 1000,
    isForNft: false,
  },
  {
    text: 'Pledge (lock) for 180 days',
    duration: 180 * 3600 * 24 * 1000,
    isForNft: true,
  },
  {
    text: 'Pledge (lock) for 1 year',
    duration: 365 * 3600 * 24 * 1000,
    isForNft: true,
  },
  {
    text: 'Pledge (lock) for 3 years',
    duration: 3 * 365 * 3600 * 24 * 1000,
    isForNft: true,
  },
  {
    text: 'Pledge (lock) for 5 years',
    duration: 5 * 365 * 3600 * 24 * 1000,
    isForNft: true,
  },
];
const E18 = BigNumber.from(10).pow(18);
const E9 = BigNumber.from(10).pow(9);
const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;

const TableMobileRow = ({
  stakeInfo,
  pRewards,
  point,
  minStakeForTokens,
  idx,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickRow = () => {
    setIsExpanded(!isExpanded);
  };

  const duration =
    Number(stakeInfo[idx].timeToUnlock) - Number(stakeInfo[idx].stakingTime);

  let durationInfo = '';
  if (duration < 3600 * 24) {
    durationInfo = `${duration / 3600} hours`;
  } else {
    if (duration / 3600 / 24 >= 365)
      durationInfo = `${duration / 3600 / 24 / 365} years`;
    else durationInfo = `${duration / 3600 / 24} days`;
  }
  const tIndex = timelocks.findIndex((it) => it == duration);
  return (
    <div className="mobile-mcrt-reward-table--body-row">
      <div
        className="mobile-mcrt-reward-table--body-row-origin"
        onClick={onClickRow}
      >
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__duration">
          {durationInfo}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__bonus">
          {nftRewards[tIndex]}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__staked">
          {`${
            minStakeForTokens
              ? BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                  chainId === 56 ? E9 : E18,
                )
              : 0
          }`}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__action">
          <div className="mobile-mcrt-reward-table--body-row-origin-cell__action-icon">
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="mobile-mcrt-reward-table--body-row-expanded">
          <div>
            <span className="mobile-mcrt-reward-table--body-row-expanded-label">{`Claimed: `}</span>
            <span className="mobile-mcrt-reward-table--body-row-expanded-value">
              {BigNumber.from(point).toNumber()}
            </span>
          </div>
          <div>
            <span className="mobile-mcrt-reward-table--body-row-expanded-label">{`Earned NFT: `}</span>
            <span className="mobile-mcrt-reward-table--body-row-expanded-value">
              {BigNumber.from(pRewards).toNumber()}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const EmptyRow = ({ duration, minStakeForTokens }) => {
  let durationInfo = '';
  if (duration < 3600 * 24) {
    durationInfo = `${duration / 3600} hours`;
  } else {
    if (duration / 3600 / 24 >= 365)
      durationInfo = `${
        duration / 3600 / 24 / 365 === 1
          ? '1 year'
          : duration / 3600 / 24 / 365 + ' years'
      }`;
    else durationInfo = `${duration / 3600 / 24} days`;
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickRow = () => {
    setIsExpanded(!isExpanded);
  };
  const tIndex = timelocks.findIndex((it) => it == duration);

  return (
    <div className="mobile-mcrt-reward-table--body-row">
      <div
        className="mobile-mcrt-reward-table--body-row-origin"
        onClick={onClickRow}
      >
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__duration">
          {durationInfo}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__bonus">
          {nftRewards[tIndex]}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__staked">
          {`${
            minStakeForTokens
              ? BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                  chainId === 56 ? E9 : E18,
                )
              : 0
          }`}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__action">
          <div className="mobile-mcrt-reward-table--body-row-origin-cell__action-icon">
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="mobile-mcrt-reward-table--body-row-expanded">
          <div>
            <span className="mobile-mcrt-reward-table--body-row-expanded-label">{`Claimed: `}</span>
            <span className="mobile-mcrt-reward-table--body-row-expanded-value">
              0
            </span>
          </div>
          <div>
            <span className="mobile-mcrt-reward-table--body-row-expanded-label">{`Earned NFT: `}</span>
            <span className="mobile-mcrt-reward-table--body-row-expanded-value">
              0
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const NFTRewardTable = (props) => {
  const { account } = useWeb3React();
  const [isMobile] = useState(window.innerWidth < 576);
  const {
    stakeInfo,
    stakingNonce,
    rewards,
    nftPoint,
    minStakeForTokens,
    pointRewards,
  } = props;

  const renderDefaultTable = () => {
    return (
      <table>
        <tr>
          <th>Duration</th>
          <th>NFT</th>
          <th>MCRT Required</th>
        </tr>
        <tbody>
          {timelocks.map((tl, idx) => {
            const tIndex = timelocks.findIndex((it) => it === tl);
            if (!dropdownList[tIndex].isForNft) return null;
            return (
              <tr key={idx}>
                {tl < 3600 * 24 ? (
                  <td>{tl / 3600} hours</td>
                ) : (
                  <td>
                    {tl / 3600 / 24 >= 365
                      ? tl / 3600 / 24 / 365 == 1
                        ? '1 year'
                        : `${tl / 3600 / 24 / 365} years`
                      : `${tl / 3600 / 24} days`}
                  </td>
                )}
                <td>{nftRewards[tIndex] || 'None'}</td>
                <td>{`${BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                  chainId === 56 ? E9 : E18,
                )}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderMainTableForMobileView = () => {
    let addedCol = [];

    return (
      <div className="mobile-mcrt-reward-table">
        <div className="mobile-mcrt-reward-table--header">
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__duration">
            Duration
          </div>
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__bonus">
            NFT
          </div>
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__staked">
            MCRT
          </div>
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__action" />
        </div>
        <div className="mobile-mcrt-reward-table--body">
          {new Array(stakingNonce).fill(0).map((_, idx) => {
            if (
              !stakeInfo[idx] ||
              !stakeInfo[idx].option ||
              stakeInfo[idx].owner == ZERO_ADDRESS
            )
              return;

            const duration =
              Number(stakeInfo[idx].timeToUnlock) -
              Number(stakeInfo[idx].stakingTime);

            const tIndex = timelocks.findIndex((it) => it == duration);
            let pRewards = 0;
            let point = 0;
            if (pointRewards.length != 0) {
              if (stakeInfo[idx].withdrawnPoint) {
                point =
                  pointRewards[tIndex][
                    nftRewardsTexts.findIndex((it) => it == nftRewards[tIndex])
                  ];
              } else {
                pRewards =
                  pointRewards[tIndex][
                    nftRewardsTexts.findIndex((it) => it == nftRewards[tIndex])
                  ];
              }
            }
            addedCol.push(duration);
            return (
              <TableMobileRow
                key={idx}
                idx={idx}
                stakeInfo={stakeInfo}
                pRewards={pRewards}
                point={point}
                minStakeForTokens={minStakeForTokens}
              />
            );
          })}
          {timelocks
            .filter((it) => !addedCol.includes(it))
            .map((duration, tIdx) => {
              const tIndex = timelocks.findIndex((it) => it == duration);
              if (!nftRewards[tIndex]) {
                return;
              }
              return (
                <EmptyRow
                  key={tIdx}
                  duration={duration}
                  minStakeForTokens={minStakeForTokens}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const renderMainTableForDesktopView = () => {
    let addedCol = [];

    return (
      <table>
        <tr>
          <th>Duration</th>
          <th>NFT</th>
          <th>MCRT Required</th>
          <th>Claimed</th>
          <th>Earned NFT</th>
        </tr>

        {new Array(stakingNonce).fill(0).map((_, idx) => {
          if (
            !stakeInfo[idx] ||
            !stakeInfo[idx].option ||
            stakeInfo[idx].owner == ZERO_ADDRESS
          )
            return;

          const duration =
            Number(stakeInfo[idx].timeToUnlock) -
            Number(stakeInfo[idx].stakingTime);

          const tIndex = timelocks.findIndex((it) => it == duration);
          let pRewards = 0;
          let point = 0;
          let nftRewardIndex = nftRewardsTexts.findIndex(
            (it) => it == nftRewards[tIndex],
          );
          if (pointRewards.length != 0) {
            if (stakeInfo[idx].withdrawnPoint) {
              point =
                pointRewards[tIndex][nftRewardIndex === 3 ? 2 : nftRewardIndex];
            } else {
              pRewards =
                pointRewards[tIndex][nftRewardIndex === 3 ? 2 : nftRewardIndex];
            }
          }
          addedCol.push(duration);
          return (
            <tr key={idx}>
              {duration < 3600 * 24 ? (
                <td>{duration / 3600} hours</td>
              ) : (
                <td>
                  {duration / 3600 / 24 >= 365
                    ? duration / 3600 / 24 / 365 == 1
                      ? '1 year'
                      : `${duration / 3600 / 24 / 365} years`
                    : `${duration / 3600 / 24} days`}
                </td>
              )}
              <td>{nftRewards[tIndex]}</td>
              <td>{`${
                minStakeForTokens
                  ? BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                      chainId === 56 ? E9 : E18,
                    )
                  : 0
              }`}</td>
              <td>{point ? BigNumber.from(point).toNumber() : 0}</td>
              <td>{pRewards ? BigNumber.from(pRewards).toNumber() : 0}</td>
            </tr>
          );
        })}

        {timelocks
          .filter((it) => !addedCol.includes(it))
          .map((duration, tIdx) => {
            const tIndex = timelocks.findIndex((it) => it == duration);

            if (!nftRewards[tIndex]) {
              return;
            }

            return (
              <tr key={tIdx}>
                {duration < 3600 * 24 ? (
                  <td>{duration / 3600} hours</td>
                ) : (
                  <td>
                    {duration / 3600 / 24 >= 365
                      ? duration / 3600 / 24 / 365 == 1
                        ? '1 year'
                        : `${duration / 3600 / 24 / 365} years`
                      : `${duration / 3600 / 24} days`}
                  </td>
                )}
                <td>{nftRewards[tIndex]}</td>
                <td>{`${
                  minStakeForTokens
                    ? BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                        chainId === 56 ? E9 : E18,
                      )
                    : 0
                }`}</td>
                <td>0</td>
                <td>0</td>
              </tr>
            );
          })}
      </table>
    );
  };

  // default view
  if (!account || stakeInfo.length === 0) {
    return <>{renderDefaultTable()}</>;
  }

  // mobile view
  if (isMobile) {
    return <>{renderMainTableForMobileView()}</>;
  }

  // desktop view
  return <>{renderMainTableForDesktopView()}</>;
};

export default NFTRewardTable;
