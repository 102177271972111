import axios from 'axios';

export const getMagicCraftListedNfts = async (
  pageNumber: number,
  sortField: string,
  contractAddress: string,
  filter: number,
  rarity?: string[],
  account?: string,
) => {
  const { data } = await axios.get(
    `${
      process.env.REACT_APP_MARKETPLACE_BACK_URL
    }/auction?contractAddress=${contractAddress}${
      account ? `&address=${account.toLowerCase()}&` : '&'
    }page=${pageNumber}&sortField=${sortField}&filter=${filter}${
      rarity && rarity?.length > 0 ? `&rarity=${rarity.toString()}` : ''
    }
`,
  );

  if (data && data.items.length === 0) return [];

  return data.items;
};

export const getMagicCraftNfts = async (
  contractAddress: string | undefined,
  ids: Array<String>,
) => {
  let { data } = await axios.post(
    `${process.env.REACT_APP_MARKETPLACE_BACK_URL}/getNFT`,
    {
      contractAddress,
      ids: ids,
    },
  );

  if (data && data.items.length === 0) return [];

  data.items = data.items.map((it: any) => {
    let { holder: ownerAddr } = data.holders.find(
      (holder: any) => holder.tokenID == it.tokenID,
    );
    return { ...it, owner: ownerAddr };
  });

  return data.items;
};

export const getMagicCraftAccountWithNfts = async (
  pageNumber: number,
  sortField: string,
  contractAddress: string,
  filter: number,
  account: string,
  rarity?: string[],
) => {
  let { data } = await axios.post(
    `${process.env.REACT_APP_MARKETPLACE_BACK_URL}/getNFT`,
    {
      address: account,
    },
  );

  let listedData = await getMagicCraftListedNfts(
    pageNumber,
    sortField,
    contractAddress,
    filter,
    rarity,
    account,
  );

  let listedMeta = await getMagicCraftNfts(
    contractAddress,
    listedData.map((it: any) => it.tokenID),
  );

  for (let i = 0; i < listedData.length; i++) {
    let metadata = listedMeta.find(
      (it: any) => it.tokenID == listedData[i].tokenID,
    );
    listedData[i].name = metadata.name;
    listedData[i].description = metadata.description;
    listedData[i].image = metadata.image;
    listedData[i].attributes = [...metadata.attributes];
  }

  if (data && data.items.length === 0 && listedData.length == 0) return [];

  return [...listedData, ...data.items];
};
