import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import moment from 'moment';
import React, { useState } from 'react';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';

// styles
import './index.scss';

const timelocks = [
  // 3600,
  30 * 86400,
  90 * 86400,
  180 * 86400,
  365 * 86400,
  365 * 3 * 86400,
  365 * 5 * 86400,
];

const E18 = BigNumber.from(10).pow(18);
const E9 = BigNumber.from(10).pow(9);
const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;

const TableMobileRow = ({ stakeInfo, rewards, bonusPercents, idx }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickRow = () => {
    setIsExpanded(!isExpanded);
  };

  if (
    !stakeInfo[idx] ||
    stakeInfo[idx].option ||
    stakeInfo[idx].owner === ZERO_ADDRESS ||
    (rewards && rewards.length !== stakeInfo.length)
  ) {
    return null;
  }

  const duration =
    Number(stakeInfo[idx].timeToUnlock) - Number(stakeInfo[idx].stakingTime);
  const stakedUntil = moment
    .utc(Number(stakeInfo[idx].timeToUnlock) * 1000)
    .local();

  let durationInfo = '';
  if (duration < 3600 * 24) {
    durationInfo = `${duration / 3600} hours`;
  } else {
    if (duration / 3600 / 24 >= 365)
      durationInfo = `${duration / 3600 / 24 / 365} years`;
    else durationInfo = `${duration / 3600 / 24} days`;
  }

  return (
    <div className="mobile-mcrt-reward-table--body-row">
      <div
        className="mobile-mcrt-reward-table--body-row-origin"
        onClick={onClickRow}
      >
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__duration">
          {durationInfo}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__bonus">
          {`${bonusPercents[timelocks.findIndex((it) => it === duration)]} %`}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__staked">
          {` ${BigNumber.from(stakeInfo[idx].tokensStaked)
            .div(chainId === 56 ? E9 : E18)
            .toNumber()
            .toLocaleString()}`}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__action">
          <div className="mobile-mcrt-reward-table--body-row-origin-cell__action-icon">
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="mobile-mcrt-reward-table--body-row-expanded">
          <div>
            <span className="mobile-mcrt-reward-table--body-row-expanded-label">{`MCRT reward: `}</span>
            <span className="mobile-mcrt-reward-table--body-row-expanded-value">
              {rewards[idx].toLocaleString()}
            </span>
          </div>
          <div>
            <span className="mobile-mcrt-reward-table--body-row-expanded-label">{`Unlocked on: `}</span>
            <span className="mobile-mcrt-reward-table--body-row-expanded-value">
              {stakedUntil.format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const EmptyRow = ({ duration, bonusPercents }) => {
  let durationInfo = '';
  if (duration < 3600 * 24) {
    durationInfo = `${duration / 3600} hours`;
  } else {
    if (duration / 3600 / 24 >= 365)
      durationInfo = `${
        duration / 3600 / 24 / 365 === 1
          ? '1 year'
          : duration / 3600 / 24 / 365 + ' years'
      }`;
    else durationInfo = `${duration / 3600 / 24} days`;
  }

  return (
    <div className="mobile-mcrt-reward-table--body-row">
      <div className="mobile-mcrt-reward-table--body-row-origin">
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__duration">
          {durationInfo}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__bonus">
          {`${bonusPercents[timelocks.findIndex((it) => it === duration)]} %`}
        </div>
        <div className="mobile-mcrt-reward-table--body-row-origin-cell mobile-mcrt-reward-table--body-row-origin-cell__staked">
          0
        </div>
      </div>
    </div>
  );
};

const MCRTRewardTable = (props) => {
  const { account } = useWeb3React();
  const [isMobile] = useState(window.innerWidth < 576);
  const { stakeInfo, stakingNonce, rewards, bonusPercents } = props;

  const renderDefaultTable = () => {
    return (
      <table>
        <tbody>
          <tr>
            <th>Duration</th>
            <th>Bonus</th>
          </tr>
          {timelocks.map((tl, idx) => {
            return (
              <tr key={idx}>
                {tl < 3600 * 24 ? (
                  <td>{tl / 3600} hours</td>
                ) : (
                  <td>
                    {tl / 3600 / 24 >= 365
                      ? tl / 3600 / 24 / 365 == 1
                        ? '1 year'
                        : `${tl / 3600 / 24 / 365} years`
                      : `${tl / 3600 / 24} days`}
                  </td>
                )}
                <td>
                  {bonusPercents[timelocks.findIndex((it) => it == tl)]} %
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderMainTableForMobileView = () => {
    let addedCol = [];
    for (let i = 0; i < stakeInfo.length; i++) {
      if (
        !stakeInfo[i] ||
        stakeInfo[i].option ||
        stakeInfo[i].owner === ZERO_ADDRESS
      ) {
        continue;
      }
      addedCol.push(
        Number(stakeInfo[i].timeToUnlock) - Number(stakeInfo[i].stakingTime),
      );
    }

    return (
      <div className="mobile-mcrt-reward-table">
        <div className="mobile-mcrt-reward-table--header">
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__duration">
            Duration
          </div>
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__bonus">
            Bonus
          </div>
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__staked">
            Staked
          </div>
          <div className="mobile-mcrt-reward-table--header-cell mobile-mcrt-reward-table--header-cell__action" />
        </div>
        <div className="mobile-mcrt-reward-table--body">
          {new Array(stakingNonce).fill(0).map((_, idx) => {
            return (
              <TableMobileRow
                key={idx}
                idx={idx}
                stakeInfo={stakeInfo}
                rewards={rewards}
                bonusPercents={bonusPercents}
              />
            );
          })}
          {timelocks
            .filter((tl) => !addedCol.includes(tl))
            .map((it, tIdx) => {
              return (
                <EmptyRow
                  key={tIdx}
                  duration={it}
                  bonusPercents={bonusPercents}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const renderMainTableForDesktopView = () => {
    let addedCol = [];

    return (
      <table>
        <tr>
          <th>Duration</th>
          <th>Bonus</th>
          <th>Staked</th>
          <th>MCRT Reward </th>
          <th>Unlocked on</th>
        </tr>
        {new Array(stakingNonce).fill(0).map((_, idx) => {
          if (
            !stakeInfo[idx] ||
            stakeInfo[idx].option ||
            stakeInfo[idx].owner === ZERO_ADDRESS ||
            rewards.length !== stakeInfo.length
          )
            return;

          const duration =
            Number(stakeInfo[idx].timeToUnlock) -
            Number(stakeInfo[idx].stakingTime);
          addedCol.push(duration);

          const stakedUntil = moment
            .utc(Number(stakeInfo[idx].timeToUnlock) * 1000)
            .local();

          return (
            <tr key={idx}>
              {duration < 3600 * 24 ? (
                <td>{duration / 3600} hours</td>
              ) : (
                <td>
                  {duration / 3600 / 24 >= 365
                    ? duration / 3600 / 24 / 365 == 1
                      ? '1 year'
                      : `${duration / 3600 / 24 / 365} years`
                    : `${duration / 3600 / 24} days`}
                </td>
              )}
              <td>
                {bonusPercents[timelocks.findIndex((it) => it == duration)]} %
              </td>
              <td>
                {' '}
                {BigNumber.from(stakeInfo[idx].tokensStaked)
                  .div(chainId === 56 ? E9 : E18)
                  .toNumber()
                  .toLocaleString()}
              </td>
              <td>{rewards[idx].toLocaleString()}</td>
              <td>{stakedUntil.format('M/D/YYYY')}</td>
            </tr>
          );
        })}

        {timelocks.map((duration, tIdx) => {
          if (addedCol.findIndex((it) => it === duration) != -1) return;

          return (
            <tr key={tIdx}>
              {duration < 3600 * 24 ? (
                <td>{duration / 3600} hours</td>
              ) : (
                <td>
                  {duration / 3600 / 24 >= 365
                    ? duration / 3600 / 24 / 365 == 1
                      ? '1 year'
                      : `${duration / 3600 / 24 / 365} years`
                    : `${duration / 3600 / 24} days`}
                </td>
              )}
              <td>
                {bonusPercents[timelocks.findIndex((it) => it == duration)]} %
              </td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
          );
        })}
      </table>
    );
  };

  // default view
  if (!account || stakeInfo.length === 0) {
    return <>{renderDefaultTable()}</>;
  }

  // mobile view
  if (isMobile) {
    return <>{renderMainTableForMobileView()}</>;
  }

  // desktop view
  return <>{renderMainTableForDesktopView()}</>;
};

export default MCRTRewardTable;
