import { BigNumber } from 'ethers';
import tokenContractJson from 'constants/ABI/MCRTToken.json';
import multicall from 'utils/multicall';
import { getWeb3 } from 'utils/web3';
import { getMCRTTokenAddress } from 'utils/addressHelpers';

export const fetchMcrtBalance = async (account: string) => {
  const [mcrtBalance] = await multicall(tokenContractJson.abi, [
    {
      address: getMCRTTokenAddress(),
      name: 'balanceOf',
      params: [account],
    },
  ]);
  const web3 = getWeb3();
  const balance = await web3.eth.getBalance(account);
  return {
    mcrtBalance: BigNumber.from(mcrtBalance[0]._hex),
    bnbBalance: BigNumber.from(balance),
  };
};
