import { useEffect, useState, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
// eslint-disable-next-line import/no-unresolved
import { simpleRpcProvider } from 'utils/providers';
import { CHAIN_ID } from 'config/constants/networks';

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = (): any => {
  const { provider, chainId, ...web3React } = useWeb3React();
  const refEth = useRef(provider);
  const [providers, setProviders] = useState(provider || simpleRpcProvider);

  useEffect(() => {
    if (provider !== refEth.current) {
      setProviders(provider || simpleRpcProvider);
      refEth.current = provider;
    }
  }, [provider]);

  return {
    library: provider,
    chainId: chainId ?? parseInt(CHAIN_ID, 10),
    ...web3React,
  };
};

export default useActiveWeb3React;
