import { useCallback, useEffect } from 'react';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { ConnectorNames, connectorLocalStorageKey } from 'widgets/WalletModal';
import { connectorsByName } from 'utils/web3React';
import { useAppDispatch } from 'state';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useWeb3React } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
const activateInjectedProvider = (connectorID: ConnectorNames) => {
  const { ethereum } = window;
  if (!ethereum?.providers) {
    return undefined;
  }

  let provider;
  switch (connectorID) {
    case 'walletlink':
      provider = ethereum.providers.find(
        ({ isCoinbaseWallet }) => isCoinbaseWallet,
      );
      break;
    case 'injected':
      provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
      break;
  }

  if (provider && ethereum) {
    ethereum?.setSelectedProvider(provider);
  }
};

const useAuth = () => {
  const dispatch = useAppDispatch();
  const { chainId } = useActiveWeb3React();
  const { connector } = useWeb3React();

  const login = useCallback(async (connectorID: ConnectorNames) => {
    activateInjectedProvider(connectorID);

    const connectorOrGetConnector = connectorsByName[connectorID];
    const connector =
      typeof connectorOrGetConnector !== 'function'
        ? connectorsByName[connectorID]
        : await connectorOrGetConnector();

    if (connector) {
      if (connector instanceof WalletConnectV2) {
        try {
          await connector.activate(56);
        } catch (e) {
          // console.log(e);
          window.localStorage.removeItem(connectorLocalStorageKey);
        }
      } else {
        try {
          if (connector instanceof MetaMask) {
            await connector.activate(56);
          }
        } catch (e) {
          // console.log(e);
          window.localStorage.removeItem(connectorLocalStorageKey);
        }
      }
      // } else {
      //   activate(connector, async (error: Error) => {
      //     window.localStorage.removeItem(connectorLocalStorageKey);

      //     if (
      //       error instanceof NoEthereumProviderError ||
      //       error instanceof NoBscProviderError
      //     ) {
      //       console.log('Provider Error', 'No provider was found');
      //       toast.error('No provider was found');
      //     } else if (
      //       error instanceof UserRejectedRequestErrorInjected ||
      //       error instanceof UserRejectedRequestErrorWalletConnect
      //     ) {
      //       if (connector instanceof WalletConnectConnector) {
      //         const walletConnector = connector as WalletConnectConnector;
      //         walletConnector.walletConnectProvider = undefined;
      //       }
      //       console.log(
      //         'Authorization Error',
      //         'Please authorize to access your account',
      //       );
      //       toast.error('Please authorize to access your account');
      //     } else {
      //       window.localStorage.removeItem(connectorLocalStorageKey);
      //       console.log(error.name, error.message);
      //       toast.error(error.message);
      //     }
      //   });
      // }
    } else {
      console.log("Can't find connector", 'The connector config is wrong');
      console.log('The connector config is wrong');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
  }, [connector, dispatch, chainId]);

  useEffect(() => {
    if (chainId === undefined) {
      window.localStorage.removeItem(connectorLocalStorageKey);
      if (connector?.deactivate) {
        connector.deactivate();
      } else {
        connector.resetState();
      }
    }
    return () => {};
  }, []);

  return { login, logout };
};

export default useAuth;
