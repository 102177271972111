import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import { ConnectWallet } from 'components/ConnectWallet';
import {
  fetchUserMCRTBalanceAsync,
  fetchAccountMagicNftsAsync,
  setAccountMagicNftsEmptyAsync,
} from 'state/action';
import { useMcrtState, useNFTMarketplaceState } from 'state/hooks';

import LogoWithText from 'assets/logo-with-text.png';
import LogoWithoutText from 'assets/logo-without-text.png';
import mcrtLogo from 'assets/icon-mcrt.png';
import bnbLogo from 'assets/icon-bnb.png';

import {
  GiBlackKnightHelm,
  GiReceiveMoney,
  GiShop,
  GiWallet,
} from 'react-icons/gi';
import { FaGamepad } from 'react-icons/fa';
import { AiFillBank, AiFillStar } from 'react-icons/ai';
import { RiDashboardFill } from 'react-icons/ri';
import { IoMdSwap } from 'react-icons/io';
import { GoVerified } from 'react-icons/go';

import './Header.scss';

const E18 = BigNumber.from(10).pow(18);
const E9 = BigNumber.from(10).pow(9);

const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;

const ConnectButtonStyle = (account) => {
  return {
    position: 'relative',
    backgroundColor: account ? '#232449' : '#316BFF',
    borderRadius: 8,
    border: 'none',
    paddingTop: 10,
    paddingBottom: 10,
    outline: 0,
  };
};

const Header = () => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const location = useLocation();
  const { balance, bnbBalance } = useMcrtState();
  const { inventoryNfts, collection } = useNFTMarketplaceState();
  const bnb =
    BigNumber.from(bnbBalance.toString()).mul(100).div(E18).toNumber() / 100;
  const mcrtBalance =
    BigNumber.from(balance.toString())
      .mul(100)
      .div(chainId === 56 ? E9 : E18)
      .toNumber() / 100;

  useEffect(() => {
    if (!account) {
      dispatch(setAccountMagicNftsEmptyAsync());
      return;
    }

    if (account) {
      dispatch(fetchAccountMagicNftsAsync(account));
      dispatch(fetchUserMCRTBalanceAsync(account));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    hotjar.stateChange(location.pathname);
  }, [location.pathname]);

  return (
    <div className="header-sticky">
      <Navbar
        collapseOnSelect
        expand="xl"
        className="bg-header main-header"
        variant="dark"
      >
        <Container fluid className="d-flex align-items-center">
          <Navbar.Brand style={{ marginRight: '0px' }}>
            <div className="header-logo header-logo__normal">
              <a href="/" rel="noopener noreferrer">
                <img src={LogoWithText} alt="logo" />
              </a>
            </div>
            <div className="header-logo header-logo__mobile">
              <a href="/" rel="noopener noreferrer">
                <img src={LogoWithoutText} alt="logo" />
              </a>
            </div>
          </Navbar.Brand>

          <div className="d-flex">
            <Nav className="connect-wallet-btn connect-wallet-btn--mobile mint-page">
              {account && (
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <img src={mcrtLogo} alt="" />

                    <div
                      className="d-flex flex-column"
                      style={{ marginRight: 20 }}
                    >
                      <h6 className="text-white">{mcrtBalance}</h6>
                      {/* {location.pathname !== '/nft_mint' && (
                        <p className="mb-0">
                          {`${account.substring(0, 6)}...${account.substring(
                            account.length - 4,
                          )}`}
                        </p>
                      )} */}
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bnbLogo} alt="" />

                    <div
                      className="d-flex flex-column"
                      style={{ marginRight: 20 }}
                    >
                      <h6 className="text-white">{bnb}</h6>
                    </div>
                  </div>
                </div>
              )}

              <ConnectWallet style={ConnectButtonStyle(account)} />
            </Nav>
            <div className="header-navbar-toggle-container">
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                className="header-navbar-toggle"
              >
                <div className="blue-circle" />
                <div className="lines">
                  <span />
                  <span />
                  <span />
                </div>
              </Navbar.Toggle>
            </div>
          </div>

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="header-navbar-container"
          >
            <Nav className="me-auto d-flex align-items-center">
              <Nav.Link
                href="/"
                className={`header-menu p-2 text-decoration-none ${
                  location.pathname === '/' ? 'active' : ''
                }`}
              >
                <div className="navbar-item">
                  <RiDashboardFill />
                  <h6>Dashboard</h6>
                </div>
              </Nav.Link>
              <Nav.Link
                href="/marketplace/explorer"
                className={`header-menu p-2 text-decoration-none ${
                  location.pathname === '/marketplace/explorer' ? 'active' : ''
                }`}
              >
                <div className="navbar-item">
                  <GiShop />
                  <h6>Marketplace</h6>
                </div>
              </Nav.Link>

              {account && (
                <Nav.Link
                  href="/marketplace/profile"
                  className={`header-menu p-2 text-decoration-none ${
                    location.pathname === '/marketplace/profile' ? 'active' : ''
                  }`}
                >
                  <div className="navbar-item">
                    <GiWallet />
                    <h6>{`My Inventory(${
                      inventoryNfts ? inventoryNfts.length : 0
                    })`}</h6>
                  </div>
                </Nav.Link>
              )}

              <Nav.Link
                href="/pledging"
                className={`header-menu p-2 text-decoration-none ${
                  location.pathname === '/pledging' ? 'active' : ''
                }`}
              >
                <div className="navbar-item">
                  <GiReceiveMoney />
                  <h6>Pledging</h6>
                </div>
              </Nav.Link>

              <Nav.Link
                href="/nft_mint"
                className={`header-menu p-2 text-decoration-none ${
                  location.pathname === '/nft_mint' ? 'active' : ''
                }`}
              >
                <div className="navbar-item">
                  <GiBlackKnightHelm />

                  <h6>Mint</h6>
                </div>
              </Nav.Link>
              <Nav.Link
                href="/top_hundred"
                className={`header-menu p-2 text-decoration-none ${
                  location.pathname === '/top_hundred' ? 'active' : ''
                }`}
              >
                <div className="navbar-item">
                  <AiFillStar />
                  <h6>Knights</h6>
                </div>
              </Nav.Link>

              <a
                href="https://lobby.magiccraft.io/?manageBalance=buyBnb"
                target="_blank"
                rel="noopener noreferrer"
                className={'header-menu p-2 text-decoration-none'}
              >
                <div className="navbar-item">
                  <IoMdSwap />
                  <h6>Buy $MCRT</h6>
                </div>
              </a>
              <Nav.Link
                href="/verify"
                className={`header-menu p-2 text-decoration-none ${
                  location.pathname === '/verify' ? 'active' : ''
                }`}
              >
                <div className="navbar-item">
                  <GoVerified />
                  <h6>Verify</h6>
                </div>
              </Nav.Link>
              <a
                href="https://lobby.magiccraft.io/"
                target="_blank"
                rel="noopener noreferrer"
                className={'header-menu p-2 text-decoration-none'}
              >
                <div className="navbar-item">
                  <FaGamepad />
                  <h6>Lobby</h6>
                </div>
              </a>
              <a
                href="https://lobby.magiccraft.io/stats"
                target="_blank"
                rel="noopener noreferrer"
                className={'header-menu p-2 text-decoration-none'}
              >
                <div className="navbar-item">
                  <FaGamepad />
                  <h6>Game Stats</h6>
                </div>
              </a>
              <Nav.Link
                href="/dao"
                className={`header-menu p-2 text-decoration-none ${
                  location.pathname === '/dao' ? 'active' : ''
                }`}
              >
                <div className="navbar-item">
                  <AiFillBank />
                  <h6>DAO</h6>
                </div>
              </Nav.Link>
            </Nav>
            <Nav
              className={`connect-wallet-btn connect-wallet-btn--desktop mint-page  ${
                !account ? 'connected' : ' '
              }`}
            >
              {account && (
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <img src={mcrtLogo} alt="" />

                    <div
                      className="d-flex flex-column"
                      style={{ marginRight: 40 }}
                    >
                      <h6 className="text-white">{mcrtBalance}</h6>
                      {/* {location.pathname !== '/nft_mint' && (
                        <p className="mb-0">
                          {`${account.substring(0, 6)}...${account.substring(
                            account.length - 4,
                          )}`}
                        </p>
                      )} */}
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center"
                    style={{ marginTop: 5 }}
                  >
                    <img src={bnbLogo} alt="" />

                    <div
                      className="d-flex flex-column"
                      style={{ marginRight: 40 }}
                    >
                      <h6 className="text-white">{bnb}</h6>
                    </div>
                  </div>
                </div>
              )}
              <ConnectWallet style={ConnectButtonStyle(account)} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
