import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
// import useWalletModal from 'widgets/WalletModal/useWalletModal';
import Modal from 'react-modal';

import './index.scss';
import { ToggleButton, ToggleButtonGroup, Button, Form } from 'react-bootstrap';
import mcrtLogo from 'assets/icon-mcrt.png';

import { useMagicNFT, useMarketplaceContract } from 'hooks/useContract';
import {
  getMagicNFTAddress,
  getMarketplaceAddress,
} from 'utils/addressHelpers';

import { ReactComponent as CExclamation } from 'assets/circle-exclamation.svg';
import { ReactComponent as TxConfirm } from 'assets/tx-confirm.svg';

interface Props {
  isSellModal: boolean;
  onClose: () => void;
  nftItem: any;
}

const SellModal: React.FC<Props> = ({ isSellModal, onClose, nftItem }) => {
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const [isHash, setIsHash] = useState(false);
  const [isReceipt, setIsReceipt] = useState(false);
  const [isAuction, setIsAuction] = useState('Fixed');
  const [fixedPrice, setFixedPrice] = useState('0');
  const [startPrice, setStartPrice] = useState('0');
  const [endPrice, setEndPrice] = useState('0');
  const [auctionDate, setAuctionDate] = useState('0');
  const [checked, setChecked] = useState(false);
  const ctMarketplace = useMarketplaceContract();
  const ctNFT = useMagicNFT(nftItem.contractAddress);

  const handleChange = (val: any) => setIsAuction(val);
  const handleFixedPrice = (e: any) => setFixedPrice(e.target.value);
  const handleStartPrice = (e: any) => setStartPrice(e.target.value);
  const handleEndPrice = (e: any) => setEndPrice(e.target.value);
  const handleAuctionDate = (e: any) => {
    if (Number(e.target.value) >= 999) {
      setAuctionDate('999');
      return;
    }

    setAuctionDate(e.target.value);
  };

  const sellItem = () => {
    setIsHash(true);
    if (isAuction == 'Fixed') {
      if (Number(fixedPrice) <= 0 || isNaN(Number(fixedPrice))) {
        toast.error('Price amount is invalid');
        setIsHash(false);
        return;
      }

      ctMarketplace.methods
        .createAuction(
          nftItem.contractAddress,
          nftItem.tokenID,
          ethers.utils.parseUnits(fixedPrice, 9).toString(),
          ethers.utils.parseUnits(fixedPrice, 9).toString(),
          30 * 24 * 3600, // 30 days
          // ethers.constants.AddressZero,
        )
        .send({ from: account })
        .on('receipt', (tx: String) => {
          toast.success('Your NFT has been listed successfully.');
          setIsReceipt(true);
          setIsHash(false);
        })
        .on('error', () => {
          toast.error('Your NFT listing has been failed.');
          setIsReceipt(false);
          setIsHash(false);
        });
    } else {
      if (
        Number(startPrice) <= 0 ||
        Number(endPrice) <= 0 ||
        isNaN(Number(startPrice)) ||
        isNaN(Number(endPrice))
      ) {
        toast.error('Price amount is invalid');
        setIsHash(false);
        return;
      }

      if (Number(auctionDate) < 1 || isNaN(Number(auctionDate))) {
        toast.error('Auction duration is invalid');
        setIsHash(false);
        return;
      }

      if (!Number.isInteger(Number(auctionDate))) {
        toast.error('Auction duration should be integer');
        setIsHash(false);
        return;
      }

      ctMarketplace.methods
        .createAuction(
          getMagicNFTAddress(),
          nftItem.tokenID,
          ethers.utils.parseUnits(startPrice, 9).toString(),
          ethers.utils.parseUnits(endPrice, 9).toString(),
          Math.floor(Number(auctionDate) * 24 * 3600),
          // ethers.constants.AddressZero,
        )
        .send({ from: account })
        .on('receipt', (tx: String) => {
          toast.success('Your NFT has been listed successfully.');
          setIsReceipt(true);
          setIsHash(false);
        })
        .on('error', () => {
          toast.error('Your NFT listing has been failed.');
          setIsReceipt(false);
          setIsHash(false);
        });
    }
  };

  const onSellItem = async () => {
    const isApproved = await ctNFT.methods
      .isApprovedForAll(account, getMarketplaceAddress())
      .call();

    if (!isApproved) {
      ctNFT.methods
        .setApprovalForAll(getMarketplaceAddress(), true)
        .send({ from: account })
        .on('receipt', (tx: String) => {
          sellItem();
        })
        .on('error', () => {
          toast.error('Approving has been failed.');
        });
    } else {
      sellItem();
    }

    // onClose();
  };

  const customStyles = {
    overlay: {
      zIndex: '999',
    },
  };

  return (
    <Modal
      isOpen={isSellModal}
      onRequestClose={onClose}
      style={customStyles}
      className="sellModal"
    >
      {!isReceipt && !isHash && (
        <>
          <h3>Sell Item</h3>
          <h5>
            Choose your sale method for{' '}
            <span>{nftItem.name + ' #' + nftItem.tokenID}</span>
          </h5>
          <div className="sellModal--tabs">
            <ToggleButtonGroup
              name="type"
              type="radio"
              value={isAuction}
              onChange={handleChange}
            >
              <ToggleButton id="tbg-btn-1" value="Fixed">
                Fixed Price
              </ToggleButton>
              <ToggleButton id="tbg-btn-2" value="Auction">
                Auction
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {isAuction == 'Fixed' ? (
            <div className="sellModal--fixed">
              <div className="sellModal--fixed--info">
                <div className="sellModal--fixed--info--infoItem">
                  <span>Sell At</span>
                  <div className="sellModal--fixed--info--infoItem--inputConainer">
                    <img src={mcrtLogo} alt="logo" />
                    <input
                      type="text"
                      className="sellModal--fixed--info--infoItem--inputConainer-input"
                      onInput={handleFixedPrice}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={fixedPrice}
                      maxLength={8}
                      min={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="sellModal--auction">
              <div className="sellModal--auction--title">
                For auctions, you can set the price to increase or descrease
                over time. Once it reaches the final price, it will stay on the
                market at this price. In order to get it back, you’ll have to
                cancel the sale.
              </div>
              <div className="sellModal--fixed--info">
                <div className="sellModal--fixed--info--infoItem">
                  <span>Start Price</span>
                  <div className="sellModal--fixed--info--infoItem--inputConainer">
                    <img src={mcrtLogo} alt="logo" />
                    <input
                      type="text"
                      className="sellModal--fixed--info--infoItem--inputConainer-input"
                      onInput={handleStartPrice}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="0"
                      value={startPrice}
                      min={0}
                      maxLength={8}
                    />
                  </div>
                </div>
                <div className="sellModal--fixed--info--infoItem">
                  <span>End Price</span>
                  <div className="sellModal--fixed--info--infoItem--inputConainer">
                    <img src={mcrtLogo} alt="logo" />
                    <input
                      type="text"
                      className="sellModal--fixed--info--infoItem--inputConainer-input"
                      onKeyPress={(event) => {
                        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onInput={handleEndPrice}
                      placeholder="0"
                      value={endPrice}
                      min={0}
                      maxLength={8}
                    />
                  </div>
                </div>
                <div className="sellModal--fixed--info--infoItem">
                  <span>Duration</span>
                  <div className="sellModal--fixed--info--infoItem--inputConainer">
                    <span>&nbsp;days</span>
                    <input
                      type="number"
                      className="sellModal--fixed--info--infoItem--inputConainer-input"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onInput={handleAuctionDate}
                      value={auctionDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="sellModal--confirm">
            <p>
              <CExclamation />A <strong>4.25%</strong> fee will be taken from
              the final sale price
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <Form.Check
                id="id-check-agree"
                label="I understand that after successfully bought, Item level will be reset to 1."
                name="group1"
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <Button disabled={!checked} onClick={onSellItem}>
                Confirm
              </Button>
            </div>
          </div>
        </>
      )}

      {isHash && (
        <div className="sellModal__hash">
          <div className="sellModal__spinner" />
          <h3>Complete the action in your wallet</h3>
          <p>
            To sell this item click on CONFIRM button in your wallet in order to
            complete the final transaction
          </p>
        </div>
      )}

      {isReceipt && (
        <div className="sellModal__receipt">
          <TxConfirm className="mb-2" />
          <h3>Transaction Submitted!</h3>
          <div className="d-flex">
            <Button onClick={() => navigate('/marketplace/profile')}>
              Go to My Inventory
            </Button>
            <Button variant="outline-secondary" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export { SellModal };
