import { useWeb3React } from '@web3-react/core';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import NFTDetail from 'pages/NFTDetail/NFTDetail';
import NFTInventory from 'pages/NFTInventory/NFTInventory';
import NFTMain from 'pages/NFTMain/NFTMain';
import NFTMarketplace from 'pages/NFTMarketplace/NFTMarketplace';
import NFTMintOld from 'pages/NFTMint/NFTMintOld';
import DogeMint from 'pages/DogeMint/DogeMint';

import NFTMint from 'pages/NFTMint/NFTMint';
import Swap from 'pages/Swap/Swap';
import VStaking from './pages/VStaking';
import TopHundred from 'pages/TopHundred';
import Header from './pages/Header';
import Footer from 'pages/Footer';
import usePersistConnect from 'hooks/usePersistConnect';
import Verify from 'pages/Verify';
import { Grid } from 'antd';
import { hotjar } from 'react-hotjar';
import { useEffect, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Dao from 'pages/Dao';

hotjar.initialize(3427174, 6);

function App() {
  const [isDisclaimer, setIsDisclaimer] = useState();

  const { account, provider } = useWeb3React();

  const breakpoint = Grid.useBreakpoint();

  usePersistConnect();

  const disableDisclaimer = () => {
    sessionStorage.setItem('isDisclaimer', 'false');
    setIsDisclaimer(false);
  };

  useEffect(() => {
    const sessionObj = sessionStorage.getItem('isDisclaimer');
    const tempIsDisclaimer = sessionObj ? JSON.parse(sessionObj) : true;

    setIsDisclaimer(tempIsDisclaimer === true);
  }, []);

  return (
    <BrowserRouter>
      <Header />
      {isDisclaimer ? (
        <div className="modal_overlay">
          <div className="modal_content">
            <p className="disclaimer">
              Use of MagicCraft's services, including the MCRT Token and
              pledging feature, is at your own risk. Please be aware that these
              services are provided on an "as is" and "as available" basis,
              without any warranties. MagicCraft will not be held liable for any
              damages that may arise from your use of these services.
              Additionally, the MCRT Token and MagicCraft's services are not
              available to residents of the United States or any other
              jurisdiction where they are prohibited by law.
            </p>
            <button
              onClick={disableDisclaimer}
              className="modal_content_button"
            >
              I understand
            </button>
          </div>
        </div>
      ) : null}
      <Routes>
        <Route
          exact
          path="/"
          element={<NFTMarketplace walletAddress={account} />}
        />
        <Route
          path="/pledging"
          element={<VStaking walletAddress={account} />}
        />
        <Route
          path="/marketplace"
          element={<NFTMarketplace walletAddress={account} />}
        />
        <Route
          path="/nft-mint-revelation"
          element={<NFTMint walletAddress={account} />}
        />
        <Route
          path="/nft_mint"
          element={<NFTMintOld walletAddress={account} />}
        />
        <Route
          path="/doge_mint"
          element={<DogeMint walletAddress={account} />}
        />

        <Route
          path="/doge-so-brave"
          element={<DogeMint walletAddress={account} />}
        />
        {/* TODO: should be hidden until it's completed */}
        <Route
          path="/marketplace/explorer"
          element={<NFTMain walletAddress={account} />}
        />
        <Route
          path="/marketplace/:nft_address/:id"
          element={<NFTDetail walletAddress={account} />}
        />
        <Route
          path="/marketplace/profile"
          element={<NFTInventory walletAddress={account} />}
        />
        <Route
          path="/top_hundred"
          element={<TopHundred walletAddress={account} />}
        />
        <Route path="/dao" element={<Dao />} />
        <Route path="/swap" element={<Swap />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
