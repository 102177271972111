import styled, { keyframes } from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { Grid } from 'antd';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
// import revelation1 from 'assets/revelation1.png';
import nftItem2 from 'assets/nft-item2.png';
import nftItem3 from 'assets/nft-item3.png';
import nftItem5 from 'assets/nft-item5.png';
import nftItem10 from 'assets/nft-item10.png';
import iconWallet from 'assets/Icon_Wallet.png';
import iconError from 'assets/error-icon.png';
import { fetchNFTMintAsync } from 'state/action';
import { useNFTMarketplaceState, useNFTMintState } from 'state/hooks';
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import { useDogeNft, useRevelation } from 'hooks/useContract';
import useWalletModal from 'widgets/WalletModal/useWalletModal';
import { AiOutlineClose } from 'react-icons/ai';
import showRamp from '../../api/utils/ramp';
import { Button } from 'components/Button';

import magicNFTContractJson from '../../constants/ABI/MagicNFT.json';

import { GiBlackKnightHelm } from 'react-icons/gi';

import discount from '../../assets/15discount.png';

// styles
import './index.scss';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { CloseIcon } from 'components/Svg';
import CollectionButton from 'components/CollectionButton/CollectionButton';

import Web3 from 'web3';
import HelpModal from 'components/HelpModal/HelpModal';
import GasFeeHelpContainer from 'components/GasFeeHelpContainer';

const E18 = BigNumber.from(10).pow(18);

const DogeMint = () => {
  const { account } = useWeb3React();
  const [timeLeft, setTimeLeft] = useState({});
  const [isDecember, setIsDecember] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [nftAvailable, setNftAvailable] = useState(9999);
  const [txHash, setTxHash] = useState('');
  const [itemIndex, setItemIndex] = useState(3);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0.15);
  const [isUtilitiesModal, setIsUtilitiesModal] = useState(false);
  const magicNFTContract = useDogeNft();
  const breakpoint = Grid.useBreakpoint();
  const { login, logout } = useAuth();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const { onPresentConnectModal } = useWalletModal(login, logout);
  const dispatch = useDispatch();
  const { collection } = useNFTMarketplaceState();

  const node = useRef(null);

  function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  const openHelpModal = () => {
    setIsHelpModalOpen(true);
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };

  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
    document.body.style.overflowY = 'unset';
    document.body.style.overflowX = 'hidden';
  };

  const openBSCScan = () => {
    const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
    const BSC_URL =
      CHAIN_ID === '56'
        ? 'https://bscscan.com/tx/'
        : 'https://testnet.bscscan.com/tx/';
    const URL = BSC_URL + txHash;
    window.open(URL);
  };

  // Countdown logic
  useEffect(() => {
    const getTargetDate = () => {
      // Set the target date to Tuesday, December 3rd, 2024, at 9 AM EST (which is 2 PM UTC)
      const targetDate = new Date(Date.UTC(2024, 11, 3, 14, 0, 0)); // 14:00 UTC is 9 AM EST on December 3rd, 2024
      return targetDate;
    };

    const targetDate = getTargetDate();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(interval);
        setIsDecember(true); // It's now the target time (Tuesday, December 3rd, 9AM EST)
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [img1, img2, img3, img5, img10] =
    collection === 'genesis'
      ? [
          <img
            alt="1 Character"
            src="https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp"
            style={{ height: '100%', width: '100%' }}
          />,
          <img
            alt="2 Characters"
            src={nftItem2}
            style={{ height: '100px', width: '100px' }}
          />,
          <img
            alt="1 Character"
            src={nftItem3}
            style={{ height: '100px', width: '100px' }}
          />,
          <img
            alt="5 Characters"
            src={nftItem5}
            style={{ height: '100px', width: '100px' }}
          />,
          <img
            alt="10 Characters"
            src={nftItem10}
            style={{ height: '100px', width: '100px' }}
          />,
        ]
      : [
          <img
            style={{ maxHeight: '100%', borderRadius: 8 }}
            alt="1 Character"
            src="https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp"
          />,
          <div style={{ display: 'flex', maxWidth: '100%', gap: 5 }}>
            <img
              style={{ maxHeight: '100%', borderRadius: 8 }}
              alt=""
              src="https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp"
            />
            <img
              style={{ maxHeight: '100%', borderRadius: 8 }}
              alt=""
              src="https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp"
            />
          </div>,
          <div
            style={{
              display: 'flex',
              maxWidth: '100%',
              maxHeight: '100%',
              gap: 5,
              position: 'relative',
            }}
          >
            <img
              style={{ borderRadius: 8, maxHeight: '100%', maxWidth: '100%' }}
              alt=""
              src="https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp"
            />
            <span
              style={{
                position: 'absolute',
                bottom: '0%',
                left: '10%',
                color: 'white',
                fontSize: 30,
                fontWeight: 700,
                textShadow: '0px 0px 10px black',
              }}
            >
              3x
            </span>
          </div>,
          <div
            style={{
              display: 'flex',
              maxWidth: '100%',
              maxHeight: '100%',
              gap: 5,
              position: 'relative',
            }}
          >
            <img
              style={{ borderRadius: 8, maxHeight: '100%', maxWidth: '100%' }}
              alt=""
              src="https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp"
            />
            <span
              style={{
                position: 'absolute',
                bottom: '0%',
                left: '10%',
                color: 'white',
                fontSize: 30,
                fontWeight: 700,
                textShadow: '0px 0px 10px black',
              }}
            >
              5x
            </span>
          </div>,
          <div
            style={{
              display: 'flex',
              maxWidth: '100%',
              maxHeight: '100%',
              gap: 5,
              position: 'relative',
            }}
          >
            <img
              style={{ borderRadius: 8, maxHeight: '100%', maxWidth: '100%' }}
              alt=""
              src="https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp"
            />
            <span
              style={{
                position: 'absolute',
                bottom: '0%',
                left: '10%',
                color: 'white',
                fontSize: 30,
                fontWeight: 700,
                textShadow: '0px 0px 10px black',
              }}
            >
              10x
            </span>
          </div>,
        ];

  const closeModal = () => setModalIsOpen(false);
  // const openBSCScan = () => {
  //   const BSC_URL = https://bscscan.com/tx/${txHash};
  //   window.open(BSC_URL, '_blank');
  // };
  const onMintNFT = async () => {
    if (account) {
      setIsMinting(true);
      // const whiteListPrice = await magicNFTContract.methods

      console.log(isDiscount);
      if (!isDiscount) {
        try {
          console.log(isDiscount);
          const publicMint = await magicNFTContract.methods.mint().send({
            from: account,
            gasPrice: ethers.utils.parseUnits('10', 'gwei'),
            gas: 250000,
          });
          console.log(publicMint);
          const mintTx = publicMint.wait();
          console.log(mintTx);
          setAlertType(1);
          setModalIsOpen(true);
          setTxHash(mintTx.transactionHash);
        } catch (err) {
          console.log(err);
        }
      }
    }
    setIsMinting(false);
  };

  if (!isDecember) {
    return (
      <CountdownWrapper>
        <ContentContainer>
          <QuestionMarkContainer>
            {/* <QuestionMark>?</QuestionMark> */}
            <video
              src="https://res.cloudinary.com/dfzcr2ch4/video/upload/v1732544181/WhatsApp_Video_2024-11-25_at_7.44.19_PM_h14pa1.mp4"
              autoPlay
              loop
              muted
              style={{
                width: '150px',
                height: '150px',
                borderRadius: '10%',
                objectFit: 'cover',
              }}
            ></video>
          </QuestionMarkContainer>
          <TitleContainer>
            <TwinklingStar3 />
            <TwinklingStar4 />
            <Title>Wow... much wait... very big...</Title>
            <TwinklingStar1 />
            <TwinklingStar2 />
            <TwinklingStar3 />
            <TwinklingStar4 />

            <TwinklingStar5 />
          </TitleContainer>

          <CountdownContainer>
            {Object.entries(timeLeft).map(([unit, value]) => (
              <CountdownItem key={unit}>
                <CountdownValue>
                  {value.toString().padStart(2, '0')}
                </CountdownValue>
                <CountdownLabel>{unit}</CountdownLabel>
              </CountdownItem>
            ))}
          </CountdownContainer>
        </ContentContainer>
      </CountdownWrapper>
    );
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <img alt="" src={alertType === 1 ? iconWallet : iconError} />
        <Message> {alert[alertType]}</Message>
        <Buttons>
          {alertType === 1 && (
            <BSCButton onClick={openBSCScan}>View in Actions</BSCButton>
          )}
          <CloseButton onClick={closeModal}>Close</CloseButton>
        </Buttons>
      </Modal>

      {isHelpModalOpen && (
        <HelpModal isModalOpen={isHelpModalOpen} closeModal={closeHelpModal} />
      )}

      <div className="nftmint">
        <div
          className={`nftmint__banner-container ${collection === '' ? '' : ''}`}
        >
          {isUtilitiesModal ? (
            <div className="nftmint__question-modal" ref={node}>
              <div className="nftmint__question-modal__icon">
                <AiOutlineClose onClick={() => setIsUtilitiesModal(false)} />
              </div>
              <ul className="nftmint__list">
                <li>
                  Earn more $MCRT by joining VIP Lobbies if you own 1 Genesis
                  NFT and 100,000 MCRT
                </li>
                <li>
                  Earn more $MCRT by joining Weekend Lobbies if you own 3
                  Revelation NFTs and 50,000 MCRT
                </li>
                <li>
                  Unlock even more earning potential with multipliers based on
                  the amount of NFTs you own, their rarity, and your $MCRT
                  holdings.
                  <a href="https://lobby.magiccraft.io/faq" target="_blank">
                    Click here for full details
                  </a>
                  .
                </li>
                <li>NFT revenue sharing (coming soon)</li>
              </ul>
            </div>
          ) : null}
          <div>
            <h1>Free Mint : Doge NFT</h1>
            <div className="nftmint__grouping__row">
              {/* <h5>Utilities for owning NFTs:</h5> */}
              {!breakpoint.sm ? <></> : null}
            </div>
            {breakpoint.sm ? (
              <ul className="nftmint__list">
                {/* <li>
                  Earn more $MCRT by joining VIP Lobbies if you own 1 Genesis
                  NFT and 100,000 MCRT
                </li>
                <li>
                  Earn more $MCRT by joining Weekend Lobbies if you own 3
                  Revelation NFTs and 50,000 MCRT
                </li>
                <li>
                  Unlock even more earning potential with multipliers based on
                  the amount of NFTs you own, their rarity, and your $MCRT
                  holdings.
                  <a href="https://lobby.magiccraft.io/faq" target="_blank">
                    Click here for full details
                  </a>
                  .
                </li>
                <li>NFT revenue sharing (coming soon)</li> */}
                <div>
                  {/* Free Mint: Doge NFT Register in the MagicCraft ecosystem to */}
                  Register in the MagicCraft ecosystem to claim your free Doge
                  NFT, unlocking an exclusive skin for Vega in MagicCraft Game.
                  <br />
                  {/* claim your free Doge NFT, unlocking an exclusive skin for Vega
                  in MagicCraft Game. So brave, so Doge... */}
                  So brave, so Doge...
                </div>
              </ul>
            ) : null}
            {/* <h5>Choose desired amount of NFTs to mint!</h5> */}
            {!breakpoint.sm ? (
              <div>
                Register in the MagicCraft ecosystem to claim your free Doge
                NFT, unlocking an exclusive skin for Vega in MagicCraft Game.
                <br />
                So brave, so Doge...
              </div>
            ) : null}
          </div>
          {breakpoint.sm ? (
            <div className="nftmint__grouping__col">
              <div className="nftmint__available-container">
                <h5 className="nftmint__available-container__text">
                  <GiBlackKnightHelm className="icon" />
                  Available amount:
                </h5>
                <h5>
                  {nftAvailable ? (
                    '11 / ' + nftAvailable + ' NFTs'
                  ) : (
                    <i className="fa fa-spinner fa-spin" />
                  )}
                </h5>
              </div>
              {/* <h4 onClick={() => setItemIndex(10)}>
               Buy 10 and get 1 free NFT 
              </h4> */}
            </div>
          ) : null}
        </div>
        {breakpoint.sm ? null : (
          <div className="nftmint__available-container">
            <h5 className="nftmint__available-container__text">
              <GiBlackKnightHelm className="icon" />
              Available amount:
            </h5>
            <h5>
              {nftAvailable ? (
                '11 / ' + nftAvailable + ' NFT'
              ) : (
                <i className="fa fa-spinner fa-spin" />
              )}
            </h5>
          </div>
        )}
        {!breakpoint.xl && (
          <Button
            variant="subtle"
            className="!w-[auto] mb-4"
            onClick={showRamp}
          >
            Buy BNB with FIAT
          </Button>
        )}
        {/* <CollectionButton /> */}
        <div className="nftmint__itemList">
          <div
            className={
              itemIndex === 1
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(1)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img1}</div>
            <div className="nftmint__itemList--item--title">
              <h3>So Doge</h3>
              {/* {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale && publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale && publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5> */}
            </div>
          </div>
          {/*<div
            className={
              itemIndex === 2
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(2)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img2}</div>
            <div className="nftmint__itemList--item--title">
              <h3>2 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      2 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        2 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    2 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  2 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
          <div
            className={
              itemIndex === 3
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(3)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img3}</div>
            <div className="nftmint__itemList--item--title">
              <h3>3 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      3 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        3 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    3 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  3 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
          <div
            className={
              itemIndex === 5
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(5)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img5}</div>
            <div className="nftmint__itemList--item--title">
              <h3>5 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      5 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        5 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    5 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  5 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
          <div
            className={
              itemIndex === 10
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            // className="nftmint__itemList--itemSold"
            onClick={() => setItemIndex(10)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img10}</div>
            <div className="nftmint__itemList--item--title">
              <h3>10 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      10 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        10 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    10 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  10 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>*/}
        </div>

        {!breakpoint.xl && itemIndex > 0 && (
          <div className="nftmint__button_mobile">
            <div className="nftmint__button_mobile--info">
              <h3>{'1 Character'}</h3>
            </div>
            {account ? (
              isMinting ? (
                <button disabled>
                  <span className="d-flex flex-row justify-content-center align-items-center">
                    <i className="fa fa-spinner fa-spin"></i>
                    <div className="ms-2">Mint</div>
                  </span>
                </button>
              ) : (
                <button
                  style={{ padding: '1rem 2rem', width: 'auto' }}
                  onClick={onMintNFT}
                >
                  Mint
                </button>
              )
            ) : (
              <button onClick={onPresentConnectModal}>Mint</button>
            )}
          </div>
        )}
        {breakpoint.xl && (
          <div className="nftmint__button">
            <div className="flex gap-4 mt-2">
              {itemIndex > 0 ? (
                account ? (
                  isMinting ? (
                    <button disabled>
                      <span className="d-flex flex-row justify-content-center align-items-center">
                        <i className="fa fa-spinner fa-spin"></i>
                        <div className="ms-2">Mint</div>
                      </span>
                    </button>
                  ) : (
                    <button onClick={onMintNFT}>Mint</button>
                  )
                ) : (
                  <button onClick={onPresentConnectModal}>Mint</button>
                )
              ) : (
                <button
                  disabled
                  style={{
                    background: '#333455',
                    color: '#212249',
                    cursor: 'not-allowed',
                  }}
                >
                  Choose Amount
                </button>
              )}
              <button onClick={showRamp}>Buy BNB with FIAT</button>
            </div>
          </div>
        )}
      </div>

      <GasFeeHelpContainer
        content="Here's how to fix gas issues"
        openStakingHelpModal={openHelpModal}
        position="center"
      />
    </>
  );
};

const Message = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const BSCButton = styled.button`
  background: #316bff;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 165px;
  height: 56px;
  border-width: 0px;
  height: 56px;
  width: 165px;
  padding: 18px, 36px, 18px, 36px;
  margin-right: 1rem;
`;

const CloseButton = styled.button`
  height: 56px;
  width: 164px;
  border-radius: 8px;
  padding: 18px, 36px, 18px, 36px;
  border: 2px solid #76779a;
  border-radius: 8px;
  background: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #76779a;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#232449',
    padding: '20px',
    borderRadius: '20px',
    textAlign: 'center',
    color: '#fff',
  },
};

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const glowPulse = keyframes`
  0% { box-shadow: 0 0 30px 10px rgba(194, 165, 255, 0.3); }
  50% { box-shadow: 0 0 40px 15px rgba(194, 165, 255, 0.5); }
  100% { box-shadow: 0 0 30px 10px rgba(194, 165, 255, 0.3); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 5px rgba(194, 165, 255, 0.5); }
  50% { box-shadow: 0 0 20px rgba(194, 165, 255, 0.5); }
  100% { box-shadow: 0 0 5px rgba(194, 165, 255, 0.5); }
`;

const twinkle = keyframes`
  0% { opacity: 0.3; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0.3; transform: scale(0.8); }
`;

const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #020407, #010317);
  color: #ffffff;
  padding: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 100%;
`;

const QuestionMarkContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  animation: ${float} 3s ease-in-out infinite,
    ${glowPulse} 4s ease-in-out infinite;
  border-radius: 20px;
  box-shadow: 0 0 30px 10px rgba(194, 165, 255, 0.3);
  filter: drop-shadow(0 0 10px rgba(194, 165, 255, 0.5));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
`;

const TitleContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const TwinklingStar = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #c2a5ff;
  border-radius: 50%;
  animation: ${twinkle} 2s infinite ease-in-out;
`;

const TwinklingStar1 = styled(TwinklingStar)`
  top: -10px;
  left: 10%;
  animation-delay: 0.5s;
`;

const TwinklingStar2 = styled(TwinklingStar)`
  top: 50%;
  right: 5%;
  animation-delay: 1s;
`;

const TwinklingStar3 = styled(TwinklingStar)`
  bottom: -5px;
  left: 30%;
  animation-delay: 1.5s;
`;

const TwinklingStar4 = styled(TwinklingStar)`
  top: -5px;
  right: 20%;
  animation-delay: 0.7s;
`;

const TwinklingStar5 = styled(TwinklingStar)`
  bottom: 10px;
  left: 15%;
  animation-delay: 1.2s;
`;

const Title = styled.h1`
  font-family: Futura, Sans-serif;
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  color: #98fff9;
`;

const CountdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;

  padding: 20px;
  min-width: 120px;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
  animation: ${glow} 2s ease-in-out infinite;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    min-width: 100px;
    padding: 15px;
  }

  @media (max-width: 480px) {
    min-width: 80px;
    padding: 10px;
  }
`;

const CountdownValue = styled.span`
  font-size: 3rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const CountdownLabel = styled.span`
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 5px;
  opacity: 0.8;

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export default DogeMint;
