/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchMintStatus } from './fetchMint';

const initialState: any = {
  publicSale: false,
  whitelistSale: false,
  publicPrice: 0,
  whiteListPrice: 0,
};

export const StakingSlice = createSlice({
  name: 'NFTMINT',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.publicSale = action.payload.publicSale;
      state.whitelistSale = action.payload.whitelistSale;
      state.publicPrice = action.payload.publicPrice;
      state.whiteListPrice = action.payload.whiteListPrice;
    },
  },
});

// Actions
export const { setData } = StakingSlice.actions;

export const fetchNFTMintAsync = () => async (dispatch: any) => {
  const { publicSale, whitelistSale, publicPrice, whiteListPrice } =
    await fetchMintStatus();
  dispatch(
    setData({
      publicSale: publicSale,
      whitelistSale: whitelistSale,
      publicPrice: publicPrice,
      whiteListPrice: whiteListPrice,
    }),
  );
};

export default StakingSlice.reducer;
