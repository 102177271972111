import { useEffect, useState } from 'react';
import { HacklessSwap } from '@hackless-project/swap';
import '@hackless-project/swap/dist/style.css';

// styles
import './index.scss';
import { Button } from 'components/Button';

const Swap = (props) => {
  const [isModal, setIsModal] = useState(true);

  useEffect(() => {
    const Swapper = new HacklessSwap(process.env.REACT_APP_HACKLESS_KEY);
    Swapper.create('#swap-widget');

    return () => {};
  }, []);

  return (
    <div className="swap">
      <div id="swap-widget" />
      {isModal ? (
        <div className="modal_overlay">
          <div className="modal_content">
            <p className="disclaimer">
              To purchase MCRT by exchanging with another cryptocurrency, you
              will need to connect your wallet to our website. Please note that
              by connecting your wallet, you agree to our Terms and Conditions,
              as well as our Privacy Policy. By clicking "I Agree" below, you
              confirm that you have reviewed and accepted our terms and
              conditions, and that you understand and accept the risks
              associated with buying and holding MCRT tokens. Your continued use
              of our website constitutes your ongoing acceptance of our terms
              and conditions.
            </p>
            <button
              onClick={() => setIsModal(false)}
              className="modal_content_button"
            >
              I agree
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Swap;
