import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { Grid } from 'antd';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import {
  fetchAccountMagicNftsAsync,
  setAccountMagicNftsEmptyAsync,
} from 'state/action';
import { ethers } from 'ethers';
import { useNFTMarketplaceState } from 'state/hooks';
import { useWalletModal } from 'widgets/WalletModal';

import InventoryItem from './NFTItem';

// styles
import './index.scss';

const NFTInventory = (props) => {
  const filterList = [
    'Highest Price First',
    'Lowest Price First',
    'Latest First',
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [itemCnt, setItemCnt] = useState(0);
  const [characterCnt, setCharacterCnt] = useState(0);
  const [nftListFilter, setNFTListFilter] = useState(
    filterList.includes(localStorage.getItem('filterlist'))
      ? localStorage.getItem('filterlist')
      : filterList[0],
  );
  const [landCnt, setLandCnt] = useState(0);
  const [bnbPrice, setBNBPrice] = useState(0);
  const [mcrtPrice, setMCRTPrice] = useState(0);
  const [interval, setIntervalNum] = useState(null);
  const { account } = useWeb3React();
  const breakpoint = Grid.useBreakpoint();
  const { logout, login } = useAuth();
  const { inventoryNfts } = useNFTMarketplaceState();
  const { onPresentConnectModal } = useWalletModal(login, logout);

  const handleListFilter = (val) => {
    setNFTListFilter(val);
    localStorage.setItem('filterlist', val);
  };

  useEffect(() => {
    if (!inventoryNfts || inventoryNfts.length === 0) return;
    setItemCnt(0);
    setCharacterCnt(inventoryNfts.length);
    setLandCnt(0);

    return () => {};
  }, [inventoryNfts]);

  useEffect(() => {
    if (!account) {
      dispatch(setAccountMagicNftsEmptyAsync());
      if (interval) clearInterval(interval);

      return;
    }
    dispatch(fetchAccountMagicNftsAsync(account));

    if (interval) {
      clearInterval(interval);
    }

    setIntervalNum(
      setInterval(() => {
        dispatch(fetchAccountMagicNftsAsync(account));
      }, 30000),
    );

    getPriceData();

    return () => {
      setCharacterCnt(0);
      setLandCnt(0);
      setItemCnt(0);
      dispatch(setAccountMagicNftsEmptyAsync());
      if (interval) clearInterval(interval);

      setIntervalNum(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const getPriceData = async () => {
    const priceData = await axios.get(
      `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,
    );

    if (priceData.data) {
      setBNBPrice(Number(priceData.data.price));
    }

    const mcrtPriceData = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=magiccraft&vs_currencies=usd',
    );
    if (mcrtPriceData.data) {
      setMCRTPrice(Number(mcrtPriceData.data.magiccraft.usd));
    }
  };

  const getCurrentPrice = (inventoryData) => {
    const today = new Date();
    let data = { ...inventoryData };

    if (
      !data ||
      !data.startingPrice ||
      !data.endingPrice ||
      !data.duration ||
      !data.startAt
    )
      return 0;

    data.startingPrice = Number(data.startingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });

    data.endingPrice = Number(data.endingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });

    if (!data.isMCRT) {
      const bnbAmt = ethers.utils.formatEther(
        ethers.BigNumber.from(`${data.startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${data.endingPrice}`).sub(
                ethers.BigNumber.from(`${data.startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
      );

      return Number(bnbAmt);
    } else {
      const mcrtAmt = ethers.utils.formatUnits(
        ethers.BigNumber.from(`${data.startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${data.endingPrice}`).sub(
                ethers.BigNumber.from(`${data.startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
        9,
      );

      return Number(mcrtAmt);
    }
  };

  const getFilteredList = () => {
    if (!inventoryNfts) return [];

    if (nftListFilter === filterList[0]) {
      return [...inventoryNfts].sort((a, b) =>
        getCurrentPrice(b) == 0 && getCurrentPrice(a) == 0
          ? 0
          : getCurrentPrice(b) - getCurrentPrice(a),
      );
    }

    if (nftListFilter === filterList[1]) {
      return [...inventoryNfts].sort((a, b) =>
        getCurrentPrice(b) == 0 && getCurrentPrice(a) == 0
          ? 0
          : getCurrentPrice(a) - getCurrentPrice(b),
      );
    }

    if (nftListFilter === filterList[2]) {
      return [...inventoryNfts].sort(
        (a, b) => Number(b.tokenID) - Number(a.tokenID),
      );
    }

    return inventoryNfts;
  };

  const sortedInventoryNfts = getFilteredList();

  if (!account) {
    return (
      <div className="container">
        <div className="nft-inventory__empty">
          <h3>Please connect your wallet first!</h3>

          <button
            onClick={() => {
              onPresentConnectModal();
            }}
          >
            <strong>Connect Wallet</strong>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="nft-inventory">
        <div className="nft-inventory__top">
          <div className="nft-inventory__title">My Inventory</div>
          <div className="nft-inventory__rarity-count">
            <div className="d-flex align-items-center item-rarity">
              <div className="nft-inventory__rarity-count__item">
                <img
                  src="/assets/inventory/character.png"
                  alt="nft-charactor"
                />
                <h3>{characterCnt}</h3>
                <p>Characters</p>
              </div>

              <div className="nft-inventory__rarity-count__item">
                <img src="/assets/inventory/item.png" alt="nft-items" />
                <h3>{itemCnt}</h3>
                <p>Items</p>
              </div>

              <div className="nft-inventory__rarity-count__item">
                <img src="/assets/inventory/land.png" alt="nft-land" />
                <h3>{landCnt}</h3>
                <p>Lands</p>
              </div>
            </div>
          </div>
        </div>

        <div className="nft-inventory__division" />
        <div className="nft-inventory__option">
          <div className="d-flex align-items-center justify-content-between">
            <h3>{`Showing ${sortedInventoryNfts.length} items`}</h3>

            <Dropdown onSelect={handleListFilter}>
              <Dropdown.Toggle id="dropdown-filter">
                {nftListFilter}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {filterList.map((filter) => {
                  if (nftListFilter !== filter) {
                    return (
                      <Dropdown.Item href="" eventKey={filter}>
                        {filter}
                      </Dropdown.Item>
                    );
                  }
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {sortedInventoryNfts && sortedInventoryNfts.length > 0 && (
          <div className="nft-inventory__content">
            <div className="nft-inventory__items row">
              {sortedInventoryNfts.map((inventoryNft, idx) => {
                return (
                  <div
                    className="col-6 col-xs-6 col-sm-4 col-lg-3 col-xl-2"
                    key={inventoryNft.tokenID}
                  >
                    {!inventoryNft.isMCRT ? (
                      <InventoryItem
                        key={inventoryNft.tokenID}
                        data={inventoryNft}
                        price={
                          getCurrentPrice(inventoryNft) == 0
                            ? null
                            : getCurrentPrice(inventoryNft)
                        }
                        priceDollar={
                          getCurrentPrice(inventoryNft) == 0
                            ? null
                            : getCurrentPrice(inventoryNft) * bnbPrice
                        }
                      />
                    ) : (
                      <InventoryItem
                        key={inventoryNft.tokenID}
                        data={inventoryNft}
                        mcrtPrice={
                          getCurrentPrice(inventoryNft) == 0
                            ? null
                            : getCurrentPrice(inventoryNft)
                        }
                        mcrtPriceDollar={
                          getCurrentPrice(inventoryNft) == 0
                            ? null
                            : getCurrentPrice(inventoryNft) * mcrtPrice
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {sortedInventoryNfts && sortedInventoryNfts.length === 0 && (
          <>
            {inventoryNfts ? (
              <div className="nft-inventory__empty">
                <h3>You have no items in your inventory</h3>

                <button onClick={() => navigate('/marketplace')}>
                  <strong>Go to Marketplace</strong>
                </button>
              </div>
            ) : (
              <div className="nft-inventory__empty">
                <div className="nft-inventory__spinner" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NFTInventory;
