import { chainId } from 'config';

import stakingContractJson from '../constants/ABI/MCRTStake.json';
import tokenContractJson from '../constants/ABI/MCRTToken.json';
import pointContractJson from '../constants/ABI/points.json';
import multiCallContractJson from '../constants/ABI/MultiCall.json';
import magicNFTContractJson from '../constants/ABI/MagicNFT.json';
import marketplaceContractJson from '../constants/ABI/Marketplace.json';
import nftRedeemJson from '../constants/ABI/NFTRedeem.json';

// const chainId = process.env.REACT_APP_CHAIN_ID;

export const getMCRTStakeAddress = () => {
  return stakingContractJson.contract[chainId];
};

export const getRevelationAddress = () => {
  return process.env.REACT_APP_REVELATION_CONTRACT_ADDRESS;
}

export const getMCRTTokenAddress = () => {
  return tokenContractJson.contract[chainId];
};

export const getPointsAddress = () => {
  return pointContractJson.contract[chainId];
};

export const getMulticallAddress = () => {
  return multiCallContractJson.contract[chainId];
};

export const getMagicNFTAddress = () => {
  return magicNFTContractJson.contract[chainId];
};

export const getMarketplaceAddress = () => {
  return marketplaceContractJson.contract[chainId];
};

export const getNFTRedeemAddress = () => {
  return nftRedeemJson.contract[chainId];
};

export const getShortenAddress = (address) => {
  return `${address.slice(0, 6)} ... ${address.slice(-6)}`;
};
