import { useEffect, useState } from 'react';
import useWeb3 from './useWeb3';
import {
  getMCRTStakeAddress,
  getMCRTTokenAddress,
  getPointsAddress,
  getMarketplaceAddress,
  getNFTRedeemAddress,
  getRevelationAddress,
} from '../utils/addressHelpers';

import stakingContractJson from '../constants/ABI/MCRTStake.json';
import tokenContractJson from '../constants/ABI/MCRTToken.json';
import pointContractJson from '../constants/ABI/points.json';
import magicNFTContractJson from '../constants/ABI/MagicNFT.json';
import marketplaceJson from '../constants/ABI/Marketplace.json';
import nftRedeemJson from '../constants/ABI/NFTRedeem.json';
import RevelationABIJson from '../constants/ABI/Revelation.abi.json';
import DogeJson from '../constants/ABI/DogeMint.json'

import { getWeb3NoAccount } from 'utils/web3';

//web3
import Web3 from 'web3';
let web3 = window.ethereum ? new Web3(window.ethereum) : getWeb3NoAccount();

const useContract = (abi, address, contractOptions = null) => {
  // const web3 = useWeb3();
  // let web3 = new Web3(window.ethereum);
  const [contract, setContract] = useState(
    new web3.eth.Contract(abi, address, contractOptions),
  );

  useEffect(() => {
    setContract(new web3.eth.Contract(abi, address, contractOptions));
  }, [abi, address, contractOptions, web3]);

  return contract;
};

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useStakeContract = () => {
  return useContract(stakingContractJson.abi, getMCRTStakeAddress());
};

export const usePointContract = () => {
  return useContract(pointContractJson.abi, getPointsAddress());
};

export const useMCRT = () => {
  return useContract(tokenContractJson.abi, getMCRTTokenAddress());
};

export const useRevelation = () => {
  return useContract(RevelationABIJson, getRevelationAddress());
}

export const useDogeNft = () => {
  return useContract(DogeJson.abi, DogeJson.contract[56]);
}

export const useMagicNFT = (contractAddress = null) => {
  const currentCollection = localStorage.getItem('collection');
  let address;

  switch (currentCollection) {
    case 'genesis':
    default: {
      address = process.env.REACT_APP_GENESIS_CONTRACT_ADDRESS || '';
      break;
    }
    case 'revelation':
      address = process.env.REACT_APP_REVELATION_CONTRACT_ADDRESS || '';
  }

  if (contractAddress) {
    address = contractAddress;
  }

  return useContract(magicNFTContractJson.abi, address);
};

export const useMarketplaceContract = () => {
  return useContract(marketplaceJson.abi, getMarketplaceAddress());
};

export const useNFTRedeemContract = () => {
  return useContract(nftRedeemJson.abi, getNFTRedeemAddress());
};

export default useContract;
