import axios from 'axios';
import { Grid } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getRecentlySoldNfts, getMCRTData } from 'api/utils';
import { BigNumber, ethers } from 'ethers';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { getRarityFrame, getNameBgColor } from 'utils/nftHelper';
import { useNFTMarketplaceState } from 'state/hooks';
import { useMagicNFT } from 'hooks/useContract';
import { Nav } from 'react-bootstrap';
import receipt from '../../assets/icon-receipt.png';
import people from '../../assets/icon-people.png';
import shop from '../../assets/icon-shop.png';
import swordsIcon from '../../assets/icon-swords.svg';
import { BiChevronRight } from 'react-icons/bi';
import mcrtLogo from 'assets/icon-mcrt.png';
import bnbLogo from 'assets/icon-bnb.png';
import { getMcrtPrice } from '../../api/utils/index';
import { fetchMagicNftsAsync } from 'state/action';
import { getNftContractAddress } from 'state/nftMarketplace';
// styles
import './index.scss';
import moment from 'moment';

TimeAgo.addDefaultLocale(en);

const E18 = BigNumber.from(10).pow(18);
const E9 = BigNumber.from(10).pow(9);

const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;

const NFTMarketplace = (props) => {
  // const [activeTimeBtn, setActiveTimeBtn] = useState('24h');
  const [listedType, setListedType] = useState('characters');
  // const [soldType, setSoldType] = useState('characters');
  const [MCRTPrice, setMCRTPrice] = useState(0);
  const [soldNfts, setSoldNfts] = useState([]);
  const [recentNfts, setRecentNfts] = useState([]);
  const [MCRTData, setMCRTData] = useState({});
  const [BNBPrice, setBNBPrice] = useState(0);
  // const { account } = useWeb3React();
  const { listedNfts, collection } = useNFTMarketplaceState();
  const location = useLocation();

  const ctNFT = useMagicNFT();

  const breakpoint = Grid.useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
      if (Math.round(elapsed / msPerMinute) === 1) {
        return Math.round(elapsed / msPerMinute) + ' minute ago';
      } else {
        return Math.round(elapsed / msPerMinute) + ' minutes ago';
      }
    } else if (elapsed < msPerDay) {
      if (Math.round(elapsed / msPerHour) === 1) {
        return Math.round(elapsed / msPerHour) + ' hour ago';
      } else {
        return Math.round(elapsed / msPerHour) + ' hours ago';
      }
    } else if (elapsed < msPerMonth) {
      if (Math.round(elapsed / msPerDay) === 1) {
        return Math.round(elapsed / msPerDay) + ' day ago';
      } else {
        return Math.round(elapsed / msPerDay) + ' days ago';
      }
    } else if (elapsed < msPerYear) {
      if (Math.round(elapsed / msPerMonth) === 1) {
        return Math.round(elapsed / msPerMonth) + ' month ago';
      } else {
        return Math.round(elapsed / msPerMonth) + ' months ago';
      }
    } else {
      if (Math.round(elapsed / msPerYear) === 1) {
        return Math.round(elapsed / msPerYear) + ' year ago';
      } else {
        return Math.round(elapsed / msPerYear) + ' years ago';
      }
    }
  }

  const goToNftDetail = (id) => {
    localStorage.setItem('prevPath', location.pathname);
    navigate(`/marketplace/${getNftContractAddress()}/${id}`);
  };

  const onBtnMint = () => {
    let path = `/nft_mint`;
    navigate(path);
  };

  const onBtnMarketplace = () => {
    navigate(`/marketplace/explorer`);
  };

  function redirect(path) {
    navigate(path);
  }

  function showAmount(amount, isMCRT) {
    const balance = isMCRT
      ? BigNumber.from(amount.toString())
          .mul(100)
          .div(chainId === 56 ? E9 : E18)
          .toNumber() / 100
      : BigNumber.from(amount.toString()).mul(100).div(E18).toNumber() / 100;

    return balance;
  }

  function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  function returnRarity(selectedNft) {
    const rarity = selectedNft.attributes.find(
      (it) => it.trait_type === 'rarity',
    )?.value;

    return rarity;
  }

  function returnClass(selectedNft) {
    let classType = '';
    if (selectedNft.name.includes('Arcana')) {
      classType = 'Arcana';
    } else if (selectedNft.name.includes('Knight')) {
      classType = 'Knight';
    } else if (
      selectedNft.contractAddress ===
      '0xafc9f628220eb9efc313ded8d131253f633f6be9'
    ) {
      return undefined;
    } else {
      classType = 'Hunter';
    }
    return classType;
  }

  async function getMCRTInfo() {
    try {
      const holders = await ctNFT.methods.totalSupply().call();
      getMCRTData().then((response) => {
        setMCRTData({
          marketCap: response.market_data.market_cap.usd,
          totalVolume: response.market_data.total_volume.usd,
          holders: holders,
        });
      });
    } catch (error) {
      console.warn(error);
    }
  }

  async function getBNBPrice() {
    const priceData = await axios.get(
      `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,
    );

    if (priceData.data) {
      setBNBPrice(Number(priceData.data.price));
    }
  }

  const getCurrentPrice = (inventoryData) => {
    const today = new Date();
    let data = { ...inventoryData };

    if (
      !data ||
      !data.startingPrice ||
      !data.endingPrice ||
      !data.duration ||
      !data.startAt
    )
      return 0;

    data.startingPrice = Number(data.startingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });

    data.endingPrice = Number(data.endingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });
    if (!data.isMCRT) {
      const bnbAmt = ethers.utils.formatEther(
        ethers.BigNumber.from(`${data.startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${data.endingPrice}`).sub(
                ethers.BigNumber.from(`${data.startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
      );

      return Number(bnbAmt);
    } else {
      const mcrtAmt = ethers.utils.formatUnits(
        ethers.BigNumber.from(`${data.startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${data.endingPrice}`).sub(
                ethers.BigNumber.from(`${data.startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
        9,
      );

      return Number(mcrtAmt);
    }
  };

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  }

  const getThumbnailImage = (url) => {
    const prevLinks = [
      'QmTPtJSxd3YWXNKfYLYkVVUe4nazk3yxhp2XQfgARzcs7y',
      'QmX1dhoSQqq9eMyzzFBSAYgQ5119Ap8h7VC2evppGs93W9',
      'QmNdDumUU4KDSScPkohP4fH2T5YwxNJTbQf4Cy8D3TcP59',
    ];
    const newLinks = [
      'QmVHWN3nP2vpMjT2w1s3fRRvHPEGtGHXoTQbkYEXZHsZBq',
      'QmbYXYGUYbWPALxh66MUbrUH7FC7eUqa8rXeVMQMDbGrga',
      'QmX8UUL4vE3NzRaXMivCoyL9TR6PR2A9GpvcVw9UdXB3Yb',
    ];

    let response;
    prevLinks.forEach((it, idx) => {
      if (url.includes(it)) {
        response = url.replace(it, newLinks[idx]);
      }
    });

    return response || url;
  };

  useEffect(() => {
    getBNBPrice();
    getMcrtPrice().then((response) => {
      setMCRTPrice(response);
    });
    getMCRTInfo();
  }, []);

  useEffect(() => {
    if (collection) {
      const contractAddress = getNftContractAddress();
      dispatch(fetchMagicNftsAsync(0, undefined, true));

      getRecentlySoldNfts(contractAddress).then((response) => {
        setSoldNfts(response.slice(0, 5));
      });
    }
  }, [collection]);

  useEffect(() => {
    if (listedNfts) {
      setRecentNfts(listedNfts.slice(0, 5));
    }
  }, [listedNfts]);

  return (
    <div className="marketplace container">
      {/* banner */}
      <div className="marketplace__banner">
        <div className="marketplace__banner-content">
          <div className="marketplace__banner--info">
            <h1>MagicCraft NFT Marketplace</h1>
            <p>
              Experience true ownership and freedom with MagicCraft's NFT
              Marketplace. Buy, sell, and trade game assets directly on the
              blockchain, securely stored in your own wallet. Embark on your
              blockchain adventure today!
            </p>
          </div>
          {breakpoint.lg && (
            <div className="marketplace__banner--pages marketplace__banner--pages--desktop">
              <button
                onClick={onBtnMarketplace}
                className="marketplace__banner--pages--btnInventory"
              >
                Marketplace
              </button>
              <button onClick={onBtnMint}>Minting Page</button>
            </div>
          )}

          {breakpoint.sm && !breakpoint.lg && (
            <div className="marketplace__banner--pages marketplace__banner--pages--tablet">
              <button
                onClick={onBtnMarketplace}
                className="marketplace__banner--pages--btnInventory"
              >
                Marketplace
              </button>
              <button onClick={onBtnMint}>Minting Page</button>
            </div>
          )}
        </div>
      </div>
      {!breakpoint.sm && (
        <div className="marketplace__banner--pages marketplace__banner--pages--mobile">
          <button
            onClick={onBtnMarketplace}
            className="marketplace__banner--pages--btnInventory"
          >
            Marketplace
          </button>
          <button onClick={onBtnMint} style={{ whiteSpace: 'nowrap' }}>
            Minting Page
          </button>
        </div>
      )}
      <div className="marketplace__data-container">
        <div className="marketplace__data-container-data">
          <img src={shop} alt="shop-icon" />
          <div className="marketplace__data-container-data__text">
            <p>Market Cap</p>
            <h1>
              <span>Ξ</span>
              {MCRTData.marketCap ? numberWithCommas(MCRTData.marketCap) : 0}
            </h1>
          </div>
        </div>
        <div className="marketplace__data-container-data">
          <img src={receipt} alt="receipt-icon" />
          <div className="marketplace__data-container-data__text">
            <p>Total Volume</p>
            <h1>
              <span>Ξ</span>
              {MCRTData.totalVolume
                ? numberWithCommas(MCRTData.totalVolume)
                : 0}
            </h1>
          </div>
        </div>
        <div className="marketplace__data-container-data">
          <img src={people} alt="people-icon" />
          <div className="marketplace__data-container-data__text">
            <p>NFT Holders</p>
            <h1>{MCRTData.holders ? numberWithCommas(MCRTData.holders) : 0}</h1>
          </div>
        </div>
      </div>{' '}
      {/* <CollectionButton /> */}
      {/* <div className="marketplace__time-btn-container">
        <div
          onClick={() => setActiveTimeBtn('24h')}
          className={`${activeTimeBtn === '24h' ? 'active' : ''}`}
        >
          <p>Last 24 Hours</p>
        </div>
        <div
          onClick={() => setActiveTimeBtn('7d')}
          className={`${activeTimeBtn === '7d' ? 'active' : ''}`}
        >
          <p>7 Days</p>
        </div>
        <div
          onClick={() => setActiveTimeBtn('30d')}
          className={`${activeTimeBtn === '30d' ? 'active' : ''}`}
        >
          <p>30 Days</p>
        </div>
      </div> */}
      <div className="marketplace__wrapper">
        <section className="marketplace__wrapper__transactions-container">
          <h1 className="marketplace__wrapper__transactions-container__heading">
            Recently Listed
          </h1>
          <div className="marketplace__wrapper__transactions-container__buttons-container">
            <div
              className={`marketplace__wrapper__transactions-container__buttons-container__button ${
                listedType === 'characters' ? 'active' : ''
              }`}
              onClick={() => setListedType('characters')}
            >
              <img src={swordsIcon} alt="swords-icon" />
              <h2>Characters</h2>
            </div>
            {/* <div
              className={`marketplace__wrapper__transactions-container__buttons-container__button ${
                listedType === 'items' ? 'active' : ''
              }`}
            >
              <img src={chestIcon} alt="chest-icon" />
              <h2>Items</h2>
            </div>
            <div
              className={`marketplace__wrapper__transactions-container__buttons-container__button ${
                listedType === 'lands' ? 'active' : ''
              }`}
            >
              <img src={directionsIcon} alt="directions-icon" />
              <h2>Lands</h2>
            </div> */}
          </div>
          <div className="marketplace__wrapper__transactions-container__listed-items-container">
            {recentNfts.map((nft, index) => {
              const rarity = returnRarity(nft);
              const isVideo = nft.image.includes('mp4');
              const _class = returnClass(nft);

              return (
                <div
                  className="marketplace__wrapper__transactions-container__listed-items-container__item"
                  key={index}
                  onClick={() => goToNftDetail(nft.tokenID)}
                >
                  <div className="marketplace__wrapper__transactions-container__listed-items-container__item-nft-container">
                    {rarity && (
                      <img
                        src={getRarityFrame(rarity)}
                        style={{ position: 'absolute', zIndex: '11' }}
                        alt="rarity-frame"
                      />
                    )}
                    {isVideo ? (
                      <video
                        autoPlay
                        controls={false}
                        loop
                        muted
                        src={getThumbnailImage(nft.image)}
                        alt="nft"
                        className="marketplace__wrapper__transactions-container__listed-items-container__item-nft"
                        style={{ pointerEvents: 'none', userSelect: 'none' }} // Prevent fullscreen and interaction
                        playsInline
                      />
                    ) : (
                      <img
                        src={getThumbnailImage(nft.image)}
                        alt="nft"
                        className="marketplace__wrapper__transactions-container__listed-items-container__item-nft"
                        style={{ pointerEvents: 'none', userSelect: 'none' }} // Prevent fullscreen and interaction
                      />
                    )}
                    {rarity && <p className="rarity-mobile">{rarity}</p>}
                  </div>
                  <div className="info">
                    <div className="marketplace__wrapper__transactions-container__listed-items-container__item-detail">
                      <h1>{nft.name.split(',')[0]}</h1>
                      {_class && (
                        <div
                          className="marketplace__wrapper__transactions-container__listed-items-container__item-detail-class"
                          style={{ backgroundColor: getNameBgColor(nft.name) }}
                        >
                          {_class}
                        </div>
                      )}
                      {rarity && <p className="rarity-desktop">{rarity}</p>}
                    </div>
                    <div className="users">
                      <div className="marketplace__wrapper__transactions-container__listed-items-container__item-seller">
                        <p>Seller</p>
                        <h2>
                          {nft.seller.substring(0, 4).concat('..') +
                            nft.seller.substring(nft.seller.length - 3)}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="marketplace__wrapper__transactions-container__listed-items-container__item-price">
                    <div>
                      <div className="marketplace__wrapper__transactions-container__listed-items-container__item-price-mcrt">
                        {nft.isMCRT ? (
                          <img src={mcrtLogo} alt="mcrt-logo" />
                        ) : (
                          <img src={bnbLogo} alt="bnb-logo" />
                        )}
                        <h2>
                          {getCurrentPrice(nft) === 0
                            ? null
                            : breakpoint.sm
                            ? numberWithCommas(getCurrentPrice(nft).toFixed(0))
                            : numberWithCommas(
                                nFormatter(getCurrentPrice(nft).toFixed(0), 1),
                              )}
                        </h2>
                      </div>
                      <p style={{ fontSize: 14, color: '#fff' }}>
                        $
                        {getCurrentPrice(nft) === 0
                          ? null
                          : nft.isMCRT
                          ? breakpoint.sm
                            ? numberWithCommas(
                                (getCurrentPrice(nft) * MCRTPrice).toFixed(2),
                              )
                            : numberWithCommas(
                                nFormatter(
                                  (getCurrentPrice(nft) * MCRTPrice).toFixed(2),
                                  1,
                                ),
                              )
                          : breakpoint.sm
                          ? numberWithCommas(
                              (getCurrentPrice(nft) * BNBPrice).toFixed(2),
                            )
                          : numberWithCommas(
                              nFormatter(
                                (getCurrentPrice(nft) * BNBPrice).toFixed(2),
                                1,
                              ),
                            )}
                      </p>
                    </div>
                    <p>
                      {timeDifference(
                        moment().valueOf(),
                        new Date(nft.createdAt).getTime(),
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <Nav.Link
            href="/marketplace/explorer"
            className="marketplace__wrapper__transactions-container__listed-items-container__see-more"
          >
            <p>View more on Marketplace</p>
            <BiChevronRight />
          </Nav.Link>
        </section>
        <section className="marketplace__wrapper__transactions-container">
          <h1 className="marketplace__wrapper__transactions-container__heading">
            Recently Sold
          </h1>
          <div className="marketplace__wrapper__transactions-container__buttons-container">
            <div
              className={`marketplace__wrapper__transactions-container__buttons-container__button ${
                listedType === 'characters' ? 'active' : ''
              }`}
              onClick={() => setListedType('characters')}
            >
              <img src={swordsIcon} alt="swords-icon" />
              <h2>Characters</h2>
            </div>
            {/* <div
              className={`marketplace__wrapper__transactions-container__buttons-container__button ${
                listedType === 'items' ? 'active' : ''
              }`}
            >
              <img src={chestIcon} alt="chest-icon" />
              <h2>Items</h2>
            </div>
            <div
              className={`marketplace__wrapper__transactions-container__buttons-container__button ${
                listedType === 'lands' ? 'active' : ''
              }`}
            >
              <img src={directionsIcon} alt="directions-icon" />
              <h2>Lands</h2>
            </div> */}
          </div>
          <div className="marketplace__wrapper__transactions-container__sold-items-container">
            {soldNfts.map((nft, index) => {
              const isVideo = nft.nftItem.image.includes('mp4');

              const rarity = returnRarity(nft.nftItem);

              const _class = returnClass(nft.nftItem);

              return (
                <div
                  className="marketplace__wrapper__transactions-container__sold-items-container__item"
                  key={index}
                  onClick={() => goToNftDetail(nft.tokenID)}
                >
                  <div className="marketplace__wrapper__transactions-container__sold-items-container__item-nft-container">
                    {rarity && (
                      <img
                        src={getRarityFrame(rarity)}
                        style={{ position: 'absolute', zIndex: '11' }}
                        alt="rarity-frame"
                      />
                    )}
                    {isVideo ? (
                      <video
                        autoPlay
                        controls={false}
                        muted
                        playsInline
                        loop
                        src={getThumbnailImage(nft.nftItem.image)}
                        alt="nft"
                        className="marketplace__wrapper__transactions-container__sold-items-container__item-nft"
                        style={{ pointerEvents: 'none', userSelect: 'none' }} // Prevent fullscreen and interaction
                      />
                    ) : (
                      <img
                        src={getThumbnailImage(nft.nftItem.image)}
                        alt="nft"
                        className="marketplace__wrapper__transactions-container__sold-items-container__item-nft"
                        style={{ pointerEvents: 'none', userSelect: 'none' }} // Prevent fullscreen and interaction
                      />
                    )}
                    {rarity && <p className="rarity-mobile">{rarity}</p>}
                  </div>
                  <div className="info">
                    <div className="marketplace__wrapper__transactions-container__sold-items-container__item-detail">
                      <h1>{nft.nftItem.name.split(',')[0]}</h1>
                      {_class && (
                        <div
                          className="marketplace__wrapper__transactions-container__sold-items-container__item-detail-class"
                          style={{
                            backgroundColor: getNameBgColor(nft.nftItem.name),
                          }}
                        >
                          {_class}
                        </div>
                      )}
                      {rarity && <p className="rarity-desktop">{rarity}</p>}
                    </div>
                    <div className="users">
                      <div className="marketplace__wrapper__transactions-container__sold-items-container__item-buyer">
                        <p>Buyer</p>
                        <h2>
                          {nft.buyer.substring(0, 4).concat('..') +
                            nft.buyer.substring(nft.buyer.length - 3)}
                        </h2>
                      </div>
                      <div className="marketplace__wrapper__transactions-container__sold-items-container__item-seller">
                        <p>Seller</p>
                        <h2>
                          {nft.seller.substring(0, 4).concat('..') +
                            nft.seller.substring(nft.seller.length - 3)}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="marketplace__wrapper__transactions-container__sold-items-container__item-price">
                    <div>
                      <div className="marketplace__wrapper__transactions-container__sold-items-container__item-price-mcrt">
                        {nft.isMCRT ? (
                          <img src={mcrtLogo} alt="mcrt-logo" />
                        ) : (
                          <img src={bnbLogo} alt="bnb-logo" />
                        )}
                        <h2>
                          {breakpoint.sm
                            ? numberWithCommas(
                                showAmount(nft.price, nft.isMCRT).toFixed(0),
                              )
                            : numberWithCommas(
                                nFormatter(
                                  showAmount(nft.price, nft.isMCRT).toFixed(0),
                                  1,
                                ),
                              )}
                        </h2>
                      </div>
                      <p style={{ fontSize: 14, color: '#fff' }}>
                        $
                        {nft.isMCRT
                          ? breakpoint.sm
                            ? numberWithCommas(
                                (
                                  showAmount(nft.price, nft.isMCRT) * MCRTPrice
                                ).toFixed(2),
                              )
                            : numberWithCommas(
                                nFormatter(
                                  (
                                    showAmount(nft.price, nft.isMCRT) *
                                    MCRTPrice
                                  ).toFixed(2),
                                  1,
                                ),
                              )
                          : breakpoint.sm
                          ? numberWithCommas(
                              (
                                showAmount(nft.price, nft.isMCRT) * BNBPrice
                              ).toFixed(2),
                            )
                          : numberWithCommas(
                              nFormatter(
                                (
                                  showAmount(nft.price, nft.isMCRT) * BNBPrice
                                ).toFixed(2),
                                1,
                              ),
                            )}
                      </p>
                    </div>
                    <p>{timeDifference(moment().valueOf(), nft.soldAt)}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <Nav.Link
            href="/marketplace/explorer"
            className="marketplace__wrapper__transactions-container__listed-items-container__see-more"
          >
            <p>View more on Marketplace</p>
            <BiChevronRight />
          </Nav.Link>
        </section>
      </div>
      {/* {!breakpoint.sm && (
        <div className="marketplace__banner--pages marketplace__banner--pages--mobile">
          <button onClick={onBtnMarketplace}>Inventory</button>
          <button onClick={onBtnMint}>Minting Page</button>
        </div>
      )} */}
      {/* <div className="marketplace__marketdata">
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
          <Tab eventKey="home" defaultChecked title="Last 24 Hours">
            <div className="marketplace__marketdata--marketInfo">
              <div className="marketplace__marketdata--marketInfo--infoItem">
                <img src={marketIcon} />
                <div className="marketplace__marketdata--marketInfo--infoItem--infoText">
                  <h5>Market Cap</h5>
                  <h2>Ξ 19.400</h2>
                </div>
              </div>
              <div className="marketplace__marketdata--marketInfo--infoItem">
                <img src={volumeIcon} />
                <div className="marketplace__marketdata--marketInfo--infoItem--infoText">
                  <h5>Total Volume</h5>
                  <h2>Ξ 3.521</h2>
                </div>
              </div>
              <div className="marketplace__marketdata--marketInfo--infoItem">
                <img src={userIcon} />
                <div className="marketplace__marketdata--marketInfo--infoItem--infoText">
                  <h5>Users</h5>
                  <h2>3,521</h2>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="profile" title="7 Days"></Tab>
          <Tab eventKey="contact" title="30 Days"></Tab>
        </Tabs>
      </div> */}
      {/* <div className="marketplace__itemList">
        {!breakpoint.sm && <h5>Recently Listed</h5>}

        <div className="marketplace__itemList--listed">
          {breakpoint.lg && <h5>Recently Listed</h5>}
          <Tabs defaultActiveKey="Characters" id="listed">
            <Tab eventKey="Characters" title="Characters">
              <NFTListedItem />
              <NFTListedItem />
              <NFTListedItem />
            </Tab>
            <Tab eventKey="Items" title="Items"></Tab>
            <Tab eventKey="Land" title="Land"></Tab>
          </Tabs>
          {breakpoint.lg && (
            <div className="marketplace__itemList--sold--details">
              View more on Marketplace
              <img src={arrowNext} />
            </div>
          )}
        </div>
        {!breakpoint.sm && (
          <div className="marketplace__itemList--sold--details">
            View more on Marketplace
            <img src={arrowNext} />
          </div>
        )}
        {!breakpoint.sm && <h5>Recently Sold</h5>}
        <div className="marketplace__itemList--sold">
          {breakpoint.lg && <h5>Recently Sold</h5>}
          <Tabs defaultActiveKey="Characters1" id="sold">
            <Tab eventKey="Characters1" title="Characters">
              <NFTSoldItem />
              <NFTSoldItem />
              <NFTSoldItem />
            </Tab>
            <Tab eventKey="Items1" title="Items"></Tab>
            <Tab eventKey="Land1" title="Land"></Tab>
          </Tabs>
          {breakpoint.lg && (
            <div className="marketplace__itemList--sold--details">
              View more on Marketplace
              <img src={arrowNext} />
            </div>
          )}
        </div>
        {!breakpoint.sm && (
          <div className="marketplace__itemList--sold--details">
            View more on Marketplace
            <img src={arrowNext} />
          </div>
        )}
      </div> */}
      {/* <div className="vstaking">NFTMarketplace</div> */}
    </div>
  );
};

export default NFTMarketplace;
