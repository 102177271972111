import Metamask from './icons/Metamask';
import MathWallet from './icons/MathWallet';
import TokenPocket from './icons/TokenPocket';
import WalletConnect from './icons/WalletConnect';
import CoinbaseWallet from './icons/CoinbaseWallet';
import BinanceChain from './icons/BinanceChain';
import SafePalWallet from './icons/SafePalWallet';
import Wombat from './icons/Wombat';
import { Config, ConnectorNames } from './types';

const connectors: Config[] = [
  {
    title: 'Injected/Browser Wallet',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'Binance Wallet',
    icon: BinanceChain,
    connectorId: ConnectorNames.BSC,
  },
  {
    title: 'Coinbase',
    icon: CoinbaseWallet,
    connectorId: ConnectorNames.WalletLink,
  },
  {
    title: 'Wallet Connect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'Wombat',
    icon: Wombat,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'Math Wallet',
    icon: MathWallet,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'Token Pocket',
    icon: TokenPocket,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'SafePal Wallet',
    icon: SafePalWallet,
    connectorId: ConnectorNames.Injected,
  },
];

export default connectors;
export const connectorLocalStorageKey = 'connectorId';
