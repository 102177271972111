import {
  RampInstantSDK,
  RampInstantEventTypes,
} from '@ramp-network/ramp-instant-sdk';

export default function showRamp() {
  const ramp = new RampInstantSDK({
    hostAppName: 'MagicCraft',
    hostLogoUrl: 'https://lobby.magiccraft.io/assets/logo-dab5f532.png',
    hostApiKey: 'oo73hsfyvd9hauquyvkt5b5pa4ud9ybojocsp3ca',
    // url: "https://app.demo.ramp.network",
  }).show();
}
