import './index.scss';
import { Row } from 'react-bootstrap';
import warningIcon from '../../assets/svg/warning.svg';

const GasFeeHelpContainer = ({
  openStakingHelpModal,
  content,
  position = 'left',
}: {
  openStakingHelpModal: () => void;
  content: string;
  position?: 'center' | 'left';
}) => {
  return (
    <div className={`help ${position === 'center' ? 'center' : ''}`}>
      <Row className="help-content" onClick={openStakingHelpModal}>
        <div className="icon-container">
          <img src={warningIcon} alt="Problem with pledging or unpledging?" />
        </div>
        {content ? (
          <p>{content}</p>
        ) : (
          <p>Problem with pledging or unpledging?</p>
        )}
      </Row>
    </div>
  );
};

export default GasFeeHelpContainer;
