import { RiFileCopyLine } from 'react-icons/ri';
import { HiChevronDown } from 'react-icons/hi';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import CopyModal from './CopyModal';
import { getNftContractAddress } from 'state/nftMarketplace';

import logo from 'assets/favicon.ico';
import {
  getTopHolders,
  getMcrtPrice,
  getTotalSupply,
} from '../../api/utils/index';
import './index.scss';

const E18 = BigNumber.from(10).pow(18);
const E9 = BigNumber.from(10).pow(9);

const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;

const LIMIT_MCRT = 15000000;

const contractAddress = getNftContractAddress();

const TopHundred = (props) => {
  const [knightsLimit, setKnightsLimit] = useState(10);
  const [knights, setKnights] = useState([]);
  const [MCRTPrice, setMCRTPrice] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [showCopyModal, setShowCopyModal] = useState(false);

  function copyToClipboard(text) {
    copy(text);
    setShowCopyModal(true);
  }

  useEffect(() => {
    setTimeout(() => setShowCopyModal(false), 2 * 1000);
  }, [showCopyModal]);

  function addZeros(value) {
    if (value.length === 1) {
      return '00' + value;
    } else if (value.length === 2) {
      return '0' + value;
    } else {
      return value;
    }
  }

  function addLimit() {
    if (knightsLimit < 100) {
      setKnightsLimit(knightsLimit + 10);
    }
  }

  function showAmount(amount) {
    const balance =
      BigNumber.from(amount.toString())
        .mul(100)
        .div(chainId === 56 ? E9 : E18)
        .toNumber() / 100;

    return balance;
  }

  function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  useEffect(() => {
    getTopHolders(contractAddress).then((response) => {
      if (Array.isArray(response)) {
        const tempArr = response.slice(0, knightsLimit);

        const filteredArr = tempArr.filter(
          (item) => showAmount(item.amount).toFixed(0) <= LIMIT_MCRT,
        );

        setKnights(filteredArr);
      }
    });
    getMcrtPrice().then((response) => {
      setMCRTPrice(response);
    });
    getTotalSupply().then((response) => {
      setMaxSupply(response);
    });
  }, [knightsLimit]);

  return (
    <div className="top-hundred">
      {showCopyModal ? <CopyModal /> : null}
      <div className="top-hundred__banner">
        <div className="top-hundred__text">
          <h1>Knights of the Realm</h1>
          <p>
          We're empowering our community by giving our top 100 MCRT holders 
          the right to vote on key decisions in our upcoming DAO system. 
          Together, we'll shape our collective future. Stay tuned for more updates!
          Community members who hold the most MCRT are entitled to fight for
          </p>
        </div>
      </div>
      <div className="top-hundred__list">
        {knights &&
          knights.map((item, i) => (
            <div className="top-hundred__list-knight">
              {/* <div className="top-hundred__list-knight-arrow up">
                <FaArrowUp style={{ marginRight: '4' }} />
                <p>2</p>
              </div> */}
              <div className="top-hundred__list-knight-rank">
                <p>#{addZeros(String(i + 1))}</p>
                <p className="label">Rank</p>
              </div>
              <div className="top-hundred__list-knight-wallet">
                <div className="top-hundred__list-knight-wallet-container">
                  <p>{item.account}</p>
                  <RiFileCopyLine
                    style={{ cursor: 'pointer', marginLeft: '10' }}
                    onClick={() => copyToClipboard(item.account)}
                  />
                </div>
                <p className="label">Wallet Address</p>
              </div>
              <div className="top-hundred__list-knight-amount">
                <p>
                  $
                  {numberWithCommas(
                    (showAmount(item.amount) * MCRTPrice).toFixed(0),
                  )}
                </p>
                <p className="label">Amount of $MCRT in USD</p>
              </div>
              <div className="top-hundred__list-knight-amount-MCRT">
                <div className="top-hundred__list-knight-amount-MCRT-container">
                  <img src={logo} alt="logo" />
                  <p>{numberWithCommas(showAmount(item.amount).toFixed(0))}</p>
                </div>
                <p className="label">Amount of $MCRT</p>
              </div>
              <div className="top-hundred__list-knight-percentage">
                <p>
                  {((showAmount(item.amount) / maxSupply) * 100).toFixed(2)}%
                </p>
                <p className="label">Percentage</p>
              </div>
            </div>
          ))}
      </div>
      {knightsLimit < 100 ? (
        <div className="load-next" onClick={addLimit}>
          <p>Load next 10 holders</p>
          <HiChevronDown />
        </div>
      ) : null}
    </div>
  );
};

export default TopHundred;
