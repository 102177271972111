import axios from 'axios';

export const getRecentlySoldNfts = async (contractAddress: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_MARKETPLACE_BACK_URL}/recent-sold?contractAddress=${contractAddress}`,
  );

  if (data && data.items.length === 0) return [];

  return data.items;
};

export const getMCRTData = async () => {
  const { data } = await axios.get(
    'https://api.coingecko.com/api/v3/coins/magiccraft',
  );

  return data;
};

export const getNFTAmountHolders = async (contractAddress: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_MARKETPLACE_BACK_URL}/nft-holder-count?contractAddress=${contractAddress}`,
  );

  return data;
};
