import React from 'react';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { ModalProvider } from 'widgets/Modal';
import store from 'state';
import { light } from 'theme';
import {
  walletConnectV2,
  hooks,
  coinbaseHooks,
  coinbaseWallet,
} from 'utils/web3React';
import { metaMask, metaMaskHooks } from 'utils/web3React';

// console.log(metaMask, metaMaskHooks);
const connectors = [
  [coinbaseWallet, coinbaseHooks],
  [metaMask, metaMaskHooks],
  [walletConnectV2, hooks],
];

const Providers = ({ children }) => {
  return (
    <Web3ReactProvider connectors={connectors}>
      <Provider store={store}>
        <SCThemeProvider theme={light}>
          <ModalProvider>{children}</ModalProvider>
        </SCThemeProvider>
      </Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
