import { darkColors, lightColors } from '../../theme/colors';
import { ModalTheme } from './types';

export const light: ModalTheme = {
  background: lightColors.card,
};

export const dark: ModalTheme = {
  background: darkColors.card,
};
