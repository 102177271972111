import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  State,
  MCRTState,
  NFTMintState,
  NFTMarketplaceState,
} from 'state/types';

export const useFetchPublicData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // staking
  }, [dispatch]);
};

export const useMcrtState = (): MCRTState => {
  const mcrtState = useSelector((state: State) => state.mcrt);
  return mcrtState;
};

export const useNFTMintState = (): NFTMintState => {
  const nftmintState = useSelector((state: State) => state.nftmint);
  return nftmintState;
};

export const useNFTMarketplaceState = (): NFTMarketplaceState => {
  const nftMarketplaceState = useSelector(
    (state: State) => state.nftMarketplace,
  );
  return nftMarketplaceState;
};
