import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useAuth from 'hooks/useAuth';
import useWalletModal from 'widgets/WalletModal/useWalletModal';
import { connectorLocalStorageKey } from 'widgets/WalletModal';

import { ReactComponent as MenuArrow } from 'assets/menu-arrow-down.svg';

const StyledConnectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;

  svg {
    margin-left: 1rem;
  }
`;

const CircleBadge = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #7390dc;
  margin-right: 5px;
`;

const Disconnect = styled.div`
  position: absolute;
  top: calc(100% - 0.65rem);
  width: 100%;
  padding: 1.5rem 0.75rem;
  border-radius: 8px;

  background: #232449;

  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: left;

  color: rgba(255, 255, 255, 0.7);
`;

const ConnectWallet = (props: any) => {
  const { login, logout } = useAuth();
  const { account } = useWeb3React();
  const { onPresentConnectModal } = useWalletModal(login, logout);
  const navigate = useNavigate();

  const [dropOpened, setDropOpened] = useState(false);

  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
    : null;

  const handleDisconnect = () => {
    const connectorId = window.localStorage.setItem(
      connectorLocalStorageKey,
      '',
    );
    logout();
  };

  return (
    <>
      <Button
        className="btn1 m-auto"
        onClick={() => {
          if (account) {
            setDropOpened(!dropOpened);
          } else {
            onPresentConnectModal();
          }
        }}
        {...props}
      >
        {account ? (
          <StyledConnectButton>
            <div>{accountEllipsis}</div>
            <MenuArrow
              style={{
                transform: dropOpened ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform ease-out 0.2s',
              }}
            />

            {dropOpened && (
              <Disconnect onClick={handleDisconnect}>Disconnect</Disconnect>
            )}
          </StyledConnectButton>
        ) : (
          <>
            <StyledConnectButton>
              <CircleBadge />
              <div>{'Connect Wallet'}</div>
            </StyledConnectButton>
          </>
        )}
      </Button>
    </>
  );
};

export { ConnectWallet };
