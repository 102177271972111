import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import warningIcon from '../../assets/svg/warning.svg';

import clock from 'assets/Clock.png';
import logo from 'assets/favicon.ico';
//Images
import graph from 'assets/Group2.png';
import withdrawClaim from 'assets/Group3.png';
// Contracts
import stakingContractJson from 'constants/ABI/MCRTStake.json';
import pointContractJson from 'constants/ABI/points.json';
import { useApprove } from 'hooks/useApprove';
// Hooks
import {
  useMCRT,
  useNFTRedeemContract,
  usePointContract,
  useStakeContract,
} from 'hooks/useContract';
import { fetchUserMCRTBalanceAsync } from 'state/action';
import { useMcrtState } from 'state/hooks';
import multicall from 'utils/multicall';

// tables
import MCRTRewardTable from './components/MCRTRewardTable/index';
import NFTRewardTable from './components/NFTRewardTable/index';

// styles
import './index.scss';

import { RoiCalculatorModal } from './components/RoiCalculatorModal';
import StakingHelpModal from './components/StakingHelpModal/StakingHelpModal';
import { getNFTRedeemAddress } from 'utils/addressHelpers';
import GasFeeHelpContainer from 'components/GasFeeHelpContainer';
import HelpModal from 'components/HelpModal/HelpModal';

// const genesisNftArticleLink = 'https://medium.com/@MagicCraftGame/everything-you-need-to-know-about-the-mcrt-genesis-nft-collection-23976dfdf59d';
const genesisNftArticleLink = 'https://marketplace.magiccraft.io/';
const timelocks = [
  // 3600,
  30 * 86400,
  90 * 86400,
  180 * 86400,
  365 * 86400,
  365 * 3 * 86400,
  365 * 5 * 86400,
];

const nftRewards = [null, null, 'Item', 'Character', 'Land', 'Land x 2'];
const nftRewardsTexts = ['Item', 'Character', 'Land'];

const E18 = BigNumber.from(10).pow(18);
const E16 = BigNumber.from(10).pow(16);
const E14 = BigNumber.from(10).pow(14);
const E9 = BigNumber.from(10).pow(9);
const E7 = BigNumber.from(10).pow(7);
const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;

const VStaking = (props) => {
  const { account } = useWeb3React();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  const [isRoiCalculatorModalOpened, setIsRoiCalculatorModalOpened] =
    useState(false);

  const [isStakingHelpModalOpen, setIsStakingHelpModalOpen] = useState(false);

  const [rewardRate, setRewardRate] = useState(0);
  const [stakingNonce, setStakingNonce] = useState(0);
  const [bonusPercents, setBonusPercents] = useState([]);
  const [minStakeForTokens, setMinStakeForTokens] = useState([]);
  const [stakeInfo, setStakeInfo] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [pointRewards, setPointRewards] = useState([]);
  const [totalEarned, setTotalEarned] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalTokensStaked, setTotalTokensStaked] = useState();
  const [
    totalTokensStakedWithBonusTokens,
    setTotalTokensStakedWithBonusTokens,
  ] = useState();
  const [rewardOption, setRewardOption] = useState(false);
  const [apy, setAPY] = useState(0);
  const [tvl, setTVL] = useState(0);
  const [nftPoint, setNftPoint] = useState([]);
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [visibleOptionDropdown, setVisibleOptionDropdown] = useState(false);

  const [dropdownValue, setDropdownValue] = useState(0);
  const [dropdownOptionValue, setDropdownOptionValue] = useState(0);
  const dispatch = useDispatch();
  const [isStaking, setIsStaking] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [redeemAmt, setRedeemAmt] = useState(0);
  const [amount, setAmount] = useState(0);
  const [stakingTime, setStakingTime] = useState('');

  const stakeContract = useStakeContract();
  const pointContract = usePointContract();
  const mcrtTokenContract = useMCRT();
  const redeemContract = useNFTRedeemContract();
  const { balance } = useMcrtState();

  const node = useRef();

  const { onApprove } = useApprove(mcrtTokenContract);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  // Disable scroll when  Staking Help Modal is open

  //@ts-ignore
  useEffect(() => {
    let interval;

    if (account) {
      getInitData();
      getRedeemAmt();
      updateRewards();
      updatePoint();

      interval = setInterval(async () => {
        await updateRewards();
        await updatePoint();
        await getRedeemAmt();
      }, 30000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [account]);

  useEffect(() => {
    if (rewardOption && minStakeForTokens.length != 0) {
      setAmount(
        minStakeForTokens[
          timelocks.findIndex(
            (it) => it === dropdownList[dropdownValue].duration / 1000,
          )
        ]
          .div(chainId === 56 ? E9 : E18)
          .toNumber(),
      );
    }

    const interval = setInterval(() => {
      const today = moment
        .utc(new Date().getTime() + dropdownList[dropdownValue].duration)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
      setStakingTime(today);
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [dropdownValue, minStakeForTokens, timelocks, rewardOption]);

  useEffect(() => {
    if (rewardOption) {
      setDropdownValue(2);
    } else {
      setDropdownValue(0);
      setAmount(0);
    }
  }, [rewardOption]);

  useEffect(() => {
    if (rewardRate && totalTokensStakedWithBonusTokens) {
      if (BigNumber.from(totalTokensStakedWithBonusTokens).eq(0)) setAPY(10000);
      else {
        const _apy = BigNumber.from(rewardRate)
          .mul(1000)
          .mul(3600 * 24 * 365 * 100)
          .div(totalTokensStakedWithBonusTokens);
        setAPY(_apy.toNumber() / 1000);
      }
    }
  }, [rewardRate, totalTokensStakedWithBonusTokens]);

  useEffect(() => {
    if (!stakeContract) return;

    getInfo();
  }, [stakeContract]);

  const getInfo = async () => {
    let arr1 = new Array(timelocks.length);
    let arr2 = new Array(timelocks.length);
    let arr4 = new Array(timelocks.length);

    arr1 = await multicall(
      stakingContractJson.abi,
      new Array(timelocks.length).fill(0).map((_, id) => {
        return {
          address: stakeContract.options.address,
          name: 'minStakeTokensForPoint',
          params: [timelocks[id]],
        };
      }),
    );

    arr2 = await multicall(
      stakingContractJson.abi,
      new Array(timelocks.length).fill(0).map((_, id) => {
        return {
          address: stakeContract.options.address,
          name: 'bonusTokenMultiplier',
          params: [timelocks[id]],
        };
      }),
    );

    if (arr1.length != 0) {
      arr1 = arr1.map((it) => BigNumber.from(it[0]));
    }
    if (arr2.length != 0) {
      arr2 = arr2.map((it) => BigNumber.from(it[0]).div(E9).toNumber() - 100);
    }

    for (let i = 0; i < timelocks.length; i++) {
      arr4[i] = await multicall(
        stakingContractJson.abi,
        new Array(3).fill(0).map((_, id) => {
          return {
            address: stakeContract.options.address,
            name: 'pointRewards',
            params: [timelocks[i], id],
          };
        }),
      );
    }
    if (arr4.length != 0) {
      arr4 = arr4.map((it) => [it[0][0], it[1][0], it[2][0]]);
    }

    const ctTotalStaked = await mcrtTokenContract.methods
      .balanceOf(stakeContract.options.address)
      .call();
    const totalRewardsLeft = await stakeContract.methods
      .totalRewardsLeft()
      .call();

    setTVL(
      BigNumber.from(ctTotalStaked)
        .sub(BigNumber.from(totalRewardsLeft))
        .div(chainId === 56 ? E9 : E18)
        .toNumber(),
    );

    setMinStakeForTokens([...arr1]);
    setBonusPercents([...arr2]);
    setPointRewards([...arr4]);
    setRewardRate(
      BigNumber.from(await stakeContract.methods.rewardRate().call()),
    );
    setTotalTokensStaked(
      BigNumber.from(await stakeContract.methods.totalTokensStaked().call()),
    );
    setTotalTokensStakedWithBonusTokens(
      BigNumber.from(
        await stakeContract.methods.totalTokensStakedWithBonusTokens().call(),
      ),
    );
  };

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setVisibleDropdown(false);
  };

  const updatePoint = async () => {
    const arr4 = await multicall(pointContractJson.abi, [
      {
        address: pointContract.options.address,
        name: 'pointsOf',
        params: [account],
      },
    ]);
    setNftPoint([...arr4]);
  };
  const updateRewards = async () => {
    let sum = 0;

    const nonce = Number(
      await stakeContract.methods.stakingNonce(account).call(),
    );

    let arr = new Array(nonce);

    arr = await multicall(
      stakingContractJson.abi,
      new Array(nonce).fill(0).map((_, id) => {
        return {
          address: stakeContract.options.address,
          name: 'earned',
          params: [account, [id]],
        };
      }),
    );

    if (arr.length != 0) {
      arr = arr.map((it) => {
        sum += BigNumber.from(it[0])
          .div(chainId === 56 ? E7 : E16)
          .toNumber();
        return (
          Number(
            BigNumber.from(it[0])
              .div(chainId === 56 ? E7 : E16)
              .toNumber(),
          ) / 100.0
        );
      });
    }

    setRewards([...arr]);
    setTotalEarned(sum);
  };

  const getRedeemAmt = async () => {
    const pointBal = await pointContract.methods.pointsOf(account).call();
    setRedeemAmt(Number(pointBal[1]));
  };

  const getInitData = async () => {
    // Get stakingNonce
    // Get staking information

    const tempNonce = Number(
      await stakeContract.methods.stakingNonce(account).call(),
    );
    let arr1 = new Array(tempNonce);
    let sum = BigNumber.from(0);
    arr1 = await multicall(
      stakingContractJson.abi,
      new Array(tempNonce).fill(0).map((_, id) => {
        return {
          address: stakeContract.options.address,
          name: 'stakingInfoForAddress',
          params: [account, id],
        };
      }),
    );

    if (arr1.length != 0) {
      arr1 = arr1.map((it) => {
        sum = BigNumber.from(sum).add(
          BigNumber.from(it.tokensStaked).div(chainId === 56 ? E9 : E18),
        );

        return {
          id: it.id,
          option: it.option,
          owner: it.owner,
          stakingTime: it.stakingTime,
          timeToUnlock: it.timeToUnlock,
          tokensStaked: it.tokensStaked,
          tokensStakedWithBonus: it.tokensStakedWithBonus,
          withdrawnPoint: it.withdrawnPoint,
        };
      });
    }

    setStakingNonce(tempNonce);
    setStakeInfo([...arr1]);
    setTotalStaked(sum.toNumber());
  };

  const increaseStaking = async () => {
    setIsStaking(true);
    const allowance = await mcrtTokenContract.methods
      .allowance(account, stakeContract.options.address)
      .call();
    const balance = await mcrtTokenContract.methods.balanceOf(account).call();

    if (amount === 0) {
      toast.error('Cannot stake zero amount');
      setIsStaking(false);
      return;
    }

    // Approve allowance
    if (
      BigNumber.from(amount * 1000000000)
        .div(1000000000)
        .gt(BigNumber.from(allowance).div(E18))
    ) {
      try {
        await onApprove(mcrtTokenContract);
        toast.success(
          'Approve is Success!, You can stake the token since now!',
        );
      } catch (err) {
        toast.error('Approve has been failed!');
      }
    }

    // Check wallet MCRT token balance
    if (
      BigNumber.from(amount * 1000000000)
        .div(1000000000)
        .gt(BigNumber.from(balance).div(chainId === 56 ? E9 : E18))
    ) {
      toast.error('Not enough MCRT token in your wallet!');
    }

    stakeContract.methods
      .stake(
        BigNumber.from(amount * 1000000000)
          .mul(chainId === 56 ? E9 : E18)
          .div(1000000000)
          .toString(),
        dropdownList[dropdownValue].duration / 1000,
        rewardOption,
      )
      .send({ from: account })
      .on('receipt', (tx) => {
        setIsStaking(false);
        dispatch(fetchUserMCRTBalanceAsync(account));
        getInitData();
        toast.success('Staking tokens has been succeed!');
      })
      .on('error', () => {
        setIsStaking(false);
        toast.error('Staking tokens has been failed!');
      });
  };

  const redeem = async () => {
    if (redeemAmt === 0) return;

    setIsRedeeming(true);
    pointContract.methods
      .approvePoints(getNFTRedeemAddress(), 0, redeemAmt, 0)
      .send({ from: account })
      .on('receipt', (tx) => {
        redeemContract.methods
          .redeem(redeemAmt)
          .send({ from: account })
          .on('receipt', () => {
            setIsRedeeming(false);
            getRedeemAmt();
            toast.success('Redeeming NFTs has been succeed!');
          })
          .on('error', () => {
            setIsClaiming(false);
            toast.error('Redeeming NFTs has been failed!');
          });
      })
      .on('error', () => {
        setIsClaiming(false);
        toast.error('Redeeming NFTs has been failed!');
      });
  };

  const withdraw = async () => {
    let rewardIds = [];
    let pRewardIds = [];
    let nRewardIds = [];
    const today = new Date().getTime();

    setIsClaiming(true);

    // here
    if (stakeInfo.length === 0) {
      setIsClaiming(false);
      return;
    }

    for (let i = 0; i < stakeInfo.length; i++) {
      if (
        (!stakeInfo[i].option ||
          (stakeInfo[i].option && stakeInfo[i].withdrawnPoint)) &&
        today >= Number(stakeInfo[i].timeToUnlock) * 1000 &&
        stakeInfo[i].owner !== ZERO_ADDRESS
      ) {
        if (stakeInfo[i].option) nRewardIds.push(i);
        else rewardIds.push(i);
      } else if (
        stakeInfo[i].option &&
        !stakeInfo[i].withdrawnPoint &&
        stakeInfo[i].owner != ZERO_ADDRESS
      ) {
        pRewardIds.push(i);
      }
    }

    // console.log('rewardIds', rewardIds);
    // console.log('nRewardIds', nRewardIds);
    // console.log('pRewardIds', pRewardIds);

    // here
    if (
      rewardIds.length === 0 &&
      nRewardIds.length === 0 &&
      pRewardIds.length === 0
    ) {
      setIsClaiming(false);
      toast.error('No staking is available to get rewards!');
      return;
    }
    if (rewardIds.length != 0 && !rewardOption) {
      stakeContract.methods
        .batchUnstake(rewardIds)
        .send({ from: account })
        .on('receipt', (tx) => {
          setIsClaiming(false);
          toast.success('UnStaking tokens has been succeed!');

          getInitData();

          dispatch(fetchUserMCRTBalanceAsync(account));
        })
        .on('error', () => {
          setIsClaiming(false);
          toast.error('UnStaking tokens has been failed!');
        });
    }

    if (pRewardIds.length != 0 && rewardOption) {
      stakeContract.methods
        .batchGetPointReward(pRewardIds)
        .send({ from: account })
        .on('receipt', (tx) => {
          setIsClaiming(false);
          toast.success('You received NFT rewards successfully!');

          getInitData();
          getRedeemAmt();
        })
        .on('error', () => {
          setIsClaiming(false);
          toast.error('Getting NFT rewards has been failed!');
        });
    }

    if (nRewardIds.length != 0 && rewardOption) {
      stakeContract.methods
        .batchUnstake(nRewardIds)
        .send({ from: account })
        .on('receipt', (tx) => {
          setIsClaiming(false);
          toast.success('UnStaking tokens has been succeed!');

          getInitData();

          dispatch(fetchUserMCRTBalanceAsync(account));
        })
        .on('error', () => {
          setIsClaiming(false);
          toast.error('UnStaking tokens has been failed!');
        });
    }
  };
  const onMaxBtnClick = async () => {
    dispatch(fetchUserMCRTBalanceAsync(account));

    const _balance = BigNumber.from(balance.toString())
      .mul(1000000000)
      .div(chainId === 56 ? E9 : E18)
      .toNumber();

    setAmount(_balance / 1000000000);
  };

  const onToggleJoinLotteryModal = () => {
    setIsRoiCalculatorModalOpened(!isRoiCalculatorModalOpened);
  };

  const renderStakeAmount = () => {
    return (
      <div className="vstaking__stake__action-container">
        <input
          disabled={rewardOption}
          type="text"
          className="vstaking__stake__action-input"
          value={amount}
          onInput={(e) => setAmount(e.target.value)}
          min={0}
          style={{ paddingRight: '4rem' }}
        />
        {!rewardOption && (
          <button
            className="vstaking__stake__action-button"
            onClick={onMaxBtnClick}
          >
            Max
          </button>
        )}
      </div>
    );
  };

  const openStakingHelpModal = () => {
    setIsStakingHelpModalOpen(true);
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };

  const closeStakingHelpModal = () => {
    setIsStakingHelpModalOpen(false);
    document.body.style.overflowY = 'unset';
    document.body.style.overflowX = 'hidden';
  };

  const renderNFTRewardTable = () => {
    let addedCol = [];

    if (!account || stakeInfo.length === 0) {
      return (
        <table>
          <tr>
            <th>Duration</th>
            <th>NFT</th>
            <th>MCRT Required</th>
          </tr>
          <tbody>
            {timelocks.map((tl, idx) => {
              const tIndex = timelocks.findIndex((it) => it === tl);
              if (!dropdownList[tIndex].isForNft) return null;
              return (
                <tr>
                  {tl < 3600 * 24 ? (
                    <td>{tl / 3600} hours</td>
                  ) : (
                    <td>
                      {tl / 3600 / 24 >= 365
                        ? `${tl / 3600 / 24 / 365} years`
                        : `${tl / 3600 / 24} days`}
                    </td>
                  )}
                  <td>{nftRewards[tIndex] || 'None'}</td>
                  <td>{`${BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                    chainId === 56 ? E9 : E18,
                  )}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    return (
      <table>
        <tr>
          <th>Duration</th>
          <th>NFT</th>
          <th>MCRT Required</th>
          <th>Point</th>
          <th>Earned NFT</th>
        </tr>

        <tbody>
          {new Array(stakingNonce).fill(0).map((_, idx) => {
            if (
              !stakeInfo[idx] ||
              !stakeInfo[idx].option ||
              stakeInfo[idx].owner === ZERO_ADDRESS
            )
              return;

            const duration =
              Number(stakeInfo[idx].timeToUnlock) -
              Number(stakeInfo[idx].stakingTime);

            const tIndex = timelocks.findIndex((it) => it === duration);

            let pRewards = 0;
            if (pointRewards.length != 0) {
              pRewards = stakeInfo[idx].withdrawnPoint
                ? 0
                : pointRewards[tIndex][
                    nftRewardsTexts.findIndex((it) => it === nftRewards[tIndex])
                  ];
            }

            addedCol.push(duration);

            return (
              <tr>
                {duration < 3600 * 24 ? (
                  <td>{duration / 3600} hours</td>
                ) : (
                  <td>
                    {duration / 3600 / 24 >= 365
                      ? `${duration / 3600 / 24 / 365} years`
                      : `${duration / 3600 / 24} days`}
                  </td>
                )}
                <td>{nftRewards[tIndex]}</td>
                <td>{`${
                  minStakeForTokens
                    ? BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                        chainId === 56 ? E9 : E18,
                      )
                    : 0
                }`}</td>
                {/* <td>00</td> */}
                <td>{BigNumber.from(pRewards).toNumber()}</td>
              </tr>
            );
          })}
        </tbody>

        {timelocks
          .filter((it) => !addedCol.includes(it))
          .map((duration) => {
            const tIndex = timelocks.findIndex((it) => it === duration);

            if (!nftRewards[tIndex]) return;

            return (
              <tr>
                {duration < 3600 * 24 ? (
                  <td>{duration / 3600} hours</td>
                ) : (
                  <td>
                    {duration / 3600 / 24 >= 365
                      ? `${duration / 3600 / 24 / 365} years`
                      : `${duration / 3600 / 24} days`}
                  </td>
                )}
                <td>{nftRewards[tIndex]}</td>
                <td>{`${
                  minStakeForTokens
                    ? BigNumber.from(minStakeForTokens[tIndex] || 0).div(
                        chainId === 56 ? E9 : E18,
                      )
                    : 0
                }`}</td>
                <td>00</td>
                <td>0</td>
              </tr>
            );
          })}
      </table>
    );
  };

  const renderStake = () => {
    return (
      <button
        disabled={account ? false : true}
        className="vstaking__stake__action__button"
        onClick={() => increaseStaking()}
      >
        {isStaking ? (
          <div className="loading_btn_style">
            <span>
              <i className="fa fa-spinner fa-spin"></i> Confirming
            </span>
          </div>
        ) : (
          <div className="btn_style">
            <img src={graph} /> <span> Pledge</span>
          </div>
        )}
      </button>
    );
  };

  const renderUnStake = () => {
    return (
      <button
        disabled={account ? false : true}
        className="vstaking__stake__action__button"
        onClick={() => withdraw()}
      >
        {isClaiming ? (
          <div className="loading_btn_style">
            <span>
              <i className="fa fa-spinner fa-spin"></i> Confirming
            </span>
          </div>
        ) : (
          <div className="btn_style">
            <img src={withdrawClaim} />{' '}
            <span>
              {rewardOption ? 'Claim NFT Rewards' : 'Claim Token Rewards'}
            </span>
          </div>
        )}
      </button>
    );
  };

  const renderRedeem = () => {
    return (
      <button
        disabled={account && redeemAmt > 0 ? false : true}
        className="vstaking__stake__action__button"
        onClick={() => redeem()}
      >
        {isRedeeming ? (
          <div className="loading_btn_style">
            <span>
              <i className="fa fa-spinner fa-spin"></i> Redeeming
            </span>
          </div>
        ) : (
          <div className="btn_style">
            <span>Redeem NFT</span>
          </div>
        )}
      </button>
    );
  };

  const dropdownList = [
    // { text: "Pledge (lock) for 1 hour", duration: 3600 * 1000, isForNft: true },
    {
      text: 'Pledge (lock) for 30 days',
      duration: 30 * 3600 * 24 * 1000,
      isForNft: false,
    },
    {
      text: 'Pledge (lock) for 90 days',
      duration: 90 * 3600 * 24 * 1000,
      isForNft: false,
    },
    {
      text: 'Pledge (lock) for 180 days',
      duration: 180 * 3600 * 24 * 1000,
      isForNft: true,
    },
    {
      text: 'Pledge (lock) for 1 year',
      duration: 365 * 3600 * 24 * 1000,
      isForNft: true,
    },
    {
      text: 'Pledge (lock) for 3 years',
      duration: 3 * 365 * 3600 * 24 * 1000,
      isForNft: true,
    },
    {
      text: 'Pledge (lock) for 5 years',
      duration: 5 * 365 * 3600 * 24 * 1000,
      isForNft: true,
    },
  ];

  return (
    <div>
      {isRoiCalculatorModalOpened && (
        <RoiCalculatorModal
          modalIsOpen={isRoiCalculatorModalOpened}
          apy={apy}
          bonusPercents={bonusPercents}
          closeModal={onToggleJoinLotteryModal}
        />
      )}

      {isStakingHelpModalOpen && (
        <HelpModal
          isModalOpen={isStakingHelpModalOpen}
          closeModal={closeStakingHelpModal}
        />
      )}

      <div className="vstaking">
        <Container className="vstaking__stake">
          <Row className="vstaking__stake-container">
            <Col sm={6}>
              <Row>
                <div className="vstaking__stake-titles vstaking__stake-titles--left">
                  <h1>MCRT Pledged </h1>
                  <h2>TVL: {tvl.toLocaleString()} MCRT</h2>
                </div>
                <div className="vstaking__stake-reward-type-container vstaking__stake-reward-type-container--desktop">
                  <h4>Choose Your Pledging Rewards:</h4>
                  <Row>
                    <Col sm={6} className="vstaking__option">
                      <div className="vstaking__option-wrapper">
                        <button
                          className={`${rewardOption ? '' : 'active'}`}
                          onClick={() => setRewardOption(false)}
                        >
                          MCRT Token
                        </button>
                      </div>
                    </Col>
                    <Col sm={6} className="vstaking__option">
                      <div className="vstaking__option-wrapper">
                        <button
                          className={`${rewardOption ? 'active' : ''}`}
                          onClick={() => setRewardOption(true)}
                        >
                          NFT
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>

              <h4 className="mt-3">Pledged MCRT</h4>
              <div className="vstaking__stake__action-staked">
                <img src={logo} alt="" />
                <span>{totalStaked.toLocaleString()}</span>
              </div>

              <h4 className="mt-4">Token Amount</h4>
              <div className="border_gradiant">{renderStakeAmount()}</div>

              <h4 className="mt-4">Pledging Period</h4>
              <div
                style={{ position: 'relative' }}
                className="border_gradiant"
                ref={node}
              >
                <div
                  className={`vstaking__stake__action-select ${
                    visibleDropdown ? 'active' : ''
                  }`}
                  onClick={() => setVisibleDropdown(!visibleDropdown)}
                >
                  <span>{dropdownList[dropdownValue].text}</span>
                  <svg
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    focusable="false"
                    className="css-8mmkcg"
                    fill="#fff"
                  >
                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                  </svg>
                </div>
                {visibleDropdown && (
                  <ul className="vstaking__stake__action-option">
                    {dropdownList.map((it, idx) => {
                      return (
                        <li
                          key={idx}
                          className={`vstaking__stake__action-option-list ${
                            rewardOption && !it.isForNft
                              ? 'vstaking__stake__action-option-list--hidden'
                              : ''
                          }`}
                          onClick={() => {
                            setDropdownValue(idx);
                            setVisibleDropdown(false);
                          }}
                        >
                          {it.text}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>

              <p className="vstaking__stake__action__hint-till">
                <strong>Pledge until</strong>
                <img src={clock} alt="clock" />
                <strong>{stakingTime}</strong>
              </p>

              {renderStake()}
              {renderUnStake()}
              {rewardOption && renderRedeem()}

              {/* <div className="vstaking__pledging-help">
                <Row
                  className="vstaking__pledging-help-content"
                  onClick={openStakingHelpModal}
                >
                  <div className="icon-container">
                    <img
                      src={warningIcon}
                      alt="Problem with pledging or unpledging?"
                    />
                  </div>
                  <span>Problem with pledging or unpledging?</span>
                </Row>
              </div> */}

              <GasFeeHelpContainer
                openStakingHelpModal={openStakingHelpModal}
              />

              <Container className="vstaking__help">
                {!rewardOption ? (
                  <>
                    <p>
                      To proceed with using our pledging services, please review
                      and accept our terms and conditions. By clicking "Pledge",
                      you confirm that you have read, understood, and agreed to
                      the terms and conditions governing the use of our pledging
                      services, and that you acknowledge the risks associated
                      with pledging MCRT tokens. Your continued use of our
                      pledging services constitutes your ongoing acceptance of
                      our terms and conditions.
                    </p>
                  </>
                ) : (
                  <>
                    <div>
                      {`- You will get NFT points right away after staking. NFT points can be exchanged for actual NFTs once they are released.`}
                    </div>
                    <div>
                      <span>{`- NFTs available as staking rewards are part of `}</span>
                      <span>
                        <a
                          href={genesisNftArticleLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`Genesis Collection`}
                        </a>
                      </span>
                      <span>{`, the amount is limited`}</span>
                    </div>
                  </>
                )}
              </Container>
            </Col>

            <Col sm={6} className="vstaking__stake__info">
              <div className="vstaking__stake-titles vstaking__stake-titles--right">
                <h1>MCRT Pledged </h1>
                <h2>TVL: {tvl.toLocaleString()} MCRT</h2>
              </div>
              <div className="vstaking__stake__info-back">
                <h1>{Number(apy).toFixed(2)}%</h1>
              </div>
              <div className="vstaking__stake-reward-type-container vstaking__stake-reward-type-container--mobile">
                <h4>Choose Your Pledging Rewards:</h4>
                <Row>
                  <Col xs={6} className="vstaking__option">
                    <div className="vstaking__option-wrapper">
                      <button
                        className={`${rewardOption ? '' : 'active'}`}
                        onClick={() => setRewardOption(false)}
                      >
                        MCRT Token
                      </button>
                    </div>
                  </Col>
                  <Col xs={6} className="vstaking__option">
                    <div className="vstaking__option-wrapper">
                      <button
                        className={`${rewardOption ? 'active' : ''}`}
                        onClick={() => setRewardOption(true)}
                      >
                        NFT
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="vstaking__stake__reward-table--title">
                <h4>{rewardOption ? 'NFT Reward' : 'MCRT Reward'}</h4>
                <span>
                  {!rewardOption && (
                    <div
                      className="vstaking__stake__reward-table--calculator"
                      onClick={onToggleJoinLotteryModal}
                    >
                      ROI Calculator
                    </div>
                  )}
                </span>
              </div>
              <div className="vstaking__stake__reward">
                {rewardOption && (
                  <div className="vstaking__stake__reward-table-wrapper--token">
                    {/* {renderNFTRewardTable()} */}
                    <NFTRewardTable
                      stakeInfo={stakeInfo}
                      stakingNonce={stakingNonce}
                      rewards={rewards}
                      nftPoint={nftPoint}
                      minStakeForTokens={minStakeForTokens}
                      pointRewards={pointRewards}
                    />
                  </div>
                )}
                {!rewardOption && (
                  <div className="vstaking__stake__reward-table-wrapper--nft">
                    <MCRTRewardTable
                      stakeInfo={stakeInfo}
                      stakingNonce={stakingNonce}
                      rewards={rewards}
                      bonusPercents={bonusPercents}
                    />
                  </div>
                )}
              </div>
              <br />
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default VStaking;
