import axios from 'axios';

export const getTopHolders = async (contractAddress: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_MARKETPLACE_BACK_URL}/top-holders?contractAddress=${contractAddress}`,
  );

  if (data && data.items.length === 0) return [];

  return data.items;
};

export const getMcrtPrice = async () => {
  const { data } = await axios.get(
    'https://min-api.cryptocompare.com/data/price?fsym=MCRT&tsyms=USD',
  );

  return data.USD;
};

export const getTotalSupply = async () => {
  return '10000000000';
};
