import './index.scss';
import { createPortal } from 'react-dom';
import { FaXmark } from 'react-icons/fa6';
import { FiDownload, FiInfo } from 'react-icons/fi';

import stakingHelpImg from '../../../../assets/images/staking/staking-help.png';

interface StakingHelpModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

const StakingHelpModal = (props: StakingHelpModalProps) => {
  if (!props.isModalOpen) {
    return null;
  }

  return createPortal(
    <div className="staking-help-modal__container" onClick={props.closeModal}>
      <div className="staking-help-modal" onClick={(e) => e.stopPropagation()}>
        <div className="close-icon" onClick={props.closeModal}>
          <FaXmark />
        </div>
        <div className="staking-help-modal__content">
          <div className="staking-help-modal__header">
            <div className="staking-help-modal__header--title">
              <h2>Error while trying to pledge or unpledge MCRT tokens?</h2>
              <p>
                The solution requires you to switch to a different RPC for BSC.
              </p>
            </div>
            <a
              href="/assets/pdf/magiccraft-pledging-help.pdf"
              download={'magiccraft_pledging_help'}
              className="staking-help-modal__download"
            >
              <span>Save as PDF</span>
              <FiDownload fontSize={18} />
            </a>
          </div>
          <div className="staking-help-modal__steps">
            <ol>
              <li>
                <h5>Step 1</h5>
                <p>Open your Metamask in your browser.</p>
              </li>
              <li>
                <h5>Step 2</h5>
                <p>Click on the three dots in the top right corner.</p>
              </li>
              <li>
                <h5>Step 3</h5>
                <p>
                  Go to Settings &rarr; Networks &rarr; Click on Smart Chain.
                </p>
              </li>
              <li>
                <h5>Step 4</h5>
                <p>A page opens in your browser.</p>
              </li>
              <li>
                <img src={stakingHelpImg} alt="" />
              </li>
              <li>
                <div className="change-url">
                  <p>
                    Here you can change the RPC URL. <br /> For example you can
                    try to change the current one to:{' '}
                    <a
                      href="https://bsc.publicnode.com/"
                      target="_blank"
                      rel="nooppener noreferrer"
                    >
                      https://bsc.publicnode.com
                    </a>
                  </p>
                </div>
                <div className="staking-badge">
                  <div className="staking-badge__icon">
                    <FiInfo color="#98fff9" />
                  </div>
                  <p>
                    Or if you want another one feel free to pick one from this
                    page, whichever works for you:{' '}
                    <a
                      href="https://chainlist.org/chain/56"
                      target="_blank"
                      rel="nooppener noreferrer"
                    >
                      https://chainlist.org/chain/56
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <h6>
                  After saving it. Close your browser and try to pledge/unpledge
                  again.
                </h6>
              </li>
            </ol>
          </div>
          <div className="staking-help-modal__content--footer">
            <h4>Did it solve your problem?</h4>
            <p>
              If not, please reach out to us in our{' '}
              <a
                href="https://t.me/magiccraftgamechat"
                target="_blank"
                rel="noreferrer noopener"
              >
                telegram community chat
              </a>{' '}
              so our moderators can help you.
            </p>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default StakingHelpModal;
