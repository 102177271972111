import React, { useState } from 'react';
import './index.scss';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

const data = {
  Email: [
    'mateusz@magiccraft.io',
    'roman@magiccraft.io ',
    'alessandro@magiccraft.io',
    'azka@magiccraft.io',
    'adam@magiccraft.io',
    'gail@magiccraft.io',
    'david@magiccraft.io',
    'anastasia@magiccraft.io',
    'anastasiya@jamescryptoguru.com',
    'alex@magiccraft.io',
    'dori@magiccraft.io',
    'viet@magiccraft.io',
    'nikita@magiccraft.io',
    'mauro@magiccraft.io',
    'oleh@magiccraft.io',
    'princesingh@magiccraft.io',
    'danielle@magiccraft.io',
    'nastassiakrupko@magiccraft.io',
    'nicole@magiccraft.io',
  ],
  Whatsapp: [
    '(+66)655408050',
    '(+48)570412820',
    '1(415)935-3091',
    '(+39)3465646719',
    '(+1)9495994474',
    '(+33)618803623',
    '(+63)9179613704',
    '(+420)773187953',
    '(+375)298001228',
    '(+31)610511144',
    '(+380)630500555',
    '(+380)630536439',
    '(+375)291104685',
    '(+7)9638360885',
    '(+380)937568526',
    '(+90)5537255299',
    '(+593)992919734',
    '(+213)671985294',
    '(+91)8240512275',
    '(+420)601297059',
    '(+43)6642284541',
    '(+63)9205371220',
    '(+375)333430315',
    '(+66)943320579',
    '(+44)7378307109',
    '(+351)930690507',
    '(+38)0631071260',
    '(+92)3194391709',
    '(+351)932569091',
  ],
  Telegram: [
    '@jamesCryptoGuruOffical',
    '@Mat_Ba',
    '@romanshp1',
    '@martixn',
    '@azkatoor',
    '@L1ghtOfHeaveN',
    '@gailfuruyama',
    '@ElBizarro',
    '@anastasia_rubanova',
    '@Shinypants2000',
    '@akadakha',
    '@anastasiiapoli',
    '@nratkevichqa',
    '@chamel92',
    '@OlegVoroshilov',
    '@erhanaydn',
    '@Zedeykey',
    '@FudKiller47',
    '@HiddenByDefault',
    '@mariodecentralized',
    '@danirawrasaur',
    '@nastja_krupko',
    '@pandoratoolbox',
    '@NixExplained',
    '@Black_Mouth',
    '@MuqsitAli',
    '@Haron_PF',
  ],
  Twitter: [
    '@jamyies',
    '@Egzothicki',
    '@Romchig',
    '@Alesxaxdro',
    '@GailFuruyama',
    '@shiny_ha',
    '@nikita02567226',
    '@OlehVoroshilov',
    '@FudKiller47',
    '@mariodecentral',
    '@danielleMCRT',
    '@nastya_krupko',
    '@PandoraToolbox',
    '@MuqsitHaider',
    '@Nogon31',
  ],
  Website: [
    'app.magiccraft.io',
    'magiccraft.io',
    'www.magiccraft.io',
    'staking.magiccraft.io',
    'marketplace.magiccraft.io',
  ],
  Wechat: [],
  Instagram: [
    'jamescryptoguru',
    'gailfuruyama',
    'rubanova_nastya',
    'nikita_ratkevich',
    'voroshilov.oleg',
    'mariodecentralized',
    'nastja_krupko',
    'emperor_deen',
    'viet_hoang_ng',
  ],
};

const Verify = () => {
  const [query, setQuery] = useState('');
  const [platform, setPlatform] = useState('Telegram');
  const [result, setResult] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    if (query.length > 0) {
      let isExist = false;
      let tempResult = '';
      let tempQuery = query;

      if (platform === 'Telegram' || platform === 'Twitter') {
        if (!query.includes('@')) {
          tempQuery = '@' + query;
          setQuery(tempQuery);
        }
      }

      if (platform === 'Website') {
        if (query.includes('https')) {
          tempQuery = query.replaceAll('/', '').replace('https:', '');
        }
      }

      if (data[platform].length > 0) {
        data[platform].map((string) => {
          if (!isExist) {
            if (tempQuery.toLowerCase() === string.toLowerCase()) {
              tempResult = 'exist';
              isExist = true;
            } else {
              tempResult = '!exist';
            }
          }
        });
      } else {
        tempResult = '!exist';
      }

      setResult(tempResult);
    }
  }

  return (
    <div>
      <section className="verify-container">
        <h1 className="verify-container__heading">MagicCraft Verify</h1>
        <div className="verify-container__platform-container">
          <h2>Choose the platform:</h2>
          <select
            className="verify-container__select"
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
          >
            <option value="Telegram">Telegram</option>
            <option value="Twitter">Twitter</option>
            <option value="Email">Email</option>
            <option value="Instagram">Instagram</option>
            <option value="Wechat">WeChat</option>
            <option value="Website">Website</option>
            <option value="Whatsapp">WhatsApp</option>
          </select>
        </div>
        <form
          className={`verify-container__input-container ${
            result
              ? result === 'exist'
                ? 'verify-container__exist'
                : 'verify-container__noexist'
              : ''
          }`}
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="verify-container__result-container">
            {result ? (
              result === 'exist' ? (
                <AiOutlineCheck
                  className="check"
                  stroke="black"
                  stroke-width="10"
                />
              ) : (
                <AiOutlineClose
                  className="close"
                  stroke="black"
                  stroke-width="10"
                />
              )
            ) : null}
          </div>
          <input
            type="text"
            placeholder="Entry query"
            className="verify-container__input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button type="submit" className="verify-container__button">
            Check
          </button>
        </form>
        <p className="verify-container__text">
          Dear MagicCrafters! Please use MagicCraft Verify to check whether the
          source officially represents MagicCraft. Website link, email address,
          phone number, WeChat ID, Twitter account or Telegram ID.
        </p>
      </section>
    </div>
  );
};

export default Verify;
