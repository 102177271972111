import axios from 'axios';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { Grid } from 'antd';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
  Badge,
  Button,
  Collapse,
  Dropdown,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchMagicNftsAsync } from 'state/action';
import { useNFTMarketplaceState } from 'state/hooks';
import { getNftContractAddress } from 'state/nftMarketplace';
import InventoryItem from '../NFTInventory/NFTItem';

// styles
import './index.scss';
import CollectionButton from 'components/CollectionButton/CollectionButton';
import { GEM_PACK_DATA } from 'config';
import GemsPack from 'pages/NFTInventory/GemsPack';
import { ToastContainer } from 'react-toastify';

const NFTMain = (props) => {
  const filterList = [
    'Highest Price First',
    'Lowest Price First',
    'Latest First',
  ];

  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const breakpoint = Grid.useBreakpoint();
  const navigate = useNavigate();
  const [typeOpen, setTypeOpen] = useState(true);
  const [rarityOpen, setRarityOpen] = useState(true);
  const [abilityOpen, setAbilityOpen] = useState(true);
  const [cacheListedNfts, setCacheListedNfts] = useState([]);
  const [nftType, setNFTType] = useState([]);
  const [bnbPrice, setBNBPrice] = useState(0);
  const [mcrtPrice, setMCRTPrice] = useState(0);
  const [nftRarity, setNFTRarity] = useState([]);
  const [cachedNftRarity, setCachedNftRarity] = useState([]);
  const [loadFilteredItems, setLoadFilteredItems] = useState(false);
  const [nftAbility, setNFTAbility] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [isSortChange, setIsSortChange] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [nftTab, setNFTTab] = useState('Characters');
  const [nftListFilter, setNFTListFilter] = useState(
    filterList.includes(localStorage.getItem('filterlist'))
      ? localStorage.getItem('filterlist')
      : filterList[0],
  );
  const [isFilterModal, setFilterModal] = useState(false);
  const [sortedItem, setSortedItem] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [nftLimit, setNFTLimit] = useState(Array.from({ length: 50 }));
  const { listedNfts } = useNFTMarketplaceState();
  const { collection } = useNFTMarketplaceState();
  const customStyles = {
    overlay: {
      zIndex: '999',
    },
  };

  useEffect(() => {
    getPriceData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(fetchMagicNftsAsync(pageNumber));
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    dispatch(fetchMagicNftsAsync(pageNumber, nftRarity));
  }, [pageNumber, nftListFilter]);

  useEffect(() => {
    dispatch(fetchMagicNftsAsync(0, nftRarity));
  }, [collection]);

  const firstUpdateRarity = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdateRarity.current) {
      firstUpdateRarity.current = false;
      return;
    }

    if (nftRarity !== cachedNftRarity) {
      if (pageNumber !== 0) {
        setPageNumber(0);
      } else {
        dispatch(fetchMagicNftsAsync(pageNumber, nftRarity));
      }
    } else {
      dispatch(fetchMagicNftsAsync(pageNumber, nftRarity));
    }
  }, [nftRarity]);

  useEffect(() => {
    filteredItem(false);
    setIsFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listedNfts]);

  useEffect(() => {
    if (!isSortChange) {
      filteredItem(true);
    } else if (nftRarity.length === 0 && cachedNftRarity.length > 0) {
      setCachedNftRarity([]);
    }
  }, [nftType]);

  const getPriceData = async () => {
    try {
      const priceData = await axios.get(
        `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,
      );

      if (priceData.data) {
        setBNBPrice(Number(priceData.data.price));
      }

      const lobbyBackURL = process.env.REACT_APP_LOBBY_BACK_URL;

      const response = await axios.get(`${lobbyBackURL}/mcrt-price`);

      const mcrtPrice = response?.data?.price;

      if (mcrtPrice) {
        setMCRTPrice(mcrtPrice);
      } else {
        setMCRTPrice(0);
      }
    } catch (error) {
      console.log(error);
      setMCRTPrice(0);
    }
  };

  const getCurrentPrice = (inventoryData) => {
    const today = new Date();
    let data = { ...inventoryData };

    if (
      !data ||
      !data.startingPrice ||
      !data.endingPrice ||
      !data.duration ||
      !data.startAt
    )
      return 0;

    data.startingPrice = Number(data.startingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });

    data.endingPrice = Number(data.endingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });
    if (!data.isMCRT) {
      const bnbAmt = ethers.utils.formatEther(
        ethers.BigNumber.from(`${data.startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${data.endingPrice}`).sub(
                ethers.BigNumber.from(`${data.startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
      );

      return Number(bnbAmt);
    } else {
      const mcrtAmt = ethers.utils.formatUnits(
        ethers.BigNumber.from(`${data.startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${data.endingPrice}`).sub(
                ethers.BigNumber.from(`${data.startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
        9,
      );

      return Number(mcrtAmt);
    }
  };

  const handleChange = (val) => setNFTType(val);

  const handleRarity = (val) => {
    if (val !== cachedNftRarity) {
      setNFTRarity(val);
    } else {
      setIsSortChange(true);
      setNFTRarity([]);
      setCachedNftRarity([]);
    }
  };

  const handleAbility = (val) => setNFTAbility(val);

  const handleTab = (val) => setNFTTab(val);

  const handleListFilter = (val) => {
    localStorage.setItem('filterlist', val);
    setNFTListFilter(val);
    setPageNumber(0);
    setIsSortChange(true);
    setShowLoading(true);
    setHasMore(true);
  };

  const closeFilterModal = () => {
    setFilterModal(false);
  };

  const goToNftDetail = (id) => {
    navigate(`/marketplace/${getNftContractAddress()}/${id}`);
  };

  const resetFilter = () => {
    setIsSortChange(true);
    setNFTRarity([]);
    setNFTType([]);
    setNFTAbility([]);
  };

  const filteredItem = (isFilterActive) => {
    if (!listedNfts) {
      setSortedItem(null);
      return;
    }

    let filtered = [];
    const tempCachedList = listedNfts;
    let currentList;

    if (collection === 'revelation') {
      setSortedItem(listedNfts);
      // return;
    }

    if (nftRarity.length > 0) {
      if (nftRarity !== cachedNftRarity) {
        currentList = listedNfts;
        setCacheListedNfts(currentList);
        setCachedNftRarity(nftRarity);
      } else if (loadFilteredItems) {
        currentList = tempCachedList;
        setLoadFilteredItems(false);
        setCacheListedNfts(currentList);
      } else if (isSortChange && pageNumber === 0) {
        currentList = listedNfts;
        setCacheListedNfts(currentList);
        setIsSortChange(false);
      } else {
        currentList = cacheListedNfts;
      }
    } else if (!isFilterActive && !isSortChange) {
      if (cachedNftRarity.length > 0) {
        currentList = listedNfts;
        setCachedNftRarity([]);
      } else {
        currentList = tempCachedList;
      }

      setCacheListedNfts(currentList);
      setLoadFilteredItems(false);
    } else if (isSortChange && pageNumber === 0) {
      currentList = listedNfts;
      setCacheListedNfts(currentList);
      setIsSortChange(false);
    } else {
      currentList = tempCachedList;
    }

    if (nftType.length > 0) {
      filtered = currentList.filter((item) => {
        for (var i = 0; i < nftType.length; i++)
          if (item.name.includes(nftType[i])) return true;
      });
    } else if (nftType.length === 0) {
      filtered = currentList;
    }

    setSortedItem(filtered);

    if (filtered.length >= nftLimit.length) {
      setNFTLimit(
        nftLimit.concat(
          Array.from({ length: filtered.length - nftLimit.length }),
        ),
      );
      setHasMore(true);
    } else {
      setNFTLimit(Array.from({ length: filtered.length }));
    }

    // if (!isFilterActive) {
    setHasMore(false);
    setShowLoading(false);
    // }
  };

  const fetchMoreData = () => {
    // if (!isSortChange) {
    //   setIsFetching(true);
    //   setPageNumber(pageNumber + 1);
    //   setLoadFilteredItems(true);
    // }
  };

  // render on mobile
  const renderOnMobile = () => {
    return (
      <Tabs defaultActiveKey="Characters1" id="sold">
        <Tab eventKey="Characters1" title="Characters">
          <div className="main__container">
            <div className="main__container--filter">
              <div className="main__container--filter--titleContainer">
                <div className="main__container--filter--titleContainer--title">
                  Filter Items
                </div>
                <div
                  className="main__container--filter--titleContainer--reset"
                  onClick={resetFilter}
                >
                  Reset All
                </div>
              </div>
              <div className="main__container--filter--divider" />
              <div className="main__container--filter--filterTypeContainer">
                <div
                  className="main__container--filter--filterTypeContainer--filterTypeHeader"
                  onClick={() => setTypeOpen(!typeOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={typeOpen}
                >
                  {!typeOpen ? (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretDownFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Character Type'}
                    </div>
                  ) : (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretUpFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Character Type'}
                    </div>
                  )}
                  <Badge pill bg="primary">
                    {nftType.length}
                  </Badge>{' '}
                </div>
                <Collapse in={typeOpen}>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={nftType}
                    onChange={handleChange}
                  >
                    <ToggleButton id="tbg-btn-1" value="Knight">
                      Knight
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-2" value="Hunter">
                      Hunter
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value="Keeper">
                      Arcana
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Collapse>
              </div>
              <div className="main__container--filter--divider" />
              {/* <div className="main__container--filter--filterTypeContainer">
                <div
                  className="main__container--filter--filterTypeContainer--filterTypeHeader"
                  onClick={() => setAbilityOpen(!abilityOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={abilityOpen}
                >
                  {!abilityOpen ? (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretDownFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Search Abilities'}
                    </div>
                  ) : (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretUpFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Search Abilities'}
                    </div>
                  )}
                  <Badge pill bg="primary">
                    {nftType.length}
                  </Badge>
                </div>
                <Collapse in={abilityOpen}>
                  <div className="panel-body">
                    <ul>
                      <li className="list-group-item">
                        <img src={nftIcon1} alt="" />
                        <div>Sweeping Blow</div>
                      </li>
                      <li className="list-group-item">
                        <img src={nftIcon2} alt="" />
                        <div>Sweeping Blow</div>
                      </li>
                      <li className="list-group-item">
                        <img src={nftIcon3} alt="" />
                        <div>Sweeping Blow</div>
                      </li>
                      <li className="list-group-item">
                        <img src={nftIcon4} alt="" />
                        <div>Sweeping Blow</div>
                      </li>
                      <li className="list-group-item">
                        <img src={nftIcon1} alt="" />
                        <div>Sweeping Blow</div>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div> */}
              {/* <div className="main__container--filter--divider" /> */}

              <div className="main__container--filter--filterTypeContainer">
                <div
                  className="main__container--filter--filterTypeContainer--filterTypeHeader"
                  onClick={() => setRarityOpen(!rarityOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={rarityOpen}
                >
                  {!rarityOpen ? (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretDownFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'RARITY'}
                    </div>
                  ) : (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretUpFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'RARITY'}
                    </div>
                  )}
                  <Badge pill bg="primary">
                    {nftRarity.length}
                  </Badge>{' '}
                </div>
                <Collapse in={rarityOpen}>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={nftRarity}
                    onChange={handleRarity}
                  >
                    <ToggleButton id="tbg-btn-4" value="rare">
                      Rare
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-5" value="epic">
                      Epic
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-6" value="legendary">
                      Legendary
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Collapse>
              </div>
            </div>
            <div className="main__container--itemListContainer">
              {/* <div></div> */}
              <div className="main__container--itemListContainer--itemListFilter">
                <h5>
                  Showing{' '}
                  {nftLimit.length == 1 ? '1 item' : nftLimit.length + ' items'}
                </h5>
                <CollectionButton />
                <Dropdown onSelect={handleListFilter}>
                  <Dropdown.Toggle
                    id="dropdown-filter"
                    disabled={isFetching ? true : false}
                  >
                    {nftListFilter}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {filterList.map((filter, index) => {
                      if (nftListFilter !== filter) {
                        return (
                          <Dropdown.Item href="" eventKey={filter} key={index}>
                            {filter}
                          </Dropdown.Item>
                        );
                      }
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="main__container--itemListContainer--itemListView">
                <InfiniteScroll
                  dataLength={nftLimit.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  height={700}
                  loader={
                    showLoading && !isSortChange ? <h4>Loading...</h4> : null
                  }
                >
                  {(!sortedItem || isSortChange) && (
                    <div className="main__container--spinner" />
                  )}
                  {sortedItem && sortedItem.length === 0 && (
                    <>
                      {listedNfts.length != 0 ? (
                        <div
                          style={{
                            transform: 'translateY(200px)',
                            textAlign: 'center',
                            padding: '0 1rem',
                          }}
                        >
                          No character matches your filter
                        </div>
                      ) : (
                        <div
                          style={{
                            transform: 'translateY(200px)',
                            textAlign: 'center',
                            padding: '0 1rem',
                          }}
                        >
                          No Items are listed on marketplace
                        </div>
                      )}
                    </>
                  )}

                  <div className="main__container--itemListContainer--itemListView--wrapper">
                    {!isSortChange &&
                      sortedItem &&
                      sortedItem.length > 0 &&
                      sortedItem
                        .slice(0, nftLimit.length)
                        .map((inventoryNft, index) => {
                          return (
                            <div
                              className="main__container--itemListContainer--itemListView--itemView"
                              key={index}
                            >
                              {!inventoryNft.isMCRT ? (
                                <InventoryItem
                                  key={inventoryNft.tokenID}
                                  data={inventoryNft}
                                  onClick={() =>
                                    goToNftDetail(inventoryNft.tokenID)
                                  }
                                  price={
                                    getCurrentPrice(inventoryNft) == 0
                                      ? null
                                      : getCurrentPrice(inventoryNft)
                                  }
                                  priceDollar={
                                    getCurrentPrice(inventoryNft) == 0
                                      ? null
                                      : getCurrentPrice(inventoryNft) * bnbPrice
                                  }
                                />
                              ) : (
                                <InventoryItem
                                  key={inventoryNft.tokenID}
                                  data={inventoryNft}
                                  onClick={() =>
                                    goToNftDetail(inventoryNft.tokenID)
                                  }
                                  mcrtPrice={
                                    getCurrentPrice(inventoryNft) == 0
                                      ? null
                                      : getCurrentPrice(inventoryNft)
                                  }
                                  mcrtPriceDollar={
                                    getCurrentPrice(inventoryNft) == 0
                                      ? null
                                      : getCurrentPrice(inventoryNft) *
                                        mcrtPrice
                                  }
                                />
                              )}
                            </div>
                          );
                        })}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Items1" title="Items">
          <div className="main__container">
            <div className="main__container--filter">
              <div className="main__container--filter--titleContainer">
                <div className="main__container--filter--titleContainer--title">
                  Filter Items
                </div>
                <div
                  className="main__container--filter--titleContainer--reset"
                  onClick={resetFilter}
                >
                  Reset All
                </div>
              </div>
              <div className="main__container--filter--divider" />
              <div className="main__container--filter--filterTypeContainer">
                <div
                  className="main__container--filter--filterTypeContainer--filterTypeHeader"
                  onClick={() => setTypeOpen(!typeOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={typeOpen}
                >
                  {!typeOpen ? (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretDownFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Item Type'}
                    </div>
                  ) : (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretUpFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Item Type'}
                    </div>
                  )}
                  <Badge pill bg="primary">
                    {nftType.length}
                  </Badge>{' '}
                </div>
                <Collapse in={typeOpen}>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={nftType}
                    onChange={handleChange}
                  >
                    <ToggleButton id="tbg-btn-1" value="Gems">
                      Gems
                    </ToggleButton>
                    <ToggleButton
                      style={{
                        backgroundColor: '#4E507A',
                        borderColor: '#4E507A',
                      }}
                      disabled
                      id="tbg-btn-2"
                      value="Coins"
                    >
                      Coins
                    </ToggleButton>
                    <ToggleButton
                      style={{
                        backgroundColor: '#4E507A',
                        borderColor: '#4E507A',
                      }}
                      disabled
                      id="tbg-btn-3"
                      value="Armor"
                    >
                      Armor
                    </ToggleButton>
                    <ToggleButton
                      style={{
                        backgroundColor: '#4E507A',
                        borderColor: '#4E507A',
                      }}
                      disabled
                      id="tbg-btn-4"
                      value="Skins"
                    >
                      Skins
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Collapse>
              </div>
              <div className="main__container--filter--divider" />
              <div className="main__container--filter--filterTypeContainer">
                <div
                  className="main__container--filter--filterTypeContainer--filterTypeHeader"
                  onClick={() => setRarityOpen(!rarityOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={rarityOpen}
                >
                  {!rarityOpen ? (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretDownFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Game'}
                    </div>
                  ) : (
                    <div className="main__container--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                      <BsFillCaretUpFill
                        size="12px"
                        style={{ marginRight: '0.2rem', opacity: '0.8' }}
                      />
                      {'Game'}
                    </div>
                  )}
                  <Badge pill bg="primary">
                    {nftRarity.length}
                  </Badge>{' '}
                </div>
                <Collapse in={rarityOpen}>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={nftRarity}
                    onChange={handleRarity}
                  >
                    <ToggleButton id="tbg-btn-5" value="magicrunner">
                      MagicRunner
                    </ToggleButton>
                    <ToggleButton
                      style={{
                        backgroundColor: '#4E507A',
                        borderColor: '#4E507A',
                      }}
                      disabled
                      id="tbg-btn-6"
                      value="magiccraft"
                    >
                      MagicCraft
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Collapse>
              </div>
            </div>
            <div className="main__container--itemListContainer">
              {/* <div className="main__container--itemListContainer--itemListFilter"> */}
              {/* <h5>
                  Showing{' '}
                  {nftLimit.length == 1 ? '1 item' : nftLimit.length + ' items'}
                </h5>
                <CollectionButton />
                <Dropdown onSelect={handleListFilter}>
                  <Dropdown.Toggle
                    id="dropdown-filter"
                    disabled={isFetching ? true : false}
                  >
                    {nftListFilter}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {filterList.map((filter, index) => {
                      if (nftListFilter !== filter) {
                        return (
                          <Dropdown.Item href="" eventKey={filter} key={index}>
                            {filter}
                          </Dropdown.Item>
                        );
                      }
                    })}
                  </Dropdown.Menu>
                </Dropdown> */}
              {/* </div> */}
              <div className="main__container--itemListContainer--itemListView">
                <div className="main__container--itemListContainer--itemListView--wrapper">
                  {GEM_PACK_DATA.map((pack) => {
                    return (
                      <div
                        className="main__container--itemListContainer--itemListView--itemView"
                        key={pack.id}
                      >
                        <GemsPack mcrtPrice={mcrtPrice} packData={pack} />
                      </div>
                    );
                  })}
                  {/* <div className="main__container--lands--empty">
                    <h1>Coming soon</h1>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Land1" title="Land (Coming Soon)">
          <div className="main__container">
            <div className="main__container--lands">
              <div className="main__container--lands--empty">
                <h1>Coming soon</h1>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    );
  };

  // render on desktop
  const renderOnDesktop = () => {
    return (
      <div className="main__mobile">
        <Modal
          isOpen={isFilterModal}
          onRequestClose={closeFilterModal}
          className="main__mobile--filterModal"
          style={customStyles}
        >
          <div className="main__mobile--filterModal--filter">
            <div className="main__mobile--filterModal--filter--titleContainer">
              <div className="main__mobile--filterModal--filter--titleContainer--title">
                Filter Characters
              </div>
              <div className="main__mobile--filterModal--filter--titleContainer--reset">
                Reset All
              </div>
            </div>
            <div className="main__mobile--filterModal--filter--divider" />
            <div className="main__mobile--filterModal--filter--filterTypeContainer">
              <div
                className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader"
                onClick={() => setTypeOpen(!typeOpen)}
                aria-controls="example-collapse-text"
                aria-expanded={typeOpen}
              >
                {!typeOpen ? (
                  <div className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                    <BsFillCaretDownFill
                      size="12px"
                      style={{ marginRight: '0.2rem', opacity: '0.8' }}
                    />
                    {'Character Type'}
                  </div>
                ) : (
                  <div className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                    <BsFillCaretUpFill
                      size="12px"
                      style={{ marginRight: '0.2rem', opacity: '0.8' }}
                    />
                    {'Character Type'}
                  </div>
                )}
                <Badge pill bg="primary">
                  {nftType.length}
                </Badge>{' '}
              </div>
              <Collapse in={typeOpen}>
                <ToggleButtonGroup
                  type="checkbox"
                  value={nftType}
                  onChange={handleChange}
                >
                  <ToggleButton id="tbg-btn-1" value="Knight">
                    Knight
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-2" value="Hunter">
                    Hunter
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-3" value="Keeper">
                    Arcana
                  </ToggleButton>
                </ToggleButtonGroup>
              </Collapse>
            </div>
            <div className="main__mobile--filterModal--filter--divider" />
            {/* <div className="main__mobile--filterModal--filter--filterTypeContainer">
              <div
                className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader"
                onClick={() => setAbilityOpen(!abilityOpen)}
                aria-controls="example-collapse-text"
                aria-expanded={abilityOpen}
              >
                {!abilityOpen ? (
                  <div className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                    <BsFillCaretDownFill
                      size="12px"
                      style={{ marginRight: '0.2rem', opacity: '0.8' }}
                    />
                    {'Search Abilities'}
                  </div>
                ) : (
                  <div className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                    <BsFillCaretUpFill
                      size="12px"
                      style={{ marginRight: '0.2rem', opacity: '0.8' }}
                    />
                    {'Search Abilities'}
                  </div>
                )}
                <Badge pill bg="primary">
                  {nftType.length}
                </Badge>{' '}
              </div>
              <Collapse in={abilityOpen}>
                <div className="panel-body">
                  <ul>
                    <li className="list-group-item">
                      <img src={nftIcon1} alt="" />
                      <div>Sweeping Blow</div>
                    </li>
                    <li className="list-group-item">
                      <img src={nftIcon2} alt="" />
                      <div>Sweeping Blow</div>
                    </li>
                    <li className="list-group-item">
                      <img src={nftIcon3} alt="" />
                      <div>Sweeping Blow</div>
                    </li>
                    <li className="list-group-item">
                      <img src={nftIcon4} alt="" />
                      <div>Sweeping Blow</div>
                    </li>
                    <li className="list-group-item">
                      <img src={nftIcon1} alt="" />
                      <div>Sweeping Blow</div>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
            <div className="main__mobile--filterModal--filter--divider" /> */}

            <div className="main__mobile--filterModal--filter--filterTypeContainer">
              <div
                className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader"
                onClick={() => setRarityOpen(!rarityOpen)}
                aria-controls="example-collapse-text"
                aria-expanded={rarityOpen}
              >
                {!rarityOpen ? (
                  <div className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                    <BsFillCaretDownFill
                      size="12px"
                      style={{ marginRight: '0.2rem', opacity: '0.8' }}
                    />
                    {'RARITY'}
                  </div>
                ) : (
                  <div className="main__mobile--filterModal--filter--filterTypeContainer--filterTypeHeader--filterTypeHeaderIcon">
                    <BsFillCaretUpFill
                      size="12px"
                      style={{ marginRight: '0.2rem', opacity: '0.8' }}
                    />
                    {'RARITY'}
                  </div>
                )}
                <Badge pill bg="primary">
                  {nftRarity.length}
                </Badge>
              </div>
              <Collapse in={rarityOpen}>
                <ToggleButtonGroup
                  type="checkbox"
                  value={nftRarity}
                  onChange={handleRarity}
                >
                  <ToggleButton id="tbg-btn-4" value="rare">
                    Rare
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-5" value="epic">
                    Epic
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-6" value="legendary">
                    Legendary
                  </ToggleButton>
                </ToggleButtonGroup>
              </Collapse>
            </div>
          </div>
          <Button
            onClick={closeFilterModal}
            style={{
              width: '90%',
              bottom: '100px',
              position: 'absolute',
              background: '#316BFF',
              height: '56px',
            }}
          >
            Apply Filters
          </Button>
        </Modal>
        <div className="main__mobile--header">
          <Dropdown onSelect={handleTab}>
            <Dropdown.Toggle id="dropdown-basic">{nftTab}</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" eventKey="Characters">
                Characters
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2" eventKey="Items">
                Items
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3" eventKey="Land">
                Land
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {nftTab === 'Characters' && (
            <Button onClick={() => setFilterModal(true)}>Filter</Button>
          )}
        </div>
        <div className="main__container--filter--divider" />
        <div className="main__container--itemListContainer">
          {/* <div></div> */}
          {nftTab === 'Characters' && (
            <div
              style={{ flexWrap: 'wrap', gap: '1rem 0' }}
              className="main__container--itemListContainer--itemListFilter"
            >
              <h5>
                Showing {nftLimit.length}{' '}
                {nftLimit.length === 1 ? 'item' : 'items'}
              </h5>
              <Dropdown onSelect={handleListFilter}>
                <Dropdown.Toggle id="dropdown-filter">
                  {nftListFilter}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {filterList.map((filter, index) => {
                    if (nftListFilter !== filter) {
                      return (
                        <Dropdown.Item href="" eventKey={filter} key={index}>
                          {filter}
                        </Dropdown.Item>
                      );
                    }
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <CollectionButton />
            </div>
          )}

          <div className="main__container--itemListContainer--itemListView">
            {nftTab === 'Characters' ? (
              <InfiniteScroll
                dataLength={nftLimit.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={700}
                loader={
                  showLoading && !isSortChange ? <h4>Loading...</h4> : null
                }
              >
                {!sortedItem && <div className="main__container--spinner" />}
                {sortedItem && sortedItem.length === 0 && (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    No Items are listed on marketplace.
                  </div>
                )}
                {sortedItem &&
                  sortedItem
                    .slice(0, nftLimit.length)
                    .map((inventoryNft, index) => {
                      return (
                        <div
                          className="main__container--itemListContainer--itemListView--itemView"
                          key={index}
                        >
                          {!inventoryNft.isMCRT ? (
                            <InventoryItem
                              key={inventoryNft.tokenID}
                              data={inventoryNft}
                              onClick={() =>
                                goToNftDetail(inventoryNft.tokenID)
                              }
                              price={
                                getCurrentPrice(inventoryNft) == 0
                                  ? null
                                  : getCurrentPrice(inventoryNft)
                              }
                              priceDollar={
                                getCurrentPrice(inventoryNft) == 0
                                  ? null
                                  : getCurrentPrice(inventoryNft) * bnbPrice
                              }
                            />
                          ) : (
                            <InventoryItem
                              key={inventoryNft.tokenID}
                              data={inventoryNft}
                              onClick={() =>
                                goToNftDetail(inventoryNft.tokenID)
                              }
                              mcrtPrice={
                                getCurrentPrice(inventoryNft) == 0
                                  ? null
                                  : getCurrentPrice(inventoryNft)
                              }
                              mcrtPriceDollar={
                                getCurrentPrice(inventoryNft) == 0
                                  ? null
                                  : getCurrentPrice(inventoryNft) * mcrtPrice
                              }
                            />
                          )}
                        </div>
                      );
                    })}
              </InfiniteScroll>
            ) : nftTab === 'Items' ? (
              <>
                {GEM_PACK_DATA.map((pack) => {
                  return (
                    <div
                      className="main__container--itemListContainer--itemListView--itemView"
                      key={pack.id}
                    >
                      <GemsPack mcrtPrice={mcrtPrice} packData={pack} />
                    </div>
                  );
                })}
              </>
            ) : (
              <div>Coming Soon</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main">
      {breakpoint.sm ? <>{renderOnMobile()}</> : <>{renderOnDesktop()}</>}
    </div>
  );
};

export default NFTMain;
