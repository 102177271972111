// import { getMagicCraftNfts } from 'subgraph/utils';
import {
  getMagicCraftNfts,
  getMagicCraftListedNfts,
  getMagicCraftAccountWithNfts,
} from 'api/utils';
import { getNftContractAddress } from 'state/nftMarketplace';

export const fetchAccountMagicNfts = async (
  pageNumber: number,
  account: string,
  rarity?: string[],
) => {
  const contractAddress = getNftContractAddress();
  let inventoryNfts: any[] = [];

  const val = localStorage.getItem('filterlist');

  let fieldType;
  let tempFilter;

  if (val === 'Highest Price First') {
    fieldType = 'startingPrice';
    tempFilter = -1;
  } else if (val === 'Lowest Price First') {
    fieldType = 'startingPrice';
    tempFilter = 1;
  } else if (val === 'Latest First') {
    fieldType = 'startAt';
    tempFilter = -1;
  } else {
    fieldType = 'startingPrice';
    tempFilter = -1;
  }

  try {
    inventoryNfts = [
      ...(await getMagicCraftAccountWithNfts(
        pageNumber,
        fieldType,
        process.env.REACT_APP_GENESIS_CONTRACT_ADDRESS || '',
        tempFilter,
        account.toLowerCase(),
        rarity,
      )),
    ];
  } catch (error) {
    inventoryNfts = [];
  }

  return {
    inventoryNfts,
  };
};

export const fetchMagicNfts = async (
  pageNumber: number,
  rarity?: string[],
  latestCall?: boolean,
) => {
  const contractAddress = getNftContractAddress();
  let listedNfts: any[] = [];

  const val = localStorage.getItem('filterlist');

  let fieldType;
  let tempFilter;

  if (!latestCall) {
    if (val === 'Highest Price First') {
      fieldType = 'startingPrice';
      tempFilter = -1;
    } else if (val === 'Lowest Price First') {
      fieldType = 'startingPrice';
      tempFilter = 1;
    } else if (val === 'Latest First') {
      fieldType = 'startAt';
      tempFilter = -1;
    } else {
      fieldType = 'startingPrice';
      tempFilter = -1;
    }
  } else {
    fieldType = 'startAt';
    tempFilter = -1;
  }

  try {
    listedNfts = await getMagicCraftListedNfts(
      pageNumber,
      fieldType,
      contractAddress,
      tempFilter,
      rarity,
    );
  } catch (error) {
    listedNfts = [];
  }

  let nftMetadata: any[] = [];

  try {
    nftMetadata = await getMagicCraftNfts(
      contractAddress,
      listedNfts.map((it: any) => it.tokenID),
    );
  } catch (error) {
    nftMetadata = [];
  }

  if (listedNfts.length) {
    for (let i = 0; i < listedNfts.length; i++) {
      let metadata = nftMetadata.find(
        (it) => it.tokenID == listedNfts[i].tokenID,
      );
      listedNfts[i].name = metadata.name;
      listedNfts[i].description = metadata.description;
      listedNfts[i].image = metadata.image;
      listedNfts[i].attributes = [...metadata.attributes];
    }
  }

  return {
    listedNfts,
  };
};

export const fetchNftInfo = async (
  tokenId: string,
  contractAddress: string,
) => {
  let inventoryNft: any = {};

  try {
    inventoryNft = contractAddress
      ? await getMagicCraftNfts(contractAddress, [tokenId])
      : [];
  } catch (error) {
    inventoryNft = {};
  }

  return {
    selectedNft: inventoryNft.length > 0 ? inventoryNft[0] : null,
  };
};
