import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Grid } from 'antd';
import { Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';

import { useMarketplaceContract, useMCRT } from 'hooks/useContract';
import { fetchNftInfoAsync } from 'state/action';
import { useNFTMarketplaceState } from 'state/hooks';
import { getMarketplaceAddress, getShortenAddress } from 'utils/addressHelpers';
import {
  getNameBgColor,
  getRarityBgColor,
  getRarityFrame,
} from 'utils/nftHelper';
import { SellModal } from 'components/SellModal';

import bnbLogo from 'assets/icon-bnb.png';
import mcrtLogo from 'assets/icon-mcrt.png';
import { ReactComponent as Badge } from 'assets/badge.svg';

// styles
import './index.scss';
import GasFeeHelpContainer from 'components/GasFeeHelpContainer';
import HelpModal from 'components/HelpModal/HelpModal';

const NFTDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const breakpoint = Grid.useBreakpoint();
  const { selectedNft } = useNFTMarketplaceState();
  const { id: tokenId, nft_address: contractAddress } = useParams();

  const [isSellModal, setSellModal] = useState(false);
  const [nftData, setNFTData] = useState(null);
  const [isOwned, setIsOwned] = useState(false);
  const [bnbPrice, setBNBPrice] = useState(200);
  const [mcrtPrice, setMCRTPrice] = useState(200);
  const [isMCRT, setIsMCRT] = useState(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  let downloadedImg = '';

  const magicMCRT = useMCRT();
  const ctMarketplace = useMarketplaceContract();

  // const contractAddress = getNftContractAddress();

  const closeSellModal = () => {
    setSellModal(false);
    navigate(0);
  };

  const onSellItem = () => {
    setSellModal(true);
  };

  const goBack = () => {
    const prevPath = localStorage.getItem('prevPath');

    if (prevPath) {
      navigate(prevPath);
    }
  };

  const onBuyItem = async () => {
    try {
      if (nftData) {
        const today = new Date();

        let startingPrice = Number(nftData.startingPrice).toLocaleString(
          'fullwide',
          {
            useGrouping: false,
          },
        );

        let endingPrice = Number(nftData.endingPrice).toLocaleString(
          'fullwide',
          {
            useGrouping: false,
          },
        );

        const offset = ethers.BigNumber.from(startingPrice).gt(
          ethers.BigNumber.from(endingPrice),
        )
          ? -1000
          : 1000;

        if (!nftData.isMCRT) {
          const bnbAmt = ethers.BigNumber.from(`${startingPrice}`).add(
            ethers.BigNumber.from(
              Math.min(
                nftData.duration,
                Math.ceil(today.getTime() / 1000.0 + offset - nftData.startAt),
              ),
            )
              .mul(
                ethers.BigNumber.from(`${endingPrice}`).sub(
                  ethers.BigNumber.from(`${startingPrice}`),
                ),
              )
              .div(ethers.BigNumber.from(nftData.duration)),
          );

          ctMarketplace.methods
            .bid(contractAddress, tokenId)
            .send({
              from: account,
              value: bnbAmt.toString(),
            })
            .on('receipt', () => {
              toast.success('The NFT has been purchased successfully.');
              setTimeout(() => navigate(0), 5000);
            })
            .on('error', () => {
              if (account) {
                toast.error('Your purchase has been failed.');
              } else {
                toast.error('Please connect your wallet first.');
              }
            });
        } else {
          const mcrtAmt = ethers.BigNumber.from(`${startingPrice}`).add(
            ethers.BigNumber.from(
              Math.min(
                nftData.duration,
                Math.ceil(today.getTime() / 1000.0 + offset - nftData.startAt),
              ),
            )
              .mul(
                ethers.BigNumber.from(`${endingPrice}`).sub(
                  ethers.BigNumber.from(`${startingPrice}`),
                ),
              )
              .div(ethers.BigNumber.from(nftData.duration)),
          );

          const allowance = await magicMCRT.methods
            .allowance(account, getMarketplaceAddress())
            .call();
          if (
            BigNumber.from(String(allowance)).lt(
              BigNumber.from(String(mcrtAmt)),
            )
          ) {
            await magicMCRT.methods
              .approve(getMarketplaceAddress(), mcrtAmt)
              .send({
                from: account,
              });
          }
          // example of successful bid:
          // https://bscscan.com/tx/0xa8e781dd08c66954120d55b0777b9503b4860e3684246b5479085fc692b44ac5
          ctMarketplace.methods
            .bidMCRT(contractAddress, tokenId, mcrtAmt)
            .send({
              from: account,
            })
            .on('receipt', () => {
              toast.success('The NFT has been purchased successfully.');
              setTimeout(() => navigate(0), 5000);
            })
            .on('error', () => {
              if (account) {
                toast.error('Your purchase has been failed.');
              } else {
                toast.error('Please connect your wallet first.');
              }
            });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const onCancelItem = async () => {
    ctMarketplace.methods
      .cancelAuction(contractAddress, tokenId)
      .send({
        from: account,
      })
      .on('receipt', () => {
        toast.success('Your auction has been cancelled successfully.');
        setTimeout(() => navigate(0), 5000);
      })
      .on('error', () => {
        toast.error('Your auction cancel has been failed.');
      });
  };

  useEffect(() => {
    setIsOwned(
      (account &&
        selectedNft &&
        selectedNft.owner &&
        selectedNft.owner.toUpperCase() === account.toUpperCase()) ||
        (account &&
          nftData &&
          nftData.seller.toUpperCase() === account.toUpperCase()),
    );
    startDownload();
  }, [selectedNft, account, nftData]);

  // function imageReceived() {
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');

  //   canvas.width = 318;
  //   canvas.height = 318;

  //   var frame = new Image();
  //   ctx.drawImage(
  //     downloadedImg,
  //     0,
  //     0,
  //     downloadedImg.width,
  //     downloadedImg.height,
  //     3,
  //     3,
  //     canvas.width - 3,
  //     canvas.height - 3,
  //   );
  //   frame.src = getRarityFrame(rarity);
  //   ctx.drawImage(
  //     frame,
  //     0,
  //     0,
  //     frame.width,
  //     frame.height,
  //     0,
  //     0,
  //     canvas.width,
  //     canvas.height,
  //   );

  //   console.log(document.getElementById('avatar'));

  //   document.getElementById('avatar').src = canvas.toDataURL('image/png');
  // }

  function startDownload() {
    if (selectedNft.image && !selectedNft?.image.includes('mp4')) {
      let imageURL = selectedNft.image;
      downloadedImg = new Image();
      downloadedImg.crossOrigin = 'Anonymous';
      // downloadedImg.addEventListener('load', imageReceived, false);
      downloadedImg.src = imageURL;
    }
  }

  useEffect(() => {
    const init = async () => {
      if (Number(tokenId) >= 0) {
        dispatch(fetchNftInfoAsync(tokenId, contractAddress));
      }

      const priceData = await axios.get(
        `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,
      );

      if (priceData.data) {
        setBNBPrice(Number(priceData.data.price));
      }

      const response = await axios.get(
        `${process.env.REACT_APP_MARKETPLACE_BACK_URL}/auction?contractAddress=${contractAddress}&tokenID=${tokenId}`,
      );

      if (response.data && response.data.items.length > 0) {
        setNFTData(response.data.items[0]);
      }

      const mcrtPriceData = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=magiccraft&vs_currencies=usd',
      );
      if (mcrtPriceData.data) {
        setMCRTPrice(Number(mcrtPriceData.data.magiccraft.usd));
      }

      const mcrtAuction = await ctMarketplace.methods
        .isMCRT(contractAddress, tokenId)
        .call();
      setIsMCRT(mcrtAuction);

      if (9999 < Number(tokenId)) {
        navigate('/marketplace/profile');
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  if (!selectedNft || !selectedNft.tokenID) {
    return (
      <div className="nft-detail">
        <div className="nft-detail__spinner" />
      </div>
    );
  }

  const rarity = selectedNft.attributes.find(
    (it) => it.trait_type === 'rarity',
  )?.value;

  // console.log(selectedNft.image, getRarityFrame(rarity))

  // mergeImages([selectedNft.image, getRarityFrame(rarity)])
  // .then((b64) => {
  //   document.getElementsByClassName('abc')[0].src = b64;
  // })
  // .catch(error => console.log(error));

  let attributes = [];

  for (let i = 1; i <= 3; i++) {
    const defName = selectedNft.attributes.find(
      (it) => it.trait_type === `default_ability_name_${i}`,
    );
    const defValue = selectedNft.attributes.find(
      (it) => it.trait_type === `default_description_${i}`,
    );

    const specName = selectedNft.attributes.find(
      (it) => it.trait_type === `special_name_${i}`,
    );
    const specValue = selectedNft.attributes.find(
      (it) => it.trait_type === `special_description_${i}`,
    );

    if (defName && defValue) {
      attributes.push({ name: defName.value, value: defValue.value });
    }

    if (specName && specValue) {
      attributes.push({ name: specName.value, value: specValue.value });
    }
  }

  const showBNBPrice = (data) => {
    const today = new Date();

    let startingPrice = Number(data.startingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });

    let endingPrice = Number(data.endingPrice).toLocaleString('fullwide', {
      useGrouping: false,
    });

    let amt;

    if (!isMCRT)
      amt = ethers.utils.formatEther(
        ethers.BigNumber.from(`${startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${endingPrice}`).sub(
                ethers.BigNumber.from(`${startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
      );
    else
      amt = ethers.utils.formatUnits(
        ethers.BigNumber.from(`${data.startingPrice}`).add(
          ethers.BigNumber.from(
            Math.min(
              data.duration,
              Math.ceil(today.getTime() / 1000.0 - data.startAt),
            ),
          )
            .mul(
              ethers.BigNumber.from(`${data.endingPrice}`).sub(
                ethers.BigNumber.from(`${data.startingPrice}`),
              ),
            )
            .div(ethers.BigNumber.from(data.duration)),
        ),
        9,
      );

    if (isMCRT === null) return;

    return (
      <div className="nft-detail__metadata-price">
        <div className="d-flex align-items-center">
          <img src={isMCRT ? mcrtLogo : bnbLogo} alt="bnb" />
          <span>
            {isMCRT ? Number(amt).toLocaleString() : Number(amt).toFixed(2)}
          </span>
        </div>

        <p className="price-dollar">
          ${(Number(amt) * Number(isMCRT ? mcrtPrice : bnbPrice)).toFixed(2)}
        </p>
      </div>
    );
  };

  const openHelpModal = () => {
    setIsHelpModalOpen(true);
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };

  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
    document.body.style.overflowY = 'unset';
    document.body.style.overflowX = 'hidden';
  };

  return (
    <>
      {isHelpModalOpen && (
        <HelpModal isModalOpen={isHelpModalOpen} closeModal={closeHelpModal} />
      )}
      <div className="container nft-detail">
        <SellModal
          onClose={closeSellModal}
          isSellModal={isSellModal}
          nftItem={selectedNft}
        />

        <div className="nft-detail__back" onClick={goBack}>
          <div className="nft-detail__back-wrapper">
            <img src="/assets/nft/back.svg" alt="back" />
            <span>Back</span>
          </div>

          {breakpoint.xs && nftData && (
            <div className="nft-detail__back-list">
              <Badge />
              <p>Item listed:</p>
              <strong>
                {nftData &&
                  nftData.startAt &&
                  new Date(Number(nftData.startAt) * 1000)
                    .toString()
                    .split(' ')
                    .slice(1, 4)
                    .join(' ')}
              </strong>
            </div>
          )}
        </div>

        <div className="nft-detail__content mobile-top">
          <div className="d-flex align-items-center justify-content-between">
            <h1 className="item-title">{selectedNft.name}</h1>
          </div>

          <div className="d-flex">
            <span
              className="hero__knight"
              style={{ backgroundColor: getNameBgColor(selectedNft.name) }}
            >
              {selectedNft.name.split(',')[1]?.split('The').join('')}
            </span>
            {rarity && (
              <span
                className="rarity__legendary"
                style={{ backgroundColor: getRarityBgColor(rarity) }}
              >
                {rarity}
              </span>
            )}
          </div>

          <p className="item-id">{`#${selectedNft.tokenID}`}</p>
        </div>

        <div className="nft-detail__section">
          <div className="nft-detail__avatar">
            {rarity && selectedNft.name !== 'Blazy' && (
              <img
                src={getRarityFrame(rarity)}
                style={{ position: 'absolute', zIndex: '11' }}
                alt="1"
              />
            )}
            {selectedNft.image.includes('mp4') ? (
              <video
                src={selectedNft.image}
                muted
                loop
                autoPlay
                controls={false}
                alt=""
                playsInline
              />
            ) : (
              <img src={selectedNft.image} alt="" />
            )}
            {/* <img id="avatar" src="" alt="avatar" /> */}
          </div>

          <div className="nft-detail__metadata">
            <div
              className={`nft-detail__metadata__wrapper container ${
                isOwned && 'align-items-center justify-content-end w-100'
              }`}
            >
              {!isOwned && (
                <div className="nft-detail__metadata-owner">
                  <p className="text-owner">OWNER</p>
                  <p className="text-owner__address">
                    {getShortenAddress(
                      nftData ? nftData.seller : selectedNft.owner,
                    )}
                  </p>
                </div>
              )}

              {isOwned && nftData && (
                <div className="nft-detail__metadata-list">
                  <Badge />
                  <div>
                    <p>Item listed:</p>
                    {/* <strong>May 20 2022</strong> */}
                    <strong>
                      {nftData &&
                        nftData.startAt &&
                        new Date(Number(nftData.startAt) * 1000)
                          .toString()
                          .split(' ')
                          .slice(1, 4)
                          .join(' ')}
                    </strong>
                  </div>
                </div>
              )}

              {isOwned ? (
                nftData ? (
                  <div className="nft-detail__metadata-price__wrapper">
                    {showBNBPrice(nftData)}
                    <Button className="danger" onClick={onCancelItem}>
                      Cancel Listing
                    </Button>
                  </div>
                ) : (
                  <Button variant="primary" onClick={onSellItem}>
                    Sell Item
                  </Button>
                )
              ) : (
                nftData && (
                  <div className="nft-detail__metadata-price__wrapper">
                    {showBNBPrice(nftData)}
                    <Button variant="primary" onClick={onBuyItem}>
                      Buy Item
                    </Button>
                  </div>
                )
              )}
            </div>

            <div className="nft-detail__content">
              <div className="container nft-detail__content__wrapper">
                <div className="d-flex align-items-center justify-content-between">
                  <h1 className="item-title">{selectedNft.name}</h1>
                  <span className="item-id">{`#${selectedNft.tokenID}`}</span>
                </div>

                {rarity && (
                  <div className="d-flex">
                    <span
                      className="hero__knight"
                      style={{
                        backgroundColor: selectedNft.name.includes('Knight')
                          ? '#C41E3A'
                          : selectedNft.name.includes('Hunter')
                          ? '#AAD372'
                          : '#3FC7EB',
                      }}
                    >
                      {selectedNft.name.split(',')[1]?.split('The').join('')}
                    </span>
                    <span
                      className="rarity__legendary"
                      style={{ backgroundColor: getRarityBgColor(rarity) }}
                    >
                      {rarity}
                    </span>
                  </div>
                )}
              </div>

              <div>
                <h3 className="item-description-text">DESCRIPTION</h3>
                <p className="item-description">{selectedNft.description}</p>
              </div>

              {attributes.length ? (
                <div className="nft-detail__ability">
                  <h3 className="item-ability">Abilities</h3>
                  <div className="nft-detail__ability-content">
                    {/* Ability Item */}
                    {attributes.map((it) => {
                      return (
                        <div className="nft-detail__ability-item" key={it.name}>
                          <div className="nft-detail__ability-item__image">
                            <img
                              src={`/assets/nft/defensive_stance.png`}
                              alt="attribute-img"
                            />
                          </div>

                          <div className="nft-detail__ability-item__text">
                            <p className="item-ability-type">{it.name}</p>
                            <p className="item-ability-value">{it.value}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>

            <GasFeeHelpContainer
              content="Experiencing high gas fees?"
              openStakingHelpModal={openHelpModal}
            />
          </div>
        </div>
      </div>

      {!isOwned ? (
        <div className="container">
          <div className="nft-detail__metadata__wrapper tablet">
            <div className="nft-detail__metadata-owner">
              <p className="text-owner">OWNER</p>
              <p className="text-owner__address">
                {`(${getShortenAddress(
                  nftData ? nftData.seller : selectedNft.owner,
                )})`}
              </p>
            </div>
            {nftData && (
              <div className="nft-detail__metadata-price__wrapper">
                {showBNBPrice(nftData)}
                <Button variant="primary" onClick={onBuyItem}>
                  Buy Item
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="nft-detail__metadata__wrapper tablet">
            <div
              className={`d-flex align-items-center justify-content-${
                nftData ? 'between' : 'center'
              } w-100`}
            >
              {nftData && (
                <div className="nft-detail__metadata-list">
                  <Badge />
                  <div>
                    <p>Item listed:</p>
                    <strong>
                      {nftData.startAt &&
                        new Date(Number(nftData.startAt) * 1000)
                          .toString()
                          .split(' ')
                          .slice(1, 4)
                          .join(' ')}
                    </strong>
                  </div>
                </div>
              )}

              {nftData ? (
                <div className="nft-detail__metadata-price__wrapper tablet">
                  {showBNBPrice(nftData)}
                  <Button className="danger" onClick={onCancelItem}>
                    Cancel Listing
                  </Button>
                </div>
              ) : (
                <Button variant="primary" onClick={onSellItem}>
                  Sell Item
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      {!isOwned ? (
        <div className="container">
          <div className="nft-detail__metadata-owner mobile">
            <p className="text-owner">OWNER</p>
            <p className="text-owner__address">
              {nftData ? nftData.seller : selectedNft.owner}
            </p>
          </div>
          <div className="nft-detail__metadata__wrapper mobile">
            {nftData && (
              <div className="nft-detail__metadata-price__wrapper mobile">
                {showBNBPrice(nftData)}
                <Button variant="primary" onClick={onBuyItem}>
                  Buy Item
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="">
          <div className="nft-detail__metadata__wrapper mobile">
            <div
              className={`d-flex align-items-center justify-content-${
                nftData ? 'between' : 'center'
              } w-100`}
            >
              {nftData ? (
                <div className="nft-detail__metadata-price__wrapper mobile">
                  {showBNBPrice(nftData)}
                  <Button className="danger" onClick={onCancelItem}>
                    Cancel Listing
                  </Button>
                </div>
              ) : (
                <Button variant="primary" onClick={onSellItem}>
                  Sell Item
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NFTDetail;
