/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAccountMagicNfts,
  fetchNftInfo,
  fetchMagicNfts,
} from './fetchAccountNftInfo';
import { NFTMarketplaceState } from 'state/types';

const initialCollection = (localStorage.getItem('collection') ||
  'revelation') as NFTMarketplaceState['collection'];

const initialState: NFTMarketplaceState = {
  inventoryNfts: null,
  selectedNft: {},
  listedNfts: null,
  collection: initialCollection,
};

export const NFTMarketplaceSlice = createSlice({
  name: 'NFTMarketplace',
  initialState,
  reducers: {
    setData: (state, action) => {
      if (
        action.payload.inventoryNfts ||
        (action.payload.inventoryNfts == null &&
          action.payload.inventoryNfts !== undefined)
      ) {
        state.inventoryNfts = action.payload.inventoryNfts;
      }

      if (action.payload.selectedNft) {
        state.selectedNft = action.payload.selectedNft;
      }
      if (action.payload.listedNfts) {
        state.listedNfts = action.payload.listedNfts;
      }
      if (action.payload.collection) {
        state.collection = action.payload.collection;
      }
    },
  },
});

// Actions
export const { setData } = NFTMarketplaceSlice.actions;

export const fetchAccountMagicNftsAsync =
  (account: string, pageNumber?: number) => async (dispatch: any) => {
    const { inventoryNfts } = await fetchAccountMagicNfts(
      pageNumber ? pageNumber : 0,
      account,
    );

    dispatch(
      setData({
        inventoryNfts,
      }),
    );
  };

export const fetchMagicNftsAsync =
  (pageNumber: number, rarity?: string[], latestCall?: boolean) =>
  async (dispatch: any) => {
    const { listedNfts } = await fetchMagicNfts(pageNumber, rarity, latestCall);

    dispatch(
      setData({
        listedNfts: listedNfts,
      }),
    );
  };

export const setAccountMagicNftsEmptyAsync = () => async (dispatch: any) => {
  dispatch(
    setData({
      inventoryNfts: null,
    }),
  );
};

export const fetchNftInfoAsync =
  (tokenId: string, contractAddress: string) => async (dispatch: any) => {
    // initialize selectedNft
    dispatch(
      setData({
        selectedNft: {},
      }),
    );

    // fetch new info
    const { selectedNft } = await fetchNftInfo(tokenId, contractAddress);
    dispatch(
      setData({
        selectedNft,
      }),
    );
  };

export const setCurrentCollection =
  (collection: string) => async (dispatch: any) => {
    localStorage.setItem('collection', collection);

    dispatch(
      setData({
        collection,
      }),
    );
  };

export const getNftContractAddress = () => {
  const currentCollection = localStorage.getItem('collection');
  switch (currentCollection) {
    case 'genesis':
      return process.env.REACT_APP_GENESIS_CONTRACT_ADDRESS || '';
    case 'revelation':
    default:
      return process.env.REACT_APP_REVELATION_CONTRACT_ADDRESS || '';
  }
};

export default NFTMarketplaceSlice.reducer;
