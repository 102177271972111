/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchMcrtBalance } from './fetchMcrt';

const initialState: any = {
  balance: 0,
  bnbBalance: 0,
};

export const StakingSlice = createSlice({
  name: 'MCRT',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.balance = action.payload.balance;
      state.bnbBalance = action.payload.bnbBalance;
    },
  },
});

// Actions
export const { setData } = StakingSlice.actions;

export const fetchUserMCRTBalanceAsync =
  (account?: string) => async (dispatch: any) => {
    if (!account) return;
    const { mcrtBalance, bnbBalance } = await fetchMcrtBalance(account);

    dispatch(
      setData({
        balance: mcrtBalance,
        bnbBalance: bnbBalance,
      }),
    );
  };

export default StakingSlice.reducer;
