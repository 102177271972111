import './index.scss';
import daoBanner from '../../assets/images/dao/dao-banner.png';
import daoCta from '../../assets/images/dao/dao-cta.png';

const Dao = () => {
  return (
    <div className="outer-dao__container">
      <section className="hero-section">
        <div className="dao__container">
          <div className="hero__content">
            <div className="hero__text">
              <div className="badge-dao">
                <span className="badge-dao__text">New</span>
              </div>
              <h1 className="hero__heading">
                Welcome to <br />
                the MagicCraft <br />
                DAO
              </h1>
              <h4>A New Realm of Community-Powered Innovation</h4>
              <div className="subtext">
                <div className="subtext__divider"></div>
                <p>
                  Welcome to a revolutionary next step in the evolution of
                  MagicCraft—a Decentralized Autonomous Organization (DAO)
                  created to empower you, our players and stakeholders. We
                  believe the true magic lies in collaborative creation, and
                  that's what MagicCraft DAO is all about.
                </p>
              </div>
              <a
                href="https://snapshot.org/#/magiccraftdao.eth"
                rel="noreferrer noopener"
                target="_blank"
              >
                <button className="dao__btn">Visit DAO page</button>
              </a>
            </div>
          </div>
        </div>
        <div className="hero-gradient-top"></div>
        <div className="hero-gradient-radial"></div>
        <div className="hero-gradient-bottom"></div>
      </section>
      <div className="dao__container dao-info">
        <section className="dao__content">
          <div className="dao__title">
            <h2>All you need to know</h2>
            <div className="badge-dao">
              <span className="badge-dao__text">Launch on 25.09.2023</span>
            </div>
          </div>
          <div className="dao-card">
            <div className="dao-card__content">
              <h4>What is MagicCraft DAO?</h4>
              <div className="dao-card__divider"></div>
              <ul className="dao-card__list">
                <li>
                  MagicCraft DAO is an exclusive digital space where your voice
                  doesn't just echo; it resonates. By leveraging our native MCRT
                  tokens, you can bring forward proposals, vote on game-changing
                  initiatives, and directly influence the landscape of the
                  MagicCraft universe.
                </li>
              </ul>
            </div>
          </div>
          <div className="dao-card">
            <div className="dao-card__content">
              <h4>How Does It Work?</h4>
              <div className="dao-card__divider"></div>
              <ul className="dao-card__list">
                <li>
                  <span className="item-title">Propose:</span> Have a
                  groundbreaking idea for a new game feature or an improvement?
                  Submit your proposal and let the community discuss its
                  potential.
                </li>
                <li>
                  <span className="item-title">Vote:</span> Use your MCRT tokens
                  to cast your vote on proposals that you believe will elevate
                  the MagicCraft experience.
                </li>
                <li>
                  <span className="item-title">Execute:</span> Once a proposal
                  receives majority approval, our in-house team takes over for
                  the seamless implementation of the community-backed idea.
                </li>
              </ul>
            </div>
          </div>
          <div className="dao-card">
            <div className="dao-card__content">
              <h4>Why Join MagicCraft DAO?</h4>
              <div className="dao-card__divider"></div>
              <ul className="dao-card__list">
                <li>
                  <span className="item-title">Influence:</span> Directly impact
                  the game's development, mechanics, and features.
                </li>
                <li>
                  <span className="item-title">Community:</span> Join a vibrant
                  ecosystem of players, developers, and strategists united by a
                  shared passion for gaming.
                </li>
                <li>
                  <span className="item-title">Rewards:</span> Participate in
                  exclusive DAO-member benefits and incentives, enriching your
                  MagicCraft journey.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div className="dao__container dao-banner">
        <div className="dao-banner__content">
          <p>Get involved</p>
          <h3>
            Ready to make your mark on the MagicCraft universe? Join the DAO
            today and become an integral part of a community where every opinion
            matters and every voice counts.
          </h3>
        </div>
        <div className="dao-banner__img-container">
          <img
            width={500}
            height={360}
            src={daoBanner}
            alt="Magiccraft game characters"
          />
        </div>
      </div>

      <section className="dao-cta-section">
        <div className="dao-cta-gradient"></div>
        <div className="dao-cta-bottom-text">
          <span>DAO DAO DAO</span>
        </div>
        <div className="dao__container dao-cta">
          <div className="dao-cta__content">
            <h2>
              Unlock <br /> the Magic with MagicCraft DAO
            </h2>
            <p>
              Embrace the future of gaming where players are not just consumers
              but co-creators. Come, let's build a realm of endless
              possibilities—together.
            </p>
            <a
              href="https://snapshot.org/#/magiccraftdao.eth"
              rel="noreferrer noopener"
              target="_blank"
            >
              <button className="dao__btn">Visit DAO page</button>
            </a>
          </div>
          <div className="dao-cta__img-container">
            <img src={daoCta} alt="DAO voting" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dao;
