// @ts-nocheck
//import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { Web3Provider } from '@ethersproject/providers';
import { ConnectorNames } from 'widgets/WalletModal';
import Web3 from 'web3';
import { initializeConnector } from '@web3-react/core';
import getNodeUrl from './getRpcUrl';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';

const POLLING_INTERVAL = 12000;
const rpcUrl = getNodeUrl();
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID || '97', 10);

//const injected = new InjectedConnector({ supportedChainIds: [chainId] });

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: '9a5fbacd6fe4bbba1b1bcf56a6f0ef89',
        chains: [chainId],
        showQrModal: true,
      },
    }),
);

export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions }),
);
const BSC_MAINNET_PARAMS = {
  chainId: 56,
  chainName: 'BSC mainnet',
  nativeCurrency: {
    name: 'BSC mainnet',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
  blockExplorerUrls: ['https://bscscan.com'],
};

const BSC_TESTNET_PARAMS = {
  chainId: 97,
  chainName: 'BSC test',
  nativeCurrency: {
    name: 'BSC test',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: [
    'https://speedy-nodes-nyc.moralis.io/1c8d8856c017266c637672dd/bsc/mainnet',
  ],
  blockExplorerUrls: ['https://testnet.bscscan.com'],
};

// const walletlink = new WalletLinkConnector({
//   url:
//     chainId === 56
//       ? BSC_MAINNET_PARAMS.rpcUrls[0]
//       : BSC_TESTNET_PARAMS.rpcUrls[0],
//   appName: 'Magic Craft',
//   appLogoUrl: '/assets/logo.png',
//   supportedChainIds: [56, 97],
// });

export const [coinbaseWallet, coinbaseHooks] =
  initializeConnector<CoinbaseWallet>(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          url:
            chainId === 56
              ? BSC_MAINNET_PARAMS.rpcUrls[0]
              : BSC_TESTNET_PARAMS.rpcUrls[0],
          appName: 'Magic Craft',
          appLogoUrl: '/assets/logo.png',
          supportedChainIds: [56, 97],
        },
      }),
  );

const bscConnector = new BscConnector({ supportedChainIds: [chainId] });

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: metaMask,
  [ConnectorNames.WalletConnect]: walletConnectV2,
  [ConnectorNames.BSC]: bscConnector,
  [ConnectorNames.WalletLink]: coinbaseWallet,
};
