import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import stakingReducer from './staking';
import mcrtReducer from './mcrt';
import nftmintReducer from './nftmint';
import nftMarketplaceReducer from './nftMarketplace';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    staking: stakingReducer,
    mcrt: mcrtReducer,
    nftmint: nftmintReducer,
    nftMarketplace: nftMarketplaceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
