import React from 'react';
import { useDispatch } from 'react-redux';
import { useNFTMarketplaceState } from 'state/hooks';
import { setCurrentCollection } from 'state/nftMarketplace';
import './index.scss';

const CollectionButton = () => {
  const { collection } = useNFTMarketplaceState();

  const dispatch = useDispatch();

  return (
    <div className="collection">
      <div className="collection-container">
        <p className="collection-container-label">Collection:</p>
        <select
          className="collection-container-select"
          onChange={(e) => dispatch(setCurrentCollection(e.target.value))}
          value={collection}
        >
          <option value="genesis">Genesis</option>
          <option value="revelation">Revelation</option>
        </select>
      </div>
    </div>
  );
};

export default CollectionButton;
