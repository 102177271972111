import { BigNumber } from 'ethers';
import magicNFTcontractJson from 'constants/ABI/MagicNFT.json';
import multicall from 'utils/multicall';
import { getNftContractAddress } from 'state/nftMarketplace';

export const fetchMintStatus = async () => {
  const contractAddress = getNftContractAddress();
  const [isPublicSale, isWhiteListSale, publicPrice, whiteListPrice] =
    await multicall(magicNFTcontractJson.abi, [
      {
        address: contractAddress,
        name: 'isPublicSale',
        params: [],
      },
      {
        address: contractAddress,
        name: 'isWhiteListSale',
        params: [],
      },
      {
        address: contractAddress,
        name: 'publicMintPriceForEach',
        params: [],
      },
      {
        address: contractAddress,
        name: 'whiteListPriceForEach',
        params: [],
      },
    ]);

  return {
    publicSale: isPublicSale[0],
    whitelistSale: isWhiteListSale[0],
    publicPrice: BigNumber.from(publicPrice[0]._hex),
    whiteListPrice: BigNumber.from(whiteListPrice[0]._hex),
  };
};
