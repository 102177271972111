import { BigNumber } from 'ethers';

export const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;
export const E18 = BigNumber.from(10).pow(18);
export const E9 = BigNumber.from(10).pow(9);
export const COINGECKO_API_ENDPOINT = 'https://api.coingecko.com/api';
export const numberRegex = /^[0-9]*\.?[0-9]*$/;
export const INVENTORY_SUBGRAPH_URL =
  'https://api.thegraph.com/subgraphs/name/daisai3/magiccraft_nft';

export const GEM_PACK_DATA = [
  {
    id: 1,
    pack_name: 'Starter Shard',
    price_in_usd: 3.5,
    gems: 50,
  },
  {
    id: 2,
    pack_name: 'Explorer Bundle',
    price_in_usd: 7.0,
    gems: 110,
  },
  {
    id: 3,
    pack_name: "Adventurer's Cache",
    price_in_usd: 14.0,
    gems: 230,
  },
  {
    id: 4,
    pack_name: "Champion's Treasury",
    price_in_usd: 35.0,
    gems: 600,
  },
  {
    id: 5,
    pack_name: 'Heroic Vault',
    price_in_usd: 70.0,
    gems: 1250,
  },
];
