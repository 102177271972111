import { useEffect, useState } from 'react';
import { getTokenPrice } from 'utils/priceHelpers';

const usePrice = () => {
  const [mcrtPrice, setMcrtPrice] = useState(1);

  const fetchMcrtPrice = async () => {
    const price = await getTokenPrice('magiccraft');
    setMcrtPrice(price);
  };

  useEffect(() => {
    fetchMcrtPrice();
    setInterval(() => {
      fetchMcrtPrice();
    }, 50000);
  }, []);

  return { mcrtPrice };
};

export default usePrice;
