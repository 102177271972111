import {
  getNameBgColor,
  getRarityBgColor,
  getRarityFrame,
} from 'utils/nftHelper';
import bnbLogo from 'assets/icon-bnb.png';
import mcrtLogo from 'assets/icon-mcrt.png';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// styles
import './index.scss';

const getThumbnailImage = (url) => {
  const prevLinks = [
    'QmTPtJSxd3YWXNKfYLYkVVUe4nazk3yxhp2XQfgARzcs7y',
    'QmX1dhoSQqq9eMyzzFBSAYgQ5119Ap8h7VC2evppGs93W9',
    'QmNdDumUU4KDSScPkohP4fH2T5YwxNJTbQf4Cy8D3TcP59',
  ];
  const newLinks = [
    'QmVHWN3nP2vpMjT2w1s3fRRvHPEGtGHXoTQbkYEXZHsZBq',
    'QmbYXYGUYbWPALxh66MUbrUH7FC7eUqa8rXeVMQMDbGrga',
    'QmX8UUL4vE3NzRaXMivCoyL9TR6PR2A9GpvcVw9UdXB3Yb',
  ];

  let response;
  prevLinks.forEach((it, idx) => {
    if (url.includes(it)) {
      response = url.replace(it, newLinks[idx]);
    }
  });

  return response || url;
};

const InventoryItem = (props) => {
  const { data, onClick, price, priceDollar, mcrtPrice, mcrtPriceDollar } =
    props;
  const rarity = data.attributes.find((it) => it.trait_type === 'rarity');

  const location = useLocation();
  function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  }

  useEffect(() => {
    localStorage.setItem('prevPath', location.pathname);
  }, [location]);

  return (
    <div>
      <Link
        to={`/marketplace/${data.contractAddress}/${data.tokenID}`}
        style={{ color: 'white', textDecoration: 'none' }}
      >
        <div className="nft-inventory__item">
          <div className="nft-inventory__item-image">
            {rarity && (
              <div
                className="nft-inventory__item-image-rarity"
                style={
                  rarity
                    ? { backgroundColor: getRarityBgColor(rarity.value) }
                    : {}
                }
              >
                {rarity.value}
              </div>
            )}
            {rarity &&
              (data?.name === 'Blazy' ? (
                <img
                  className="nft-inventory__item-image-revelation-frame"
                  src={getRarityFrame(rarity.value)}
                  alt="frame"
                />
              ) : (
                <img
                  className="nft-inventory__item-image-frame"
                  src={getRarityFrame(rarity.value)}
                  alt="frame"
                />
              ))}
            {getThumbnailImage(data.image).includes('mp4') ? (
              <video
                autoPlay
                style={{ position: 'relative', borderRadius: 8 }}
                className="nft-inventory__item-image-avatar"
                loop
                muted
                controls={false}
                src={getThumbnailImage(data.image)}
                alt="avatar"
                loading="lazy"
                playsInline
              />
            ) : (
              <img
                className="nft-inventory__item-image-avatar"
                src={getThumbnailImage(data.image)}
                alt="avatar"
                loading="lazy"
              />
            )}
          </div>
          <div className="nft-inventory__item-content">
            <h3 style={{ marginBottom: 4 }}>{data.name.split(',')[0]} </h3>
            <div className="d-flex align-items-center">
              <div
                className="item-hero-knight"
                style={{ backgroundColor: getNameBgColor(data.name) }}
              >
                {data.name.split(',')[1]?.split('The').join('')}
              </div>
            </div>
            {price && priceDollar && (
              <div
                className="d-flex align-items-center"
                style={{ marginTop: 4 }}
              >
                <img src={bnbLogo} width={16} height={16} alt="" />
                <div className="item-price-token">
                  {numberWithCommas(Number(price).toFixed(2))}
                </div>
                <span className="item-price-dollar">
                  ${numberWithCommas(nFormatter(priceDollar, 1))}
                </span>
              </div>
            )}

            {mcrtPrice && mcrtPriceDollar && (
              <div
                className="d-flex align-items-center"
                style={{ marginTop: 4 }}
              >
                <img src={mcrtLogo} width={16} height={16} alt="" />
                <div className="item-price-token">
                  {numberWithCommas(nFormatter(mcrtPrice, 2))}
                </div>
                <span className="item-price-dollar">
                  ${numberWithCommas(nFormatter(mcrtPriceDollar, 1))}
                </span>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default InventoryItem;
