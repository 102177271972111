import React from 'react';

import './index.scss';

const CopyModal = () => {
  return (
    <div className="copy-overlay">
      <div className="copy-container">Copied!</div>
    </div>
  );
};

export default CopyModal;
