
import frameEpic from 'assets/frame_epic.png';
import frameLegendary from 'assets/frame_legendary.png';
import frameRare from 'assets/frame_rare.png';

export const getNameBgColor = (name) => {
  if (name.includes('Knight')) return '#C41E3A';
  if (name.includes('Hunter')) return '#AAD372';
  return '#3FC7EB';
}

export const getRarityBgColor = (rarity) => {
  if (rarity === 'Rare') return '#0070DD';
  if (rarity === 'Epic') return '#A335EE';
  if (rarity === 'Legendary') return '#FF8000';
}

export const getRarityFrame = (rarity) => {
  if (rarity === 'Rare') return frameRare;
  if (rarity === 'Epic') return frameEpic;
  if (rarity === 'Legendary') return frameLegendary;
}