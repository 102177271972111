import Axios from 'axios';
import { appendParams } from 'utils/axios';
import { COINGECKO_API_ENDPOINT } from 'config';

export const getTokenPrice = async (tokenId: string) => {
  const price = await getTokenPriceFromCoingecko(tokenId);
  return price;
};

const getTokenPriceFromCoingecko = async (tokenId: string) => {
  if (!tokenId) return 1;
  const url = appendParams(`${COINGECKO_API_ENDPOINT}/v3/simple/price`, {
    ids: tokenId,
    vs_currencies: 'usd',
  });
  const res = await Axios.get(url);

  if (res.status === 200) {
    if (res.data[tokenId]) {
      return res.data[tokenId].usd;
    }
    if (tokenId === 'magiccraft') {
      return 0.00492517;
    }
  }
  return 1;
};
